import {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import NotificationV2 from '../components/NotificationV2';
import StoryService from '../services/StoryService';
import { AuthContext } from '../context/AuthContext';




const NotificationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  padding-top: 1em;
  
`;


function NotificationsPage() {
  const { user } = useContext(AuthContext);
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    
    
    setIsLoading(true);
    async function getNotifications() {
      
    const response = await StoryService.getUserNotifications(user._id);
    
    setNotifications(response.data);
    // alert(JSON.stringify(response.data))
    };
    getNotifications();
    setIsLoading(false);
  }, []);

  return (
    isLoading ? <div>Loading...</div> :
    
    <>
    <h4>Notifications</h4>

    <NotificationsContainer>

      {notifications.map((notification) => (
        <NotificationV2
          key={notification._id}
          senderId={notification.sender._id}
          senderName={notification.sender.username}
          type={notification.type}
          message={notification.message}
          refId={notification.refId._id}
          storyTitle={notification.refId.title || ""}
          link={notification.link}
          read={notification.read}
        />
      ))}
    </NotificationsContainer>
    </>
    
  );
};

export default NotificationsPage;
