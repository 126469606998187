import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';


const HelpIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  cursor: pointer;
  border-radius: 50%;
    width: 0.7em;
    height: 0.7em;
    padding: 0.2em;
  border: 1px solid var(--color-primary);
`;

const Icon = styled.p`
    font-size: 0.4em;
`

const HelpModalStyle = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    width                 : '50%',
    minHeight             : '50%',
    transform             : 'translate(-50%, -50%)',
    backgroundColor: 'var(--color-background)',
    zIndex: 9999   
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 9998
  }
};

function HelpIcon(props) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

    function openModal() {
    setModalIsOpen(true);
    }

    useEffect(() => {
        return
      }, [modalIsOpen]);

  function closeModal() {
    setModalIsOpen(false);
  } 

  useEffect(() => {
    if (modalIsOpen) {
        document.body.classList.add('modal-open');
    } else {
        document.body.classList.remove('modal-open');
    }
}, [modalIsOpen]);

  return (
    <>
    <HelpIconWrapper onClick={openModal}>
    <Icon>{"?"}</Icon>

    </HelpIconWrapper>

          <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
          shouldFocusAfterRender={true}
          style={HelpModalStyle}
        >
    <FlexColWrapper>
    <CloseButton onClick={closeModal}>x</CloseButton>
<Pad/>
          <MessageText>{props.message}</MessageText>
        
          </FlexColWrapper>
        </Modal>
        </>
  );
};

export default HelpIcon;

const MessageText = styled.p`
flex-grow: 1;
// white-space: pre-wrap;
`;

const CloseButton = styled.button`
align-self: flex-start;
background-color: var(--color-primary);
color: var(--color-background);
border: var(--color-primary) 1px solid;
border-radius: 0.2em;
cursor: pointer;
text-align: center;
`;

const Pad = styled.div`
padding: 0.5em;
`;

const FlexColWrapper = styled.div`
display: flex;
flex-direction: column;
`;

