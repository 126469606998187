import React, { useEffect, useState, useContext} from 'react';
import axios from 'axios';
import AuthService from '../services/AuthService';
import { useNavigate, Link, redirect } from 'react-router-dom';
import styled from 'styled-components';
import {AuthContext}  from '../context/AuthContext';




// this allows you to post session data from the client to the backend
axios.defaults.withCredentials = true

function UserLogin() {
  const navigate = useNavigate();

const {isAuthenticated, user, setIsAuthenticated, setUser} = useContext(AuthContext);

// handle the state of input data
const [loginInfo, setLoginInfo] = useState({
  email: "",
  password: ""
});

const [loginAttempt, setLoginAttempt] = useState();

const [passedCredentials, setPassedCredentials] = useState(false);
const [verifiedEmail, setVerifiedEmail] = useState(false);
const [isLoading, setIsLoading] = useState(false);


  // handle data as it is entered in input areas (event)
  function handleChange(event) {
    
    const { name, value } = event.target;

    setLoginInfo((prevNote) => {
      return {
        ...prevNote,
        [name]: value
      };
    });
  }

  useEffect(() => {
    // alert(user)
  }, [isLoading])


  // handling the submit function
async function postLoginInfo() {
  setIsLoading(true);
  // event.preventDefault();
  AuthService.login(loginInfo).then(
    (response) => {
      
      setLoginAttempt(true);
      if (response?.data) {
        setVerifiedEmail(response.data?.verifiedEmail);
        setPassedCredentials(response.data?.passedCredentials);

        if (response.data.verifiedEmail) {
          setUser(response.data.user); // Set the user in the context
          setIsAuthenticated(true);    // Set isAuthenticated to true in the context
          navigate(-1, { replace: true });
      }

      }
    
      setIsLoading(false);

    }
  )
    

} 




    return (
      <FlexWrapper>
                  <Pad/>

          <FieldInputContainer>

            <EmailInput
              name="email"
              placeholder="email"
              type="email"
              autoComplete='email'
              onChange={handleChange}
            />

            <PasswordInput
              name="password"
              placeholder="password"
              type="password"
              autoComplete='current-password'
              onChange={handleChange}
            />

<ForgotPasswordP><Link to='/auth/forgotpassword'>forgot your password?</Link></ForgotPasswordP>

          <SubmitButton onClick={postLoginInfo}>login</SubmitButton>

          </FieldInputContainer>


        
          <ExtraInfo>

          </ExtraInfo>
        {isLoading ? "" : 
        <ExtraInfo>
        {!passedCredentials && !isLoading && loginAttempt && <p>Incorrect email or password. Please try again.</p>}
        {passedCredentials && !isLoading && loginAttempt && !verifiedEmail &&  <p>Awaiting email verification. Please check your email for a verification link, and then come back here!</p>}
          {/* {
          : ((!verifiedEmail) ? 
          
            : <p>
              New user? <Link to='/auth/register'>Click here to register</Link>
            </p>)} */}

        </ExtraInfo>
        }
        <ExtraInfo>

        <p> new user? <Link to='/auth/register'>click here to register</Link> </p>
        </ExtraInfo>

        <Pad/>
      </FlexWrapper>
      

    );
}

export default UserLogin;


const FlexWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`;


const FieldInputContainer = styled.div`
  margin:10px 50px 10px 50px;
  height: auto;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  display: grid;
  grid-column: 1/ span 1;
`;




const SubmitButton = styled.button`
width: 100%;
height: auto;
border-radius: 4px;
font-size: 32px;
padding: 8px;
margin-top: 8px;
background-color: grey;
color: white;
grid-column: 1/ span 1;
grid-row: 3/ span 1;
box-sizing: border-box;
text-align: left;
cursor: pointer;
`

const ExtraInfo = styled.div`

  `


const Input = styled.input`
  width: 100%;
  padding: 8px;
  margin-top: 8px;
  font-size: 32px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;

`
const ForgotPasswordP = styled.p`
font-size: 0.7em;
text-align: right;
`

const EmailInput = styled(Input)`
  grid-column: 1/ span 1;
  grid-row: 1/ span 1;
`;

const PasswordInput = styled(Input)`
grid-column: 1/ span 1;
grid-row: 2/ span 1;
`;

const Pad = styled.div`
  height: 1em;
`