import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import CharacterService from '../services/CharacterService';
import { FaMinus, FaPlus } from 'react-icons/fa'
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { TextareaAutosize } from '@mui/material';
import {AuthContext} from '../context/AuthContext';
import { useEffect } from 'react';
import { SolidButton } from './StyledWidgets';

const Container = styled.div`
  // margin: 0 auto;
  padding: 2em;
  background-color: var(--color-background);
`;

const Title = styled.h1`
  text-align: center;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-weight: bold;
  
`;


const StyledTextArea = styled(TextareaAutosize)`
  width: 100%;
  overflow: auto;
  flex-grow: 1;
  padding: 0.5em;
  // rows: ${(props) => props.rows || 1};
  height: auto; 

  resize: none;
  vertical-align: center;
  horizontal-align: center;
// set font size relative to screen size;  smaller for laptop, larger for mobile
  // font-size: calc(0.5em - 0.1vw);
  font-size: 1em;
  outline: none;
  border-radius: 7px;
  box-sizing: border-box;
  border-color: var(--color-primary);
  color: var(--color-primary);
  background-color: var(--color-background);
  ::placeholder {
    color: var(--color-secondary);
    opacity: 0.8;
};
  &:hover {
    ::placeholder {
      opacity: 1;
    };
  };
&:focus {
  outline: none;
  ::placeholder {
    transition: opacity 0.5s 0.5s ease!important;
    opacity: 0.35;
    color: var(--color-secondary);
};
};
`

// const StyledFaPlus = styled(FaPlus)`
// width: 4em;
// font-size: 3em;
//   `



const LinkHead = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.2em;
`;

const LinkItem = styled(LinkHead)`
padding-top: 0.2em;

`

const PadDiv = styled.div`
padding: 0.5em 0 0.5em 0;
`

const Pad = styled.div`
padding: ${(props) => props.padding || '1em'};
`

const InputDiv = styled.div`
padding: 0.5em 0 0.5em 0;
display: flex;
flex-direction: column;
gap: 0.2em;
`


const Button = styled.button`
  padding: 0.1em;
  margin-top: 0.2em;
  background-color: var(--color-primary);
  color: var(--color-background);
  border: none;
  border-radius: 5px;
  display: inline-block;

`;

const CenteredButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  border: none;
  background-color: var(--color-primary);
  color: var(--color-background);
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: var(--color-secondary);
  }
`;

function CharacterBuilderForm(props) {

  const location = useLocation();
  const characterData = location.state?.characterData;

  const { isAuthenticated, user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [characterDetails, setCharacterDetails] = useState({
    name: '',
    age: '',
    gender: '',
    occupation: '',
    backstory: '',
    personality: [],
    skills: [],
    physicalDescription: '',
    goals: '',
    dialogueExamples: []
  });

  useEffect(() => {
    console.log(characterData)
    if (characterData) {
      setCharacterDetails(characterData);
    }
  }, []);


//   // Handle API call here
  const handleSave = () => {
    // alert('clicked')
    CharacterService.postCharacter(user.username,characterDetails)
      .then((res) => {
        navigate(`/characters/${res.data.character_ID}`);
      })
      .catch((err) => {
        alert('Error saving character')
        console.error(err);
      });
  }

  const handleInputChange = (e, field) => {
    setCharacterDetails({
      ...characterDetails,
      [field]: e.target.value,
    });
  };

  const handleArrayChange = (e, index, field) => {
    const newArray = [...characterDetails[field]];
    newArray[index] = e.target.value;
    setCharacterDetails({
      ...characterDetails,
      [field]: newArray,
    });
  };

  const handleRemoveArray = (index, field) => {
    const newArray = [...characterDetails[field]];
    newArray.splice(index, 1);
    setCharacterDetails({
      ...characterDetails,
      [field]: newArray,
    });
  };

  const handleAddArray = (field) => {
    setCharacterDetails({
      ...characterDetails,
      [field]: [...characterDetails[field], ''],
    });
  };
  const re = /([a-z])([A-Z])/g;
  return (
    <Container>
      <Title>Create Character</Title>
      <Form>
        {/* Code for input fields */}
        <InputDiv>
          {['name', 'age', 'gender', 'occupation', 'backstory', 'physicalDescription', 'goals'].map(field => (
            <PadDiv key={field}>
              <Label htmlFor={field}>{
              field
              .replace(/([a-z])([A-Z])/g, '$1 $2')
              .replace(/\b\w/g, char => char.toUpperCase())}
        </Label>
        <Pad padding='0.2em'/>
              <StyledTextArea
                id={field}
                type="text"
                value={characterDetails?.[field] || ''}
                onChange={(e) => handleInputChange(e, field)}
              />
            </PadDiv>
          ))}
        </InputDiv>

        {/* Code for array fields */}
        {['personality', 'skills', 'dialogueExamples'].map((field) => (
          <PadDiv key={field}>
            <LinkHead>
              <Label htmlFor={field}>{
              field
              .replace(/([a-z])([A-Z])/g, '$1 $2')
              .replace(/\b\w/g, char => char.toUpperCase())}</Label>
            </LinkHead>
            <Pad padding='0.2em'/>

            {characterDetails?.[field].map((trait, index) => (
              <LinkItem key={index}>
                <CenteredButton type="button" onClick={() => handleRemoveArray(index, field)}>
                  <FaMinus />
                </CenteredButton>
                <StyledTextArea
                  id={`${field}-${index}`}
                  type="text"
                  value={trait || ''}
                  onChange={(e) => handleArrayChange(e, index, field)}
                />
              </LinkItem>
            ))}
            <LinkItem>
              <CenteredButton type="button" onClick={() => handleAddArray(field)}>
                <FaPlus  />
              </CenteredButton>
            </LinkItem>
            <Pad padding='0.5em'/>

          </PadDiv>
        ))}
        
        <PadDiv />
        {/* <Button type="button" onClick={props.onClose}>
          Cancel
        </Button> */}
        <PadDiv />
        <SolidButton type="button" onClick={handleSave}>
          Save Character
        </SolidButton>
      </Form>
    </Container>
  );
};

export default CharacterBuilderForm;
