import {React} from 'react'; 

import NotFound from '../components/NotFound';
import Header from '../components/Header';




function NotFoundPage() {
return (
    <div>

        <NotFound />

    </div>
);
}

export default NotFoundPage;