import React, { useState } from "react";
import styled from "styled-components";

const SwitchContainer = styled.div`
display: flex;
align-items: center;
${'' /* padding: 0.5em; */}

width: calc(${props => props.size} * 2);  
height: calc(${props => props.size});  
  background: var(--color-background);

  border-radius: calc(${props => props.size}); 
  // position: relative;
  cursor: pointer;
  box-shadow: 0 0 0 calc(${props => props.size}*0.2) rgba(255, 255, 255, 0.1);
  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.3em rgba(255, 255, 255, 0.5); /* offset outline when focused */
  }
`;

const SwitchToggle = styled.div`
  width: calc(${props => props.size} *0.85);
  height: calc(${props => props.size}*0.85);
  border-radius: 50%;
  background: ${props => props.checked ? 'white' : 'var(--color-primary)'};
  position: relative;

  transition: all 0.3s ease;
  left: ${props => (props.checked ? `calc(100% - ${props.size}*0.85 - ${props.size}/10)` : `calc(${props.size}/10)`)};
`;

// `calc(100% - ${props.size} - ${props.size}*0.3)`


function StyledSwitch (props) {
  

  const handleClick = () => {
    props.onToggle(!props.checked)
  };

  const size = props.size || '1em';

  

  return (
    <SwitchContainer size={size} checked={props.checked} onClick={handleClick}>
      <SwitchToggle size={size} checked={props.checked} />

    </SwitchContainer>
  );
};

export default StyledSwitch;
