import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

function MainLayout() {
  return (
    <StyledMain>
      <Outlet />
    </StyledMain>
  );
}

export default MainLayout;

const StyledMain = styled.main`
  width: 95%;
  max-width: 1080px;
  margin: 0 auto;
  padding-left: 0.05rem;
  padding-right: 0.05rem;

  @media (min-width: 480px) {
    width: 90%;
  }

  @media (min-width: 768px) {
    width: 85%;
  }

  @media (min-width: 1024px) {
    width: 80%;
  }

  @media (min-width: 1100px) {
    width: 1080px;
    padding-left: 0;
    padding-right: 0;
  }
`;