import React, {useContext, useState, useEffect} from "react";
import { slide as BurgerMenu } from 'react-burger-menu';
import { useNavigate, useLocation, Link} from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { StoryContext } from "../context/StoryContext";
import StoryService from "../services/StoryService";
import AuthService from "../services/AuthService";
// import JoyRideComponent from "./JoyRideComponent";
import Joyride from 'react-joyride';
import {TbArrowFork} from "react-icons/tb";


// import SearchBar from "./SearchBar";



import { MdFace } from "react-icons/md";

// import a bell icon
// import { FaBell } from "react-icons/fa";
import {VscBell} from "react-icons/vsc";
import {VscBellDot} from "react-icons/vsc";

import styled from "styled-components";


function Header() {

  const location = useLocation();
  const navigate = useNavigate();

  const {
    isAuthenticated,
    user
  } = useContext(AuthContext);

  const menuItems = [
    { path: '/', label: 'Home' },
    { path: '/story/explore', label: 'Explore' },
    // { path: '/feedback', label: 'Feedback' },
  ];
  
  if (isAuthenticated) {
    menuItems.push(
      { path: '/new-story', label: 'New Story' },
      { path: '/notifications', label: 'Notifications' },
      { path: '/auth/logout', label: 'Logout' },
      { path: '/welcome', label: 'Welcome' },
      { path: `/user/${user.username}`, label: 'Profile' },
      { path: `/feedback`, label: 'Feedback' }

    );


  } else {


    menuItems.push(
      { path: '/auth/login', label: 'Login' },
      { path: '/auth/register', label: 'Register' }
    );

  }

  const {startNewStory, setStartNewStory} = useContext(StoryContext);
  const [newNotification, setNewNotification] = useState(false);
  const [isLoading, setIsLoading] = useState(true)

// create a useEffect to set the state of newNotification
useEffect(() => {
  if (!isAuthenticated) {
    return;
  }
  setIsLoading(true)

  // create a function in userservice/storyservice to fetch notification data
  const getNewNotificationCheck = async (userId) => {
    StoryService.getNewNotificationCheck(userId).then((response) => {
      setIsLoading(false)

      // if the response is true, setNewNotification to true
      if (response?.data) {
        setNewNotification(true);
      }
    }
 
    )

  }
  // call the function
  getNewNotificationCheck(user._id);
  setIsLoading(false)

}, [user]);







  
  // alert(JSON.stringify(location));
  const handleClick = (route) => {
    navigate(`/${route}`)
}




  return (

    <ColWrapper>

    <RowWrapper>

    <Logo  onClick={()=> {handleClick("")}}>
    <BrightSpan>Story<GreySpan>Fork</GreySpan></BrightSpan>
    </Logo>
<Nav>
    {isAuthenticated 
    &&
    <>
    <ProfileBtn onClick={()=> {handleClick(`user/${user.username}`)}}><MdFace/></ProfileBtn>
    <NavItem onClick={()=> {handleClick(`notifications`); setNewNotification(false);}}>
     
     
     {newNotification ? <VscBellDot /> : <VscBell />} 
    
     </NavItem>
     </>
     }

<BurgerMenu   styles={styles} right>
      {menuItems.map((item, index) => (
        <Link key={index} className="menu-item" to={item.path}>
          {item.label}
        </Link>
      ))}

    </BurgerMenu>
    </Nav>
    {/* <Nav>
    <NavItem onClick={()=> {handleClick("story/explore")}}>Explore Stories</NavItem> */}

    {/* <NavItem onClick={()=> {handleClick("feedback")}}>Feedback</NavItem> */}

    {/* <NavItem onClick={()=> {handleClick("")}}> Home</NavItem>  */}

    {/* {isAuthenticated 
    && 
   <>
    <NavItem onClick={()=> {handleClick("new-story")}}>New Story</NavItem>
    <NavItem onClick={()=> {handleClick("story/explore")}}>Explore</NavItem>

    </>
    } */}




    {/* 
    <ProfileBtn onClick={()=> {handleClick(`user/${user.username}`)}}><MdFace size = {40}/></ProfileBtn>
    </>
    : <NavItem onClick={()=> {handleClick("auth/login")}}>Login</NavItem>} */}


    {/* </Nav> */}

  


    </RowWrapper>
    </ColWrapper>

  );
}


export default Header;

const ColWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

const RowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    // margin-right: 4em;
    padding: 0.5em 0.5em;
    justify-content: space-between;
    // width: 60%;
`

const Nav = styled(RowWrapper)`
    justify-content: flex-end;
    margin-left: auto;
    // margin-right: 3rem;
`




var styles = {
  bmBurgerButton: {
    position: 'relative',
    width: '1.5em',
    height: '1.5em'
  },
  bmBurgerBars: {
    background: 'var(--color-primary)'
  },
  bmBurgerBarsHover: {
          color: 'var(--color-secondary)'

  },

  bmCross: {
    background: 'var(--color-primary)'
  },
  bmMenuWrap: {
    // position: 'fixed',
    width: 'auto',
    // top: '2.5rem'

    height: '100%'
  },
  bmMenu: {
    background: 'var(--color-background)',
    // padding: '2rem 0.5rem',

  },
  bmMorphShape: {
    // fill: '#373a47'
  },
  bmItemList: {
    color: 'var(--color-primary)',
    padding: '0.8em',
    // fontSize: '2.5rem'
  },
  bmItem: {
    display: 'flex',
    flexDirection: 'column'
  },
  bmOverlay: {
    position: 'fixed',
    top: '0em',
    left: '0em',
    width: '100vw',
    height: '100vh',
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: '99',
    // display: 'none'
    // background: 'rgba(0, 0, 0, 0.3)'
  }
}




const NavText = styled.h1`
margin: 0 1em;
/* background-color: var(--color-primary); */
transition: ease-in-out 200ms;
color: var(--color-primary);
cursor: pointer;
&:hover {
        transform: scale(1.2);
        color: var(--color-secondary)
    }
      &:active{
        transform: scale(1.2);
        color: var(--color-secondary)
    }
  
`


const NavItem = styled.div`

display: flex;
// padding: 0 1.2em;
padding-right: 0.5em;
font-size: 1.5em;
transition: ease-in-out 200ms;
color: var(--color-primary);
cursor: pointer;
align-items: center;
&:hover {
        transform: scale(1.2);
        color: var(--color-secondary)
    }
      &:active{
        transform: scale(1.2);
        color: var(--color-secondary)
    }
  
`

const ProfileBtn = styled(NavItem)`
`

const Logo = styled(NavText)`
    font-size: 2em;
  
`

const Pad = styled.div`
    padding: 1em;
`

const GreySpan = styled.span`
color: var(--color-accent);


`

const BrightSpan = styled.span`
    color: var(--color-secondary);
`
;