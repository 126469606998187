import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const MentionLink = styled(Link)`
  /* Apply your styling for the mention link */
  color: blue;
  text-decoration: underline;
  cursor: pointer;
`;

function renderMention (suggestion, search, highlightedDisplay) {
    // Extract character id from suggestion object
    const characterId = suggestion.id; // Change this to match your data structure
    return (
      <MentionLink to={`/characters/${characterId}`}>
        {highlightedDisplay}
      </MentionLink>
    );
  };

export default renderMention;