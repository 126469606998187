export const validateEmail = new RegExp("^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,})+$");
    export const  validatePassword = new RegExp('^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$');
  export const  validateUsername = new RegExp('^[a-zA-Z0-9_]+$');

  export function toSlug(id, str) {
    //0. Test if there is a string
    if (str === undefined || str === null || str === '') {
        return id;
    }

    // 1. Convert to lowercase
    str = str.toLowerCase();

    // 2. Remove non-ASCII characters
    str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    // 3. Replace spaces and undesired characters with '-'
    str = str.replace(/[\s\W-]+/g, '-');

    // 4. Remove potential unwanted edge dashes
    str = str.replace(/^-+|-+$/g, '');

    return id+'_'+str;
}









