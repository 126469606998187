import React, {useState, useEffect} from 'react';
import {MdDelete, MdEdit, MdDownload} from 'react-icons/md';


import styled from 'styled-components';

export default function AuthorOptions(props) {

  return (
    <div  >

      <FlexWrapper>
        <MyButton >
          <MdDelete onClick={props.handleStoryDelete} />
        </MyButton>
{/* 
        <Pad />
        <MyButton  >
          <MdDownload onClick={props.exportPNG}/>
        </MyButton> */}
        <Pad />
        <MyButton onClick={props.toggleEdit} >
          <MdEdit />
        </MyButton>

      </FlexWrapper>


    </div>
  );
}



const MyButton = styled.button`
display: flex;
  background-color: var(--color-background);
  border: none;
  border-radius: 0;
  padding: 0;
  margin: 0;
  font-size: 1.5em;

  align-items: center;
&& hover: {
    background-color: var(--color-background);
}

`

const Pad = styled.div`
    width: 0.5em;
`


const FlexWrapper = styled.div`
display: flex;
flex-direction: row;
align-items: center;

`


