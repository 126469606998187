import React, { useState } from 'react';
import styled from 'styled-components';
import StoryService from '../services/StoryService';
import { FaMinus, FaPlus, FaLink } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom';


const Container = styled.div`
width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: var(--color-background);
`;

const Title = styled.h1`
  text-align: center;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-weight: bold;
  
`;

const Input = styled.input`
  display: inline-block;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const Textarea = styled.textarea`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const LinkHead = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.2em;
`;

const LinkItem = styled(LinkHead)`
padding-top: 0.2em;

`

const PadDiv = styled.div`
padding: 0.5em 0 0.5em 0;
`

const InputDiv = styled.div`
padding: 0.5em 0 0.5em 0;
display: flex;
flex-direction: column;
gap: 0.2em;
`


const Button = styled.button`
  padding: 0.1em;
  margin-top: 0.2em;
  background-color: var(--color-primary);
  color: var(--color-background);
  border: none;
  border-radius: 5px;
  display: inline-block;

`;

const CenteredButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border: none;
  background-color: var(--color-primary);
  color: var(--color-background);
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: var(--color-secondary);
  }
`;

function EditProfileForm(props) {

  const navigate = useNavigate();

  const [userDetails, setUserDetails] = useState(props.user)
  // const [username, setUsername] = useState(props.user.username);
  // const [pronouns, setPronouns] = useState(props.user.pronouns);
  // const [bio, setBio] = useState(props.user.bio);
  // const [socials, setSocials] = useState(props.user.socialsLinks || ['']);
  // const [works, setWorks] = useState(props.user.worksLinks || ['']);
  // const [links, setLinks] = useState(props.user.otherLinks || ['']);
  // const [goals, setGoals] = useState(props.user.goals);

  function handleClick() {
    // Regular expression for URLs
    // const urlPattern = new RegExp(
    //   '^(https?:\\/\\/)?' + // protocol
    //   '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name and extension
    //   '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    //   '(\\:\\d+)?' + // port
    //   '(\\/[-a-z\\d%@_.~+&:]*)*' + // path
    //   '(\\?[;&a-z\\d%@_.,~+&:=-]*)?' + // query string
    //   '(\\#[-a-z\\d_]*)?$',
    //   'i'
    // );
  
    // // Check every link field
    // const linkFields = ['socialsLinks', 'worksLinks', 'otherLinks'];
    // for (let field of linkFields) {
    //   for (let link of userDetails[field]) {
    //     if (!urlPattern.test(link)) {
    //       alert('Please enter a valid URL for all link fields.');
    //       return;
    //     }
    //   }
    // }
  
    // All URLs are valid, proceed with the API call
    StoryService.postUserDetails(userDetails)
      .then((res) => {
        if (res.data.username_error) {
          alert('warning: username exists, please choose another username');
        } else {
          alert('Profile updated!');
          navigate(`/user/${userDetails.username}`, {state: {userDetails: res.data.updateduser}});
          props.onClose();

        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  


  const handleInputChange = (e, field) => {
    setUserDetails({
      ...userDetails,
      [field]: e.target.value,
    });
  };

  const handleLinkChange = (e, index, field) => {
    const newLinks = [...userDetails[field]];
    newLinks[index] = e.target.value;
    setUserDetails({
      ...userDetails,
      [field]: newLinks,
    });
  };

  const handleRemoveLink = (index, field) => {
    const newLinks = [...userDetails[field]];
    newLinks.splice(index, 1);
    setUserDetails({
      ...userDetails,
      [field]: newLinks,
    });
  };

  const handleAddLink = (field) => {
    setUserDetails({
      ...userDetails,
      [field]: [...userDetails[field], ''],
    });
  };

  return (
    <Container>
      <Title>Edit Profile</Title>
      <Form>
        <InputDiv>
        <Label htmlFor="username">Username</Label>
        <Input
          id="username"
          type="text"
          value={userDetails.username || ''}
          onChange={(e) => handleInputChange(e, 'username')}
        />
        </InputDiv>

        <InputDiv>
        <Label htmlFor="pronouns">Pronouns</Label>
        <Input
          id="pronouns"
          type="text"
          value={userDetails.pronouns || ''}
          onChange={(e) => handleInputChange(e, 'pronouns')}
        />
        </InputDiv>

        <InputDiv>
        <Label htmlFor="bio">Bio</Label>
        <Input
          id="bio"
          type="text"
          value={userDetails.bio || ''}
          onChange={(e) => handleInputChange(e, 'bio')}
        />
        </InputDiv>

        <InputDiv>
        <Label htmlFor="writingGoals">Writing Goals</Label>
        <Input
          id="writingGoals"
          type="text"
          value={userDetails.writingGoals || ''}
          onChange={(e) => handleInputChange(e, 'writingGoals')}
        />
        </InputDiv>


        <PadDiv>
          <LinkHead>

            <Label htmlFor="socials">Socials <FaLink /></Label>

          </LinkHead>

          {userDetails.socialsLinks.map((link, index) => (
            <LinkItem key={index}>
              <CenteredButton type="button" onClick={() => handleRemoveLink(index, 'socialsLinks')}>
                <FaMinus />
              </CenteredButton>
              <Input
                id={`social-${index}`}
                type="text"
                value={userDetails.socialsLinks[index] || ''}
                onChange={(e) => handleLinkChange(e, index, 'socialsLinks')}
              />

            </LinkItem>

          ))}
          <LinkItem>
            <CenteredButton type="button" onClick={() => handleAddLink('socialsLinks')}>
              <FaPlus />
            </CenteredButton>
          </LinkItem>
        </PadDiv>



        <PadDiv>
          <LinkHead>

            <Label htmlFor="worksLinks">Published Work <FaLink /></Label>

          </LinkHead>

          {userDetails.worksLinks.map((link, index) => (
            <LinkItem key={index}>
              <CenteredButton type="button" onClick={() => handleRemoveLink(index, 'worksLinks')}>
                <FaMinus />
              </CenteredButton>
              <Input
                id={`works-${index}`}
                type="text"
                value={userDetails.worksLinks[index] || ''}
                onChange={(e) => handleLinkChange(e, index, 'worksLinks')}
              />

            </LinkItem>

          ))}
          <LinkItem>
            <CenteredButton type="button" onClick={() => handleAddLink('worksLinks')}>
              <FaPlus />
            </CenteredButton>
          </LinkItem>
        </PadDiv>


        <PadDiv>
          <LinkHead>

            <Label htmlFor="otherLinks">Other Links <FaLink /></Label>

          </LinkHead>

          {userDetails.otherLinks.map((link, index) => (
            <LinkItem key={index}>
              <CenteredButton type="button" onClick={() => handleRemoveLink(index, 'otherLinks')}>
                <FaMinus />
              </CenteredButton>
              <Input
                id={`link-${index}`}
                type="text"
                value={userDetails.otherLinks[index] || ''}
                onChange={(e) => handleLinkChange(e, index, 'otherLinks')}
              />

            </LinkItem>

          ))}
          <LinkItem>
            <CenteredButton type="button" onClick={() => handleAddLink('otherLinks')}>
              <FaPlus />
            </CenteredButton>
          </LinkItem>
        </PadDiv>





        <PadDiv />

        <Button type="button" onClick={props.onClose}>
          Cancel
        </Button>
        <PadDiv />
        <Button type="button" onClick={handleClick}>
          Save Changes
        </Button>
      </Form>
    </Container>
  );
};

export default EditProfileForm;
