import React, { useState, useContext, useEffect } from 'react';
import styled from "styled-components";
import { AuthContext } from "../context/AuthContext";
import StoryService from "../services/StoryService";
import { Link } from "react-router-dom";

function FollowUser(props) {
    const { isAuthenticated, user } = useContext(AuthContext);
    const [following, setFollowing] = useState(false);

    // const fontSize = props.fontSize ?? "1em";


    useEffect(() => {
        if (!isAuthenticated) return;
    
        StoryService.getFollowCheck(props.username)
          .then((res) => {
            setFollowing(res.data.following);
          })
          .catch((err) => {
            ;
          });
      }, [props.username]);
    
      function handleFollow() {
        StoryService.postFollowUser(props.username)
          .then((res) => {
            ;
            setFollowing(res.data.following);
          })
          .catch((err) => {
            ;
          });
      }
    
      function handleUnFollow() {
        StoryService.postUnFollowUser(props.username)
          .then((res) => {
            ;
            setFollowing(res.data.following);
          })
          .catch((err) => {
            ;
          });
      }






    return (
         <>
        
        {!isAuthenticated || user?.username === props.username ? null : following ? (
            <FollowButton onClick={handleUnFollow}>Following</FollowButton>
          ) : (
            <FollowButton onClick={handleFollow}>Follow</FollowButton>
          )}
    </>
    )
    }



    function Username (props) {
        return (
            <Link to={`/user/${props.username}`}>
            <User>{props.username}</User>
        </Link>        )
    }

    export {Username, FollowUser, RowWrapper};


    const User = styled.h1`
    font-size: ${(props) => props.fontSize ?? "1em"};
    color: var(--color-secondary);
`;

const RowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: auto;
`;

const FollowButton = styled.button`
  background-color: var(--color-primary);
  color: black;
  border: none;
  font-weight: bold;
  border-radius: 0.2em;
  padding: 0.2em 1em;
  font-size: ${(props) => props.fontSize ?? "0.3em"};
  ${'' /* font-size: 0.3em; */}


  ${'' /* transition: ease-in-out 200ms; */}


  &:hover {
    ${'' /* transform: scale(1.2); */}
    background-color: var(--color-secondary);
  }
  &:active {
    ${'' /* transform: scale(1.2); */}
    background-color: var(--color-secondary);
  }
`;