import React, { useState, useEffect, useContext } from 'react';

import {useParams, useNavigate, Link} from 'react-router-dom';

import CharacterService from '../services/CharacterService';
import StoryService from '../services/StoryService';
import styled from 'styled-components';
import { AuthContext } from '../context/AuthContext';

import CharacterCard from '../components/cards/CharacterCard';
import { CustomLink } from '../components/StyledWidgets';

function UserCharactersPage() {

    const navigate = useNavigate();
    const { isAuthenticated, user } = useContext(AuthContext);
    const {username} = useParams();
    console.log(user)


    const [userCharacters, setUserCharacters] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [userIsAuthor, setUserIsAuthor] = useState(false);
    

    useEffect(() => {

        StoryService.getUserCharacters(username)
        .then(res => {
            console.log(res);
            if (res.status > 300) {
                return
      }
            setUserCharacters(res.data);

            setIsLoading(false);
        })
        .catch(err => {
            console.error(err);
        })
    }, []);

    useEffect(() => {
        if (user?.username === username) {
            setUserIsAuthor(true);
        }
    } , [user])









    return (
      <PageWrapper>

{(userCharacters && !isLoading) ? 
<>
        <Pad />
        
        {userIsAuthor ? <Heading>Your Characters:</Heading> : <Heading>{username}'s Characters:</Heading>}
        <Pad />

        <CardsContainer >
            {userCharacters.map((character) => (
                <Link to={`/characters/${character._id}`}>
                <CharacterCard 
                    key={character._id} 
                    {...character}
                    // name={character.name} 
                    // age={character.age} 
                    // gender={character.gender} 
                    // occupation={character.occupation}
                    // backstory={character.backstory}
                    // personality={character.personality}
                    // skills={character.skills}
                    // physicalDescription={character.physicalDescription}
                    // goals={character.goals}
                    // dialogueExamples={character.dialogueExamples}
                    // featuredIn={character.featuredIn}
                />
                </Link>
            ))}
        </CardsContainer>
        <Pad />
        <Pad />
        </>
 : 
 <Wrapper>

    <h3>Looks like you don't have any characters yet.</h3>
    <Pad />

    <h4> Why not make one?</h4>
    <Pad />
    <CustomLink to={`/characterbuilder`}>Create a Character</CustomLink>
    </Wrapper>
}


      </PageWrapper>  
    );
}

export default UserCharactersPage;

const PageWrapper = styled.div`
padding: 2em;
`;

const Wrapper = styled.div`
padding: 2em;
`;

const Pad = styled.div`
    padding: 1rem;
`;

const Heading = styled.h1`
    font-weight: bold;
    margin-bottom: 1rem;
    margin-left: 1rem;
`;

const CardsContainer = styled.div`
display: flex;
flex-wrap: wrap;
font-size: 16px;
align-items: stretch;
`;

const CardWrapper = styled.div`
    `;
