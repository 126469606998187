import React,{ useState, useContext} from "react";
import {MyButton} from "./StyledWidgets";
import { MdDelete } from 'react-icons/md';
import StoryService from "../services/StoryService";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




function DeleteButton(props) {
    console.log('DeleteButton props')
    console.log(props)

    function handleHandOver(event) {

       const confirmation =  window.confirm("Are you sure you want to hand over this story to the hivemind account? All associations with you as the author will be removed.") 

       if (confirmation) {
        handOver();
        alert('This fork is now owned by the Hivemind.')

        window.location.reload();

       } else {
              return;
         }

         async function handOver() {
            await StoryService.handOverStory(props.storyId)
        }
       

        // toast.warning('This fork is now owned by the Hivemind.', {
        //     position: "top-center",
        //     autoClose: 2000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     theme: 'dark'

        //   });
    
        
            
            // refresh the page
    }


    return (
        <>
        {/* <ToastContainer /> */}
<MyButton disabled={props.disabled} onClick={handleHandOver}>
                <MdDelete/>
            </MyButton>
        </>




    )
}

export default DeleteButton;


