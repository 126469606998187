import React, {lazy, Suspense, useEffect} from 'react'; 
import {Outlet, useLocation} from 'react-router-dom';

// Import ReactGA from react-ga4
import ReactGA from 'react-ga4';

import GlobalStyles from "../components/GlobalStyles";
import styled from 'styled-components';
import Footer from "../components/Footer";

// import Header from "../components/Header";
const Header = lazy(() => import("../components/Header"));

// Initialize ReactGA with your GA4 property ID
ReactGA.initialize('G-3WG7T71EXJ');

// Define a function that tracks page views
function trackPageView(location) {
  // make sure that the pageview is not sent
  // while in the development mode
  if (process.env.NODE_ENV !== 'development') {
    ReactGA.send({ hitType: 'page_view', page_path: location.pathname });
  } 

};


function RootLayout() {

    const location = useLocation();

    useEffect(() => {
      trackPageView(location);
    }, [location]);

return (
    <>
    <GlobalStyles/>
    <PageContainer>
            <Suspense fallback={<div>Loading...</div>}>
                <Header />
            </Suspense>
    <StyledMain>
            <Outlet />
        </StyledMain>
    

    <StyledFooter />
</PageContainer>
    </>

);
}

export default RootLayout;




const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    `

const StyledFooter = styled(Footer)`
    margin-top: auto;
    margin-bottom: 0;
    `   

    const StyledMain = styled.main`
    flex-grow: 1;
    ${'' /* @media (min-width: 1024px) { 
        width: 35rem; 
        margin: 0 auto;
    } */}
    `
    
// const PageContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   min-height: 100vh;
//   width: 100%;
//   align-items: center; // Center children horizontally
// `;

// const StyledFooter = styled(Footer)`
//   margin-top: auto;
//   margin-bottom: 0;
// `;

// const StyledMain = styled.main`
//   flex-grow: 1;
//   width: 100%; // Use 100% width to use full container space
//   max-width: 794px; // Adjust this to fit your design preference
//   padding: 20px; // Add some padding
//   box-sizing: border-box; // Include padding in width calculation
//   margin: 0 auto; // Center the content horizontally
// `;

