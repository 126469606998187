import React, { useState } from 'react';
import axios from 'axios';
import authService from '../services/AuthService';

axios.defaults.withCredentials = true

function UserLogout() {

  // handling the submit function
async function postLogout(event) {

  // prevent default to stop the page refreshing on submit
  // event.preventDefault();

  // posting data in form to the route specified in the backend
  authService.logout();

} 


    return (
        <div>
        <form onSubmit={postLogout} className="">
       
          <button type="submit">Logout</button>

        </form>
      </div>

    );
}

export default UserLogout;


