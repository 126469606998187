import React, { useState, useEffect } from 'react';
import AuthService from '../services/AuthService';
import { useNavigate, Link } from 'react-router-dom';

import styled from 'styled-components';

import {validateEmail, validatePassword, validateUsername} from '../utils/regex';



function UserRegister() {

  const navigate = useNavigate();


  // handle the state of input data
const [regInfo, setRegInfo] = useState({
  email: "",
  username: "",
  password: "",
  password2: ""
});

const [loginResponse, setLoginResponse] = useState({
  user: {},
  email_exists: false,
  username_exists: false,
  isAuthenticated: false,
  user_registered: false,
  password_test: false
});

const [loginAttempt, setLoginAttempt] = useState(false);
const [isLoading, setIsLoading] =useState(false);

const [validateCount, setValidateCount] = useState(0);
const [validEmail, setValidEmail] = useState(true);
const [validUsername, setValidUsername] = useState(true);
const [validPassword, setValidPassword] = useState(true);
const [passwordTest, setPasswordTest] = useState(true);


// create a useEffect to trigger the postRegInfo function
// any time the validateCount state changes

useEffect(() => {
  if (validateCount > 0) {
    postRegInfo();
  }
}, [validateCount]);


// handle data as it is entered in input areas (event)
function handleChange(event) {
  const { name, value } = event.target;

  setRegInfo((prevNote) => {
    return {
      ...prevNote,
      [name]: value
    };
  });
}
 
function validate() {
  setIsLoading(true);

  setValidEmail(validateEmail.test(regInfo.email));
  setValidUsername(validateUsername.test(regInfo.username));
  setValidPassword(validatePassword.test(regInfo.password));
  setPasswordTest(regInfo.password === regInfo.password2);
  setValidateCount(validateCount + 1);
  setIsLoading(false);

}

// handling the submit function
async function postRegInfo() {
  setIsLoading(true);


  if (!validEmail || !validUsername || !validPassword || !passwordTest) {
  ;
  } else {
    const response = await AuthService.register(regInfo)
    setLoginResponse(response.data)
    setLoginAttempt(true);
    if (response.data.user_registered) {
      navigate('/auth/registersuccess');
    }
  }
setIsLoading(false);


} 

    return (
        <FlexWrapper>
        <FieldInputContainer>
          
            <EmailInput
              name="email"
              placeholder="email"
              type="email"
              onChange={handleChange}
            />
            <UsernameInput
              name="username"
              placeholder="username"
              type="text"
              onChange={handleChange}
            />
            <PasswordInput
              name="password"
              placeholder="password"
              type="password"
              autoComplete='new-password'
              onChange={handleChange}
            />
            <Password2Input
              name="password2"
              placeholder="re-type password"
              type="password"
              autoComplete='new-password'
              onChange={handleChange}
            />
            <SubmitButton onClick= {async () => {validate()}}>register</SubmitButton>
        </FieldInputContainer>
        <p>{(!validEmail) && "invalid email"}</p>
        <p>{(!validUsername) && "invalid username. letters, numbers and under_scores's only"}</p>
        <p>{(!validPassword) && "invalid password. must be at least 6 characters long and contain at least one letter and one number"}</p>
        <p>{(!passwordTest) && "passwords don't match, try again."}</p>

        <p>{(loginResponse.email_exists && loginAttempt) ? 
        "email already registered, please login to continue." : 
        ((loginResponse.username_exists && loginAttempt) && "username taken, try being more original."
        //  : 
        // (!passwordTest && loginAttempt && "passwords don't match, please try again.")
        )}</p>
        <p> already registered? <Link to='/auth/login'>click here to login</Link> </p>
       
        
      </FlexWrapper>

    );
}

export default UserRegister;

const FlexWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`;

const FieldInputContainer = styled.div`
  margin:10px 50px 10px 50px;
  height: auto;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  display: grid;
  grid-column: 1/ span 1;
  
`;

const SubmitButton = styled.button`
width: 100%;
height: auto;
border-radius: 4px;
font-size: 32px;
padding: 8px;
margin-top: 8px;
background-color: grey;
color: white;
grid-column: 1/ span 1;
grid-row: 5/ span 1;
box-sizing: border-box;
text-align: left;
cursor: pointer;
`

const ExtraInfo = styled.div`

  `


const Input = styled.input`
  width: 100%;
  padding: 8px;
  margin-top: 8px;
  font-size: 32px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;

`

const EmailInput = styled(Input)`
  grid-column: 1/ span 1;
  grid-row: 1/ span 1;
`;

const UsernameInput = styled(Input)`
  grid-column: 1/ span 1;
  grid-row: 2/ span 1;
`;

const PasswordInput = styled(Input)`
grid-column: 1/ span 1;
grid-row: 3/ span 1;
`;

const Password2Input = styled(Input)`
grid-column: 1/ span 1;
grid-row: 4/ span 1;
`;