import React, { useState, useRef, useEffect } from "react";
import styled from 'styled-components';
// import StoryService from "../services/StoryService";
import OpenAiService from "../services/OpenAiService";
// import { useNavigate, redirect } from "react-router-dom";
// import { StoryContext } from "../context/StoryContext";
// import Switch from '@mui/material/Switch';
import StyledSwitch from "./StyledSwitch";
import Modal from 'react-modal';
import {Link} from "react-router-dom";

import InfoBox from "./InfoBox";


// Components Import
import GridWrapper from "./GridWrapper";
import TextareaAutosize from '@mui/base/TextareaAutosize';
// import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';

// import a react send icon
import { FaPaperPlane } from "react-icons/fa";

// import cancel button from mui icons



function WritingBuddySample() {

 
  const [writingBuddy, setWritingBuddy] = useState(true);
  const [isRequesting, setIsRequesting] = useState(false);
  const textAreaRef = useRef(null);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  
  const customStyles = { 
    overlay: {
    // overflowY: 'scroll',
    maxHeight: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 99999 // set the z-index of the overlay to a high value
  },
    content : {
      display               : 'flex',
      flexDirection         : 'column',
      justifyContent        : 'center',
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      width                 : '70%',
      height                : '70%',
      transform             : 'translate(-50%, -50%)',
      backgroundColor: 'var(--color-background)',
    zIndex: 99999   
  }
  };

  const [newStory, setNewStory] = useState({
    storyroot: true,
    title: "",
    content: ""
    // user details will be sent with the req object.
  });

  const charCount = newStory.content.length;
  const charLimit = 300;
  const overLimit = charCount > charLimit;

  // follow the cursor as text is added to textarea
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
    }
  }, [newStory.content]);


  // set modal to open if overlimit is true
  useEffect(() => {
    if (overLimit) {
      setModalIsOpen(true);
    }
  }, [overLimit])



  // format punctuation after request is finished
  useEffect(() => {
    if (!isRequesting) {
      if (newStory.content.length > 0) {
        formatContent(newStory.content)
      }
    }
  }, [isRequesting])

  // handler to update the title and content state
  function handleChange(event) {
    const { name, value } = event.target;
    setNewStory((prevData) => {
      return {
        ...prevData,
        [name]: value
      };
    });

    if (value.length < newStory.content.length) {
      ;
      return;
    }

    const isTerminal = isLastCharTerminalPunctuation(value);

    if (name === "content" && writingBuddy && isTerminal && !isRequesting) {
      // addSpace();
      generateNextSentence(value);
    }
  }





  function formatContent(content) {
    // Trim the content first
    content = content.trim();


    // Regular expression for checking terminal punctuation
    const terminalPunctuation = isLastCharTerminalPunctuation(content)

    if (!terminalPunctuation) {
      // If the last character is not terminal punctuation, add a full stop
      return setNewStory((prevData) => {
        return {
          ...prevData,
          content: prevData.content + ". "
        };
      });
    } else {
      // If the last character is terminal punctuation but not a space, add a space
      return setNewStory((prevData) => {
        return {
          ...prevData,
          content: prevData.content + " "
        };
      });
    }

  }

  function isLastCharTerminalPunctuation(str) {
    // 
    const lastChar = str.slice(-1);
    return /[.!?]$/.test(lastChar);
  }


  async function addSpace() {
    // add a space
    return setNewStory((prevData) => {
      return {
        ...prevData,
        content: prevData.content + " "
      };
    });
  }

  // generate next sentence of the story
  async function generateNextSentence(str) {
    
    
    setIsRequesting(true);
    addSpace();

    for await (const content of OpenAiService.generateNextSentence(str)) {
      setNewStory((prevData) => {
        return {
          ...prevData,
          content: prevData.content + content
        };
      });
    }
    setIsRequesting(false);

  }





  return (
    <>

        <FlexWrapper>


          <TextAreaWrapper>
            <StyledTextArea
              placeholder="How Does Your Story Begin...?"
              name="content"
              ref={textAreaRef}
              value={newStory.content}
              onChange={handleChange} />
              <RowWrapper>
            <p>{(overLimit) ? "over the limit buddy, reel it in a little" : `${charCount} / ${charLimit}`}</p>
        
          <Pad padding={'0.2em'} />
          <SwitchWrapper>
            <StyledSwitch checked={writingBuddy} onToggle={setWritingBuddy} />
            <Pad />
            {/* <InfoBox /> */}
            <p>Writing Buddy {writingBuddy? 'On' : 'Off'}</p>
            
          </SwitchWrapper>
          </RowWrapper>
          </TextAreaWrapper>
        
        </FlexWrapper>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <FlexWrapper>
          <h2>Looks like you're having fun!</h2>
          <p>Why not join the party? It's free!</p>
          <CTALink to="/auth/register">Get Writing!</CTALink>
          </FlexWrapper>
          {/* <button onClick={() => setModalIsOpen(false)}>close</button> */}
        </Modal>






    </>

  )
}

// const FlexWrapper = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
// `;



const CTALink = styled(Link)`
width: auto;
  display: inline-block;
  background-color: var(--color-secondary); 
  color: var(--color-background);
  font-weight: bold;
  border-radius: 1rem;
  padding: 1rem 1.3rem;
  ${'' /* font-size: 2.5rem; */}
  text-decoration: none;
  cursor: pointer;
  transition: ease-in-out 200ms;
  &:hover {
        transform: scale(1.2);
        ${'' /* color: var(--color-background) */}
    }
&:active {
transform: scale(1.2);
${'' /* color: var(--color-secondary); */}
}

`;

const SwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  `

const FlexWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
`
const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`



const TextAreaWrapper = styled.div`
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
  width: 100%;
`

const StyledTextArea = styled.textarea.attrs({
  rows: 15,
})`
  width: 100%;
  overflow: auto;
  flex-grow: 1;
  padding: 0.5em;
  rows: 10;
  resize: none;
  vertical-align: center;
  horizontal-align: center;
  font-size: 1em;
  outline: none;
  border-radius: 7px;
  box-sizing: border-box;
  border-color: var(--color-primary);
  color: var(--color-primary);
  background-color: var(--color-background);
  ::placeholder {
    color: var(--color-secondary);
    opacity: 0.8;
};
  &:hover {
    ::placeholder {
      opacity: 1;
    };
  };
&:focus {
  outline: none;
  ::placeholder {
    transition: opacity 0.5s 0.5s ease!important;
    opacity: 0.35;
    color: var(--color-secondary);
};
};
`


const Pad = styled.div`
  padding: 1em;
  padding: ${(props) => props.padding};

  `


export default WritingBuddySample;
