// create a component that has a form similar to that in UserLogin.jsx, that has one input: email.
// the form should have a submit button that calls a function that sends a post request to the backend.

// the backend should have a route that receives the post request and sends an email to the email address that was entered in the form.


import React, { useState, useEffect, useContext } from "react";
import AuthService from "../services/AuthService";
import { useNavigate, Link } from "react-router-dom";
import styled from "styled-components";
import { AuthContext } from "../context/AuthContext";

function ForgotPasswordPage() {
  const navigate = useNavigate();

//   const { isAuthenticated, user } = useContext(AuthContext);

  // handle the state of input data

  const [email, setEmail] = useState("");

  const [submitAttempt, setSubmitAttempt] = useState(false);
  const [emailExists, setEmailExists] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // handle data as it is entered in input areas (event)
  function handleChange(event) {
    setEmail(event.target.value);
    // 
  }


  //// YOU ARE HERE ////
  // need to check the page in react browser to see if the handle change is working
  // to put the typed email into the input space.


  // handling the submit function
  async function postForgotPassword() {
    setIsLoading(true);
    // event.preventDefault();
    await AuthService.forgotPassword({email:email}).then( async (response) => {
      setSubmitAttempt(true);
      if (response?.data) {
        setEmailExists(response.data?.email_exists);
      }
      setIsLoading(false);
    });
    
  }

  return (
    <>
      {isLoading ? <h1>Loading...</h1> : 
      
      <FlexWrapper>
          <Pad height="10rem"/>

          <FieldInputContainer>

            <EmailInput
              name="email"
              placeholder="email"
              type="email"
              autoComplete='on'
              onChange={handleChange}
            />


          <SubmitButton onClick={postForgotPassword}>Submit</SubmitButton>

          </FieldInputContainer>
          <Pad height="3rem"/>

          {!submitAttempt ? <p>please enter your email and we'll send you a password reset link</p>
  : (emailExists ? <p>A reset link has been sent to your email.</p> 
  : <p>email not found, please try again</p>)}

        <Pad height="10rem"/>
      </FlexWrapper>
      
      }
     
    </>
  );
}

export default ForgotPasswordPage;

const FlexWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`;


const FieldInputContainer = styled.div`
  margin:10px 50px 10px 50px;
  height: auto;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  display: grid;
  grid-column: 1/ span 1;
`;




const SubmitButton = styled.button`
width: 100%;
height: auto;
border-radius: 4px;
font-size: 32px;
padding: 8px;
margin-top: 8px;
background-color: grey;
color: white;
grid-column: 1/ span 1;
grid-row: 2/ span 1;
box-sizing: border-box;
text-align: left;
cursor: pointer;
`



const Input = styled.input`
  width: 100%;
  padding: 8px;
  margin-top: 8px;
  font-size: 32px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;

`

const EmailInput = styled(Input)`
  grid-column: 1/ span 1;
  grid-row: 1/ span 1;
`;



const Pad = styled.div`
  height: ${(props) => props.height};
`