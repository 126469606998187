import React from "react";
import styled from "styled-components";
import ExplorerSwiper from "../components/ExplorerSwiper";
import { useAllStoriesAndForksContext } from "../context/AllStoriesAndForksContext";
import 'swiper/css';

const Pad = styled.div`
  padding: 1rem;
`;

function StoryExplorer() {
  const { rootStories, allStories, isLoading } = useAllStoriesAndForksContext();

  return (
    <>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <>
          <Pad />
          <ExplorerSwiper 
            stories={rootStories} 
            title="Explore Stories" 
            type="rootStoriesArray"
          />
          <Pad />
          <Pad />

          <ExplorerSwiper 
            stories={allStories} 
            title="Explore All Submissions" 
            type="allStoriesArray"
          />
           <Pad />
        </>
      )}
    </>
  );
}

export default StoryExplorer;