// create a component that loads data from the backend
// then passes it to the storybuilder component

// this component will be used to load the storybuilder component
// with data from the backend

import React, {useState, useEffect, useContext} from 'react';
import { useParams, useLocation } from 'react-router-dom';

import StoryBuilder from '../pages/StoryBuilder';

// this component will be used to pass data fetched for the route
// "story/:forkid/fork/:forkid" to the storybuilder component

import {StoryContext} from '../context/StoryContext';
import StoryService from '../services/StoryService';


function ForkLoader() {

    console.log(`ForkLoader rendered`)
    const location = useLocation();

 


    const {forkid} = useParams();
    const {storytitle} = useParams();
    const {fetchedData, setFetchedData} = useContext(StoryContext);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {storyLoader()}, [forkid]);

    // async function scrollToFork() {
    //     if (forkid) {
    //         const element = document.getElementById(forkid);
    //         alert(`element: ${element}`)
    //         if (element) {
    //           element.scrollIntoView({ behavior: 'smooth' });
    //         }
      
    //       }
    // }


    useEffect(() => {
        // If there's a hash in the URL, try to scroll to the element
        
    
      }, []);


    const storyLoader = async () => {
        await setIsLoading(true);
        await setFetchedData(null);
        await StoryService.getForkParents(storytitle,forkid)
        .then(async  res => {
            ;
            return res.data;
        }
        ).then(async (data) => {
            await setFetchedData(data);
            await setIsLoading(false);
            // 

        })
        .catch(err => {
            ;
        }
        )
    }

    return (
        <div>
            {!isLoading ? <StoryBuilder /> : <p>something</p>}
        </div>
    )
}


export default ForkLoader;