import React from "react";

function NotFound() {

  return (
    <div>
      <h1>
        Page Not Found
      </h1>
      <p>Please Try again</p>

    </div>

  );
}

export default NotFound;
