import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import CharacterService from '../services/CharacterService';
import { AuthContext } from '../context/AuthContext';
import StoryService from '../services/StoryService';

import styled from 'styled-components';

import CharacterCard from '../components/cards/CharacterCard';
import { SolidButton, CustomLink } from '../components/StyledWidgets';

const PageWrapper = styled.div`
padding: 1em;
`;

const Pad = styled.div`
    padding: 1rem;
`;

const Heading = styled.h1`
    font-weight: bold;
    margin-bottom: 1rem;
    margin-left: 1rem;
`;

const Button = styled.button`
  background-color: var(--color-primary);
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
`;

function CharacterPage() {

     const {user} = useContext(AuthContext);
    const { characterid } = useParams();
    const [characterData, setCharacterData] = useState({});
    const [isLoading, setLoading] = useState(true);
    const navigate = useNavigate();

    // test to see if the character id is in the array user.authoredCharacters
    // make sure that the user is logged in
    // and the authoredCharacters field is in the user object
    const userOwnsCharacter = user && user.authoredCharacters && user.authoredCharacters.includes(characterid);
    // alert(userOwnsCharacter)
    // alert(JSON.stringify(user))

    useEffect(() => {
        setLoading(true);
        StoryService.getUserDetails()
        CharacterService.getCharacter(characterid)
        .then(res => {
            setCharacterData(res.data);
        })
        .catch(err => {
            console.error(err);
        })
        .finally(() => {
            setLoading(false);
        })
    }, [characterid]);

    // set up a useEffect to setCharacterData to dummy character
    // useEffect(() => {
    //     setCharacterData(niceClown);
    // }, []);


    // const handleEdit = () => {
    //     // Add your function to handle edit here
    // };

    function handleDelete () {
        CharacterService.deleteCharacter(characterid)
        .then(res => {
            console.log(res);
            alert(JSON.stringify(res.data.message))
            navigate(-1);
        })
        .catch(err => {
            console.error(err);
        })
    }

    return (
      <PageWrapper>
        {(!isLoading && characterData) ?

(isLoading ? <p>Loading...</p> :
<>
        <CharacterCard key={characterData._id} {...characterData} />
        {
                    userOwnsCharacter &&
                    <>
                <Pad />
        <CustomLink to="/characterbuilder" state={{characterData: characterData}}>
            Edit Character
        </CustomLink>
        <Pad />
        <SolidButton onClick={handleDelete}>Delete Character</SolidButton>
        <Pad />
</>
        }

        </>

)
:
<p>Character not found</p>
}



      </PageWrapper>  
    );
}

export default CharacterPage;
