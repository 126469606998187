import React, { useState, useEffect } from 'react';
import AuthService from '../services/AuthService';
import { useNavigate, Link, useParams } from 'react-router-dom';

import styled from 'styled-components';



function ResetPasswordPage() {

  const navigate = useNavigate();

  const {id, token} = useParams();


// user lands here from email link
// on landing, the page needs to check that the token matches the token in the database
// if the token matches, the user can enter a new password


const [tokenValid, setTokenValid] = useState(false);
const [tokenExpired, setTokenExpired] = useState(false);


  // handle the state of input data
const [passwordInfo, setRegInfo] = useState({
  password: "",
  password2: ""
});



const [resetSuccess, setResetSuccess] = useState(false);

const [resetAttempt, setResetAttempt] = useState(false);
const [isLoading, setIsLoading] =useState(false);



useEffect(() => {
  async function verifyPasswordResetToken() {
      alert(`verify email called with id: ${id} and token: ${token}`)
    const response = await AuthService.verifyPasswordResetToken(id, token);
    setTokenValid(response.data?.token_valid);
    setTokenExpired(response.data?.token_expired);
  }
  verifyPasswordResetToken();
  setIsLoading(false);

}, []);


// handle data as it is entered in input areas (event)
function handleChange(event) {
  const { name, value } = event.target;

  setRegInfo((prevNote) => {
    return {
      ...prevNote,
      [name]: value
    };
  });
}
 

// handling the submit function
async function postReset(event) {
  setIsLoading(true);
  // event.preventDefault();
  if (passwordInfo.password !== passwordInfo.password2) {
    alert('passwords do not match, please try again');
    setIsLoading(false);
    return;
  }

  
  const response = await AuthService.postReset(id, token, {password:passwordInfo.password})
  setResetSuccess(response.data?.password_reset)
  setResetAttempt(true);
  setIsLoading(false);

} 

    return (



        <FlexWrapper>
                <Pad height="10rem" />

        {isLoading ? <p>Loading...</p> :
        
        (tokenValid && !tokenExpired) ?
        
        <FieldInputContainer>
            <PasswordInput
              name="password"
              placeholder="password"
              type="password"
              autoComplete='new-password'
              onChange={handleChange}
            />
            <Password2Input
              name="password2"
              placeholder="re-type password"
              type="password"
              autoComplete='new-password'
              onChange={handleChange}
            />
            <SubmitButton onClick= {postReset}>Submit</SubmitButton>
        </FieldInputContainer>
        :
        <ExtraInfo>
        <Pad height="3rem" />
        {tokenExpired ? <p>Sorry, this link has expired. Please request a new password reset link.</p> : <p>Sorry, this link is invalid. Please request a new password reset link.</p>}
        <Pad height="3rem" />

        <ForgotPasswordP><Link to="/auth/login">Login</Link></ForgotPasswordP>
        </ExtraInfo>

        }

        <Pad height="10rem" />

      </FlexWrapper>

    );
}

export default ResetPasswordPage;

const FlexWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`;

const FieldInputContainer = styled.div`
  margin:10px 50px 10px 50px;
  height: auto;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  display: grid;
  grid-column: 1/ span 1;
  
`;

const ForgotPasswordP = styled.p`
font-size: 1.5rem;
text-align: center;
`

const SubmitButton = styled.button`
width: 100%;
height: auto;
border-radius: 4px;
font-size: 32px;
padding: 8px;
margin-top: 8px;
background-color: grey;
color: white;
grid-column: 1/ span 1;
grid-row: 5/ span 1;
box-sizing: border-box;
text-align: left;
cursor: pointer;
`

const ExtraInfo = styled.div`

  `


const Input = styled.input`
  width: 100%;
  padding: 8px;
  margin-top: 8px;
  font-size: 32px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;

`

const PasswordInput = styled(Input)`
grid-column: 1/ span 1;
grid-row: 3/ span 1;
`;

const Password2Input = styled(Input)`
grid-column: 1/ span 1;
grid-row: 4/ span 1;
`;

const Pad = styled.div`
  height: ${(props) => props.height};
`