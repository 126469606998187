import { React, useReducer, useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useLoaderData, useParams, useActionData } from 'react-router-dom';
import StoryBlockV2 from './StoryBlockV2';
import styled from 'styled-components';
import { Swiper, SwiperSlide} from 'swiper/react';
import StoryService from "../services/StoryService"
import CreateFork from './CreateFork';
// import CommentCountIcon from './CommentCountIcon';
import CommentButtonModal  from './CommentButtonModal';

import useOuterClick from './useOuterClick';
import { StoryContext } from "../context/StoryContext";

import _ from "lodash";

import GridWrapper from './GridWrapper';

// import a comment outline icon from react icons
import {VscComment} from 'react-icons/vsc';

// Import Swiper styles
import 'swiper/css';
import "swiper/css/navigation";


// import required modules for swiper component
import { Navigation, Pagination, Keyboard } from "swiper";
import { fabClasses } from '@mui/material';




function StorySwiper(props) {
    

    const {fetchedData, setFetchedData} = useContext(StoryContext);
    const [activeIndex, setActiveIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [isClickedFork, setIsClickedFork] = useState(false);
    const [isInfoShowing, setIsInfoShowing] = useState(false);
    // const newForkInnerRef = useOuterClick(() => { handleClickOut() });
    const [isInitialRender, setIsInitialRender] = useState(true);
    const [prevButtonDisabled, setPrevButtonDisabled] = useState(true);
    const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
    const [showChildren, setShowChildren] = useState(true);
    


    // these allow us to create custom navigation buttons
    const swiperRef = useRef();

    // this useEffect function is called when the component is initially rendered
    // it helps to avoid multiple renders of the component
    useEffect(() => {
        initialiseStory();
        }, [isInitialRender]);

// this function helps with finding the active index of the 
// story array. It is called when the component is rendered.

async function setStoryActiveIndex(indexArray,activeIndex) {
    setIsLoading(true);



    if (_.isEmpty(indexArray)) {
        var newData = fetchedData.slice();
        var dataArray = newData;

    } else {
        var {newData, dataobj} = locateInStory(indexArray);
        dataArray = dataobj.children;

    }
    // remove activeIndex field from all objects in the array
    // dataArray = dataArray.map(({activeIndex, ...rest}) => rest);
    dataArray.forEach(object => {
        delete object['activeIndex'];
        });


    // add activeIndex field to the array at the activeIndex
    dataArray[activeIndex].activeIndex = true;


    await setFetchedData(newData);
    setIsLoading(false);

}


    function locateInStory(indexArray) {
        
        // for some reason, .slice() allows us to modify the fetched data
        var newData = fetchedData.slice();
        
        // locatefunction finds the childarray in fetchedData
        // passed back from storyblock
        function locateFunction(accumulator,curvalue,index) {

            if (index === 0) {
                return accumulator[curvalue]
            } else {
                return accumulator.children[curvalue]
            }   
        }
        
            // .reduce takes an array and iteratively applies a function, 
            // returning a single value/object
            // in this case we're reducing the 'indexArray'
            // and returning the specific object we want to modify
        
        // dataobj is the parent object of the target story
        const dataobj = indexArray.reduce(locateFunction,newData)
        
        return {newData, dataobj};
    }
        



    // function handleClickOut() {
    // setIsClickedFork(false);
    // }

    // function handleDoubleClick(event) {
    //     if (event.detail === 2) {
    //         setIsClickedFork(!isClickedFork);
    //     }
    // }

    function handleSwiperNavigation(swiper) {
        if (swiper.isBeginning) {
            setPrevButtonDisabled(true);
        } else {
            setPrevButtonDisabled(false);
        }
        if (swiper.isEnd) {
            setNextButtonDisabled(true);
        } else {
            setNextButtonDisabled(false);
        }
    }


    function handleSwiper(swiper) {

        setIsLoading(true);
        // alert(`swiper.activeIndex: ${swiper.activeIndex}`)
        setActiveIndex(swiper.activeIndex);

        // need to make sure that activeIndex for the current storyarray is being rendered
        {setStoryActiveIndex(props.indexArray,swiper.activeIndex)};

        // Run the function to fetch the index of the child array
        // remember to send the swiper.activeIndex in the function as it won't have access to 
        // activeIndex in state

        setIsLoading(false);
        
      };

// function refreshComponent() {
//     setRefreshed(!refreshed)
// }


      function initialiseStory() {
        setIsLoading(true);
        setIsInitialRender(false);

        // set the activeIndex of the current storyarray
        {setStoryActiveIndex(props.indexArray,activeIndex)};

        setIsLoading(false);
        
      };

      async function loadMoreStories(indexArray,response) {
        setIsLoading(true);

        if (_.isEmpty(indexArray)) {

            
            var newData = fetchedData.slice();
            newData = newData.concat(response.data);
    
        } else {
            var {newData, dataobj} = locateInStory(indexArray);
            dataobj.children = dataobj.children.concat(response.data);
        }
        
        setFetchedData(newData);
        setIsLoading(false);

        return(response);
    }

      async function fetchSiblingStories() {
        setIsLoading(true);

        // map props.storyArray to get an array of story ids
        const idArray = props.storyArray.map((story) => {
            return story._id
        });

        const searchId = props.parentId ?? 'root'
        if (searchId === 'root') {
            alert('root case for fetchSiblingStories')
        }

        const response = await StoryService.getMoreSiblings(searchId,3,idArray);
        if (response.data.length !== 0) {
            await loadMoreStories(props.indexArray,response); 

            // setStoryActiveIndex(props.indexArray,activeIndex);

        }
        
        setIsLoading(false);
        setNextButtonDisabled(false);
        
      };



        // this function loads more data when the last swiper is reached and there are 
        // more child stories to load
        async function fetchChildrenStories() {
            setIsLoading(true);
    
            const indexArray = props.indexArray.concat(activeIndex);
            const response = await StoryService.getChildren(props.storyArray[activeIndex]._id,3);
        
    
            if (response.data.length !== 0) {
                await loadMoreStories(indexArray,response); 
            }
            setIsLoading(false);
    
          };



    return (
        <>

            <FlexWrapper 
            // ref={newForkInnerRef} 
            // onClick={handleDoubleClick}
            >
                <SwiperWrapper>

                <ColWrapper>
                        <SwiperButton
                            opacity={isClickedFork ? "0" : (prevButtonDisabled ? "0.1" : "0.8")}
                            onClick={() => swiperRef.current?.slidePrev()}
                            disabled={isClickedFork}
                        >
                            <NavigationWrapper>
                                <SwiperIconPrev> &lt; </SwiperIconPrev>

                            </NavigationWrapper>
                        </SwiperButton>
                </ColWrapper>
                
                

                    
                    <Swiper
                        autoHeight={true}
                        allowTouchMove= {true}
                        // autoWidth={false}
                        // centeredSlides={true}
                        observer={true}
                        observeParents={true}
                        resizeObserver={true}
                        // text-align={'true'}
                        followFinger={true}
                        slidesPerView={1}
                        initialSlide={props.storyArray.findIndex((story) => {return story?.activeIndex})}
                        onBeforeInit={(swiper) => {
                        swiperRef.current = swiper;
                        }}
                        onInit={(swiper) => {
                            handleSwiperNavigation(swiper);
                            swiper.update();
                        }}
                        onSlideChange={(swiper) => {
                            handleSwiperNavigation(swiper);
                        }}

                        keyboard={{ enabled: false }}
                        modules={[Navigation, Pagination, Keyboard]}
                        onReachEnd={ () => {
                            const childrenCount = (props.parentStory?.childrenCount ?? 0)
                        
                            const childrenLength = (props.parentStory?.children?.length ?? 0)
                            
                            if ((childrenCount > childrenLength)) {

                                fetchSiblingStories();
                            }
                            }

                            }

                        onActiveIndexChange={ (swiper) => {
                            handleSwiper(swiper);
                            ;}}
             >
                        {props.storyArray.map((story, index) => {

                            if (story == undefined) {return;}

                            
                          
                            return (
                                <CustomSlide key={`swiperslide-${story._id}`} id={`swiperslide-${story._id}`}>
                                    <StoryBlockV2

                                        key={story._id}
                                        {...story}
                                        id={story.shortId || story._id}
                                        storyId={story._id}
                                        userSubmitted={story.userSubmitted}
                                        title={story.title}
                                        likes={story.likes}
                                        likedByUser={story.likedByUser}
                                        segment={story.segment}
                                        dateSubmitted={story.createdAt}
                                        storyRoot={story.storyRoot}
                                        parentRoot={story.parentRoot}
                                        storyTitle={props.storyTitle}
                                        indexArray={[...props.indexArray,index]}
                                        childrenCount={story.childrenCount}
                                        children={story.children}
                                        
                                        isClickedFork={isClickedFork}
                                        setIsClickedFork={setIsClickedFork}
                                        forkClick={() => { setIsClickedFork(!isClickedFork) }}
                                        setIsInfoShowing={setIsInfoShowing}
                                        isInfoShowing={isInfoShowing}
                                        likeClick={props.changeLiked}
                                        toggleChildren={() => { setShowChildren(!showChildren) }}
                                        swiperRef={swiperRef}
                                        addStory={props.addStory}
                                        editStory={props.editStory}

                                    />

{/* {isClickedFork &&  
                    <ForkContainer>
                        <CreateFork

                            key={story._id}
                            {...story}
                            id={story.shortId || story._id}
                            storyId={story._id}
                            userSubmitted={story.userSubmitted}
                            title={story.title}
                            likes={story.likes}
                            likedByUser={story.likedByUser}
                            segment={story.segment}
                            dateSubmitted={story.createdAt}
                            storyRoot={story.storyRoot}
                            parentRoot={story.parentRoot}
                            storyTitle={props.storyTitle}
                            indexArray={[...props.indexArray, index]}
                            childrenCount={story.childrenCount}
                            children={story.children}

                            isClickedFork={isClickedFork}
                            forkClick={() => { setIsClickedFork(!isClickedFork) }}
                            likeClick={props.changeLiked}
                            toggleChildren={() => { setShowChildren(!showChildren) }}
                            swiperRef={swiperRef}
                            addStory={props.addStory}
                            editStory={props.editStory}
                        />
             </ForkContainer>
            } */}


                                </CustomSlide>
                            );
                        })}
                        

                    </Swiper>

                   
                    <ColWrapper>
                    <SwiperButton 
                    opacity={isClickedFork ? "0" : (nextButtonDisabled ? "0.1" : "0.8")} 
                    disabled={isClickedFork}
                    onClick={() => {setIsClickedFork(false); swiperRef.current?.slideNext();}}>
                    <NavigationWrapper>
                    <SwiperIconNext> &gt; </SwiperIconNext>

                    </NavigationWrapper>
                    
                    </SwiperButton>
                    {/* Need to add functionality to show comment count
                    VSCComent below can be put into a component and passed into this component
                    pass in story id, which will fetch comments, and do lazy load  */}
                    {/* <CommentCountIcon count={props.storyArray[activeIndex].comments.length}/> */}
{/* <CommentButtonModal storyId={props.storyArray[activeIndex]._id} count={props.storyArray[activeIndex].comments.length} /> */}
                    </ColWrapper>



                </SwiperWrapper>

               
            

        

           </FlexWrapper>

            {/* This is where the functionality will go  */}




           {
            // check to see if the current story has children
            (!isLoading && 
            !isClickedFork 
            && 
            props.storyArray[activeIndex].children.length === 0 && 
            props.storyArray[activeIndex].childrenCount > 0
            ) && 
            <GridWrapper>
        <LoadMoreButton onClick={()=> {fetchChildrenStories();}}> 
        <LoadMoreText>
            Load More Forks...
        </LoadMoreText>
        </LoadMoreButton>
        </GridWrapper>
           }
{
            (!isLoading &&
            !isClickedFork && 
            showChildren &&
            props.storyArray[activeIndex].children.length > 0 && 
            typeof props.storyArray[activeIndex].children[0] === 'object'
            ) &&
            <>
            <Pad/>
            <Line/>
                <StorySwiper               
                isClickedFork={isClickedFork || props.isClickedFork}
                parentId = {props.storyArray[activeIndex]._id}
                key={props.storyArray[activeIndex]._id}  
                storyTitle={props.storyTitle}
                parentStory={props.storyArray[activeIndex]}
                storyArray={props.storyArray[activeIndex].children}
                indexArray={[...props.indexArray,activeIndex]}
                changeLiked={props.changeLiked}
                addStory={props.addStory}
                editStory={props.editStory}

  
                />
                </>
                } 


        </>
    );
}


const CustomSlide = styled(SwiperSlide)`
    display: flex;
    flex-grow: 1;
    width: 100vw;
    height: auto;
    `

const FlexWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${'' /* padding: 0 1em ; */}
    width: 100%;
`
const ColWrapper = styled.div`
    display: flex;
    flex-direction: column;
    color: var(--color-primary);
    `


const NavigationWrapper = styled.div`

`

const Pad = styled.div`
padding: 0.1rem;
z-index: 1;
`

const Line = styled.hr`
border: none;
border-top: 0.1em dotted var(--color-primary) ;
opacity: 0.1;
${'' /* color: #fff; */}
background-color: var(--color-background);
height: 0.05em;
width: 61.8%;
`
// const ForkContainer = styled.div`
// width: 90%;
// font-size: 16px;
// margin: auto;
// `

const SwiperButton = styled.button`
    ${'' /* opacity: ${props => props.isClickedFork ? "0" : (props.nextButtonDisabled ? "0.1" : "1.0")}; */}
    opacity: ${(props) => props.opacity};
    background-color: var(--color-background);
    border: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    height: 100%;
    cursor: pointer;
    color: var(--color-primary);
    font-size: 0.5em;
    padding: 0;
`



const SwiperIcon = styled.h1`
    height:100%;
    text-align: center;
    vertical-align: middle;
    &:hover {
      color: var(--color-secondary);
    };
`

const SwiperIconPrev = styled(SwiperIcon)`
padding-right: 0.5em;
`
const SwiperIconNext = styled(SwiperIcon)`
padding-left: 0.5em;

`



const LoadMoreButton = styled.button`
    background-color: var(--color-background);
    ${'' /* color: var(--color-background); */}
    border: none;
    margin: 60px 0;

    grid-column: 2 / span 1;
  grid-row: 2 / span 1;
  text-align: left;
  &:hover {
      color: var(--color-secondary);
    };

`

const LoadMoreText = styled.h4`
    ${'' /* font-size: 40px; */}
    &:hover {
      color: var(--color-secondary);
    };
`



const SwiperWrapper = styled.div`
    visibility: ${(props) => props.visibility};
    display: flex;
    justify-content: center;
    color: white;
    text-align: center;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
`

export default StorySwiper;


