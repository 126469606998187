import React, { useState } from 'react';
import styled from 'styled-components';

// Modal styles
const Modal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  padding: 10px;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
`;

const IconContainer = styled.div`
  position: relative;
  display: inline-block;
`;

function InfoModal() {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <IconContainer>
      <sup
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        style={{ cursor: 'pointer' }}
      >
        i
      </sup>

      {isHovering && (
        <Modal>
          This is some information that will show when you hover over the "i" icon.
        </Modal>
      )}
    </IconContainer>
  );
}

export default InfoModal;
