import { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const Container = styled.div`
  width: 600px;
  margin: 0 auto;
`;

const Textbox = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const Suggestions = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Suggestion = styled.li`
  padding: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
`;

const Selected = styled.div`
  margin-top: 20px;
`;

const Title = styled.h3`
  margin: 0;
`;

const Description = styled.p`
  margin: 10px 0;
`;

const Image = styled.img`
  width: 100%;
`;

const SearchBar = () => {
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `https://dummyjson.com/products/search?q=${value}`
        );
        setSuggestions(data.products);
      } catch (error) {
        ;
      }
    };
    fetchData();
  }, [value]);

  const handleSelectSuggestion = (suggestion) => {
    setSelectedSuggestion(suggestion);
    setValue(suggestion.title);
    setSuggestions([]);
  };

  return (
    <Container>
      <Textbox
        type="text"
        placeholder="Search data..."
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onBlur={() => {
          setTimeout(() => {
            setSuggestions([]);
          }, 100);
        }}
      />
      {suggestions.length > 0 && (
        <Suggestions>
          {suggestions.map((suggestion) => (
            <Suggestion
              key={suggestion.id}
              onClick={() => handleSelectSuggestion(suggestion)}
            >
              {suggestion.title}
            </Suggestion>
          ))}
        </Suggestions>
      )}
      {selectedSuggestion && (
        <Selected>
          <Title>{selectedSuggestion.title}</Title>
          <Description>{selectedSuggestion.description}</Description>
          <Image src={selectedSuggestion.image} alt={selectedSuggestion.title} />
        </Selected>
      )}
    </Container>
  );
};

export default SearchBar;
