import axios from 'axios';

axios.defaults.withCredentials = true


if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    
    var api_character_string = `http://${process.env.REACT_APP_ADDRESS}:3000/api/character`

} else {
    
    api_character_string = `${process.env.REACT_APP_BACKEND_API_ADDR_PROD}/character`
}



// const api_story_string = `http://${process.env.REACT_APP_ADDRESS}:3000/api/stories`
// const api_user_string = `http://${process.env.REACT_APP_ADDRESS}:3000/api/users`






export default {

    /// Character Service

    postCharacter: async (username, character) => {
            
            return await axios.post(`${api_character_string}/create`, {username, character})
            .then(res => { return res })
            .catch(error => console.log(error))
        },

        getCharacter: async (characterid) => {
                
            return await axios.get(`${api_character_string}/characters/${characterid}`)
            .then(res => { return res })
            .catch(error => console.log(error))
        },
        getCharacters: async () => {
                
            return await axios.get(`${api_character_string}/characters`)
            .then(res => { return res })
            .catch(error => console.log(error))
        },
        deleteCharacter: async (characterid) => {
                    
                return await axios.delete(`${api_character_string}/characters/${characterid}/delete`)
                .then(res => { return res })
                .catch(error => console.log(error))
            }



   
    



}


