// reduce the font size
// change the format
// make it like  tommy: "this is a comment" etc.

import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import StoryService from "../services/StoryService";
import Comment from '../components/Comment';
import { FaPaperPlane } from 'react-icons/fa';
import { MyButton } from './StyledWidgets';
import { AuthContext } from "../context/AuthContext";
import AuthCheckModal from './AuthCheckModal';


import {StyledButton, SolidButton, CancelButton, SubmitButton, StyledTextArea} from './StyledWidgets';


const CommentSection = (props) => {
  const {isAuthenticated} = useContext(AuthContext);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);


  async function getComments() {
    const response = await StoryService.getSegmentComments(props.storyId);

    await setComments(response.data.comments);
    await props.swiperRef.current.update();
  }

  useEffect(() => {
    setIsLoading(true);
    // async function getComments() {
    //   const response = await StoryService.getSegmentComments(props.storyId);

    //   await setComments(response.data.comments);
    //   props.swiperRef.current.update();
    // }
     getComments();
    setIsLoading(false);


  }, []);

  // useEffect(() => {
  //     props.handleHeightChange();
  // }, [comments]);

  async function handleCommentSubmit() {

    if (!isAuthenticated) {
      setModalIsOpen(true);
      return;
    } else {
      setIsLoading(true);

      async function postComments() {
        const response = await StoryService.postSegmentComment(props.storyId, { comment: newComment })
  
        setComments(response.data.comments);
      }
      await postComments();
      await setNewComment("");
      // await getComments();
      await setIsLoading(false);
      props.swiperRef.current.update();
    }
    

  }

  return (
    <>
      <Pad />
      <InputWrapper>
        <StyledTextArea
          type="text"
          value={newComment}
          placeholder="Add a comment"
          onChange={(e) => { setNewComment(e.target.value) }}
        />
        <Pad />
        <SubmitButton onClick={handleCommentSubmit}> <FaPaperPlane /></SubmitButton>
        <CancelButton onClick={props.cancel}>Cancel</CancelButton>
      </InputWrapper>
      <Pad />
      <CommentsWrapper>
        {!isLoading && comments.map((comment, index) => {
          return (
            <FlexWrapper>
              <Comment
                key={comment._id}
                id={comment._id}
                comment={comment.comment}
                index={index}
                createdAt={comment.createdAt}
                userSubmitted={comment.userSubmitted}
              />
              <Pad />
            </FlexWrapper>
          )
        })}
      </CommentsWrapper>
      <AuthCheckModal 
        isOpen={modalIsOpen} 
        onRequestClose={() => setModalIsOpen(false)} 
      />
    </>
  )
}

export default CommentSection;


const Pad = styled.div`
    height: 1em;
    width: 0.5em;
`

const FlexWrapper = styled.div`
display: flex;
${'' /* height: 100%; */}
flex-direction: column;
justify-content: center;
    `

const InputWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    ${'' /* height: auto; */}
    
    `

// const Input = styled.input`
//     ${'' /* rows: 1; */}
//     flex-grow: 1;
//   width: auto;
//   ${'' /* margin: 10px;
//    */}
//   padding: 10px;
//   vertical-align: center;
//   horizontal-align: center;
//   font-size: 0.5em;
//   outline: none;
//   ${'' /* text-align: left; */}
//   border-radius: 7px;
//   box-sizing: border-box;
//   border-color: var(--color-primary);
//   resize: none;
//   color: var(--color-primary);
//   background-color: var(--color-background);
//   ::placeholder {
//     color: var(--color-secondary);
//     opacity: 0.8;
// };
//   &:hover {
//     ::placeholder {
//       opacity: 1;
//     };
//   };
// &:focus {
//   outline: none;
//   ::placeholder {
//     transition: opacity 0.5s 0.5s ease!important;
//     opacity: 0.35;
//     color: var(--color-secondary);
// };
// };
// `


// const GenButton = styled.button`
//   background-color: var(--color-primary); /* Green */
//   border: none;
//   color: var(--color-background);
//   font-weight: bold;
//   width: 100%;
//   text-align: center;
//   text-decoration: none;
//   display: inline-block;
//   font-size: 0.5em;
//   cursor: pointer;
//   border-radius: 8px;
//   &:hover {
//     color: var(--color-secondary);
//   };
// `

// const SubmitButton = styled(GenButton)`
// width: 20%;
// padding: 0.5em;
// height: 3em;
// `

const CommentsWrapper = styled.div`
    display: flex;
  
    flex-direction: column;
    max-height: 15em;
    border-radius: 8px;
    border-color: var(--color-primary);
    border-opacity: 0.5;

    overflow-y: scroll;
    ::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

 ${'' /* mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5)); */}
            ${'' /* -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)); */}
       
    `

