import React, { useState, useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';


// a component to test out the pallette of colours

// define the divs for the colours
const ColourDiv = styled.div`
    background-color: ${props => props.color};
    width: 100px;
    height: 100px;
`;



export default function ColourTester() {
  

  return (
    <>
<ColourDiv color={`var(--color-primary)`}/>
<ColourDiv color={`var(--color-secondary)`}/>
<ColourDiv color={`var(--color-tertiary)`}/>
<ColourDiv color={`var(--color-accent)`}/>
<ColourDiv color={`var(--color-background)`}/>
<ColourDiv color={`var(--color-text)`}/>
</>




  )
}


