import {React, useState, useEffect} from 'react'; 
import {useParams, Outlet} from 'react-router-dom';

import StoryService from '../services/StoryService';
import { Link } from 'react-router-dom';
import StorySample from '../components/StorySample';





function LikedListPage() {

// params must be named as they are used in the route
const {id} = useParams();

const [userLikedStories, setUserLikedStories] = useState([]);

// fetch all the users stories using StoryService using a useEffect
useEffect(() => {
    StoryService.getLikedStories(id)
    .then(res => {
        setUserLikedStories(res.data);
    })
    .catch(err => {
        console.log(err);
    })
}, []);


return (
    <>

        {userLikedStories.map((story, index) => {
          return (  <StorySample story={story} />)
        }
        )}

    </>
);
}

export default LikedListPage;



