import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import DOMPurify from 'dompurify';

const StoryContentWrapper = styled.div`
  font-size: ${(props) => props.fontSize};
  line-height: 1.4;
  padding-bottom: 0.1em;
  width: 100%;
  text-align: left;
  color: var(--color-primary);
  cursor: pointer;
  ${'' /* -webkit-text-size-adjust: 100%; */}

  p {
    margin-bottom: 0.5em;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
`;

const TruncatedContent = styled.div`
  ${'' /* overflow: hidden; */}
  text-overflow: ellipsis;
  ${'' /* display: ${props => props.shouldTruncate ? '-webkit-box' : 'block'};
  -webkit-line-clamp: 5; // Adjust this number to change the number of lines shown
  -webkit-box-orient: vertical; */}
`;

function StoryContent({ segment, shouldTruncate = true, fontSize = '0.8rem' }) {
  // const [fontSize, setFontSize] = useState('0.8rem');
  const maxLength = 250; // Adjust this value as needed

  // useEffect(() => {
  //   const handleResize = () => {
  //     const screenWidth = window.innerWidth;
  //     if (screenWidth < 768) {
  //       setFontSize('0.8rem');
  //     } else if (screenWidth < 1024) {
  //       setFontSize('0.8rem');
  //     } else {
  //       setFontSize('0.8rem');
  //     }
  //   };

  //   handleResize();
  //   window.addEventListener('resize', handleResize);

  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  const truncateContent = (content) => {
    if (!shouldTruncate || content.length <= maxLength) return content;
    return content.slice(0, maxLength) + '...';
  };

  const wrapInParagraphs = (content) => {
    // Check if the content is already wrapped in <p> tags
    if (/<p>[\s\S]*<\/p>/i.test(content.trim())) {
      return content;
    }
    // Split the content by line breaks and wrap each in <p> tags
    return content.split(/\r?\n/)
      .map(line => `<p>${line}</p>`)
      .join('');
  };

  const sanitizeAndProcess = (content) => {
    const wrappedContent = wrapInParagraphs(content);
    const sanitized = DOMPurify.sanitize(wrappedContent, {
      ALLOWED_TAGS: ['p', 'br', 'strong', 'em', 'u', 'a'],
      ALLOWED_ATTR: ['href', 'class']
    });
    return truncateContent(sanitized);
  };

  if (!segment) {
    return null;
  }

  const processedContent = sanitizeAndProcess(segment);

  return (
    <StoryContentWrapper fontSize={fontSize}>
      <TruncatedContent 
        shouldTruncate={shouldTruncate} 
        dangerouslySetInnerHTML={{ __html: processedContent }} 
      />
    </StoryContentWrapper>
  );
}

export default StoryContent;