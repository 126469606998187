import React from "react";
import styled from "styled-components";
import { MyButton } from "./StyledWidgets";
import {  useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();
  const year = new Date().getFullYear();

  function handleClick (){
    navigate("/feedback");
  };

  return (
    <FooterDiv>
      <p>Copyright ⓒ {year}</p>
      <FeedbackButton onClick={handleClick}>Feedback</FeedbackButton>
    </FooterDiv>
  );
}

export default Footer;

const FooterDiv = styled.footer`
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  padding: 0.5rem;
  `

  const FeedbackButton = styled.button`
  background-color: var(--color-primary); /* Green */
  border: none;
  color: var(--color-background);
  font-weight: bold;
  padding: 0.3em;
  // flex-grow: 1;
font-size: 1em;
  // width: auto;
  height: auto;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1em;
  text-color: var(--color-background);
  cursor: pointer;
  border-radius: 8px;
  &:hover {
    background-color: var(--color-secondary);
  };
`