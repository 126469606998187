import React, { createContext, useState, useEffect, useContext } from 'react';
import StoryService from "../services/StoryService";

export const AllStoriesAndForksContext = createContext();

export const useAllStoriesAndForksContext = () => useContext(AllStoriesAndForksContext);

export const AllStoriesAndForksProvider = ({ children }) => {
  const [allStories, setAllStories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    storyLoader();
  }, []);

  const storyLoader = async () => {
    setIsLoading(true);
    try {
      const res = await StoryService.getRecentStoriesAndForks();
      setAllStories(res.data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const rootStories = allStories.filter(story => story.storyRoot === true);

  return (
    <AllStoriesAndForksContext.Provider value={{
      allStories,
      rootStories,
      isLoading,
      storyLoader
    }}>
      {children}
    </AllStoriesAndForksContext.Provider>
  );
};