import React from "react";
import StoryEditor from './StoryEditorV3';

function CreateFork({ segment, storyId, id, indexArray, addStory, forkClick, cancel }) {
  const handleSubmit = ({ content }) => {
    const parentId = id;
    addStory(indexArray, { parentId, newStory: { rootStory: false, content: content, title: "" } });
    forkClick();
  };

  return (
    <StoryEditor
      isForking={true}
      segment={segment}
      storyId={storyId}
      onSubmit={handleSubmit}
      onCancel={cancel}
    />
  );
  
}

export default CreateFork;