import React from 'react';
import styled from 'styled-components';
import HelpIcon from './HelpIcon';

function StoryTitle(props) {
    const helpMessage = [`Swipe a section of the story left or right to see alternate storylines.`, <><br/><br/></>, `Click on a section to see details of the submission. You can like, comment, share and most importantly, fork it!`];

    return (
        <Wrapper>
            <StoryContentWrapper>
                <Title>{props.title}</Title>
                <HelpWrapper><HelpIcon message={helpMessage}/></HelpWrapper>
            </StoryContentWrapper>
        </Wrapper>
    )
}

export default StoryTitle;

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StoryContentWrapper = styled.div`
    position: relative;
    // background-color: var(--color-background);
    font-size: ${(props) => props.fontSize};
`;

const HelpWrapper = styled.div`
    position: absolute;
    bottom: 1em;
    right: 1em;
    // padding: 1em;
`;

const Title = styled.h4`
    white-space: pre-wrap;
    user-select: auto;
    text-align: center;
    padding: 2rem;
    &:hover {
        color: var(--color-secondary);
    };
    visibility: ${(props) => props.visibility};
    font-weight: bold;
`;
