import {React, useContext} from 'react'; 
import UserRegister from '../components/UserRegister';
import {AuthContext}  from '../context/AuthContext';
import {Link} from 'react-router-dom';



function RegisterPage() {

    const {
        isAuthenticated
      } = useContext(AuthContext);

return (
    <div>       
     {isAuthenticated ? 
     <p>Please <Link to='auth/logout'>logout</Link> before registering a new user.</p> 
     : <UserRegister /> }
        
    </div>
);
}

export default RegisterPage;