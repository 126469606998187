import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import sinceSubmittedDate from '../utils/sinceSubmittedDate';


// create the comment component

// things to improve here
// change the font size to be smaller
// include the date the comment was made (smaller still)

const Comment = (props) => {

  const newDate =  sinceSubmittedDate(props.createdAt);

   // convert date to a more readable format
   // const date = new Date(props.createdAt);
   // const formattedDate = date.toDateString();
   console.log(props)
   console.log(props.userSubmitted)
    
      return (
     <CommentWrapper>
     <ColumnWrapper>
      <UserText><StyledLink to={`/user/${props.userSubmitted.username}`}>{props.userSubmitted.username}:</StyledLink></UserText>
{/* <ShortPad /> */}
      <DateLine>{newDate}</DateLine>
</ColumnWrapper>
        <CommentText>{props.comment}</CommentText>
        

         </CommentWrapper>
      )
    }

    

export default Comment;


// const ShortPad = styled.div`
//    padding: 0.2em;
// `
const CommentWrapper = styled.div`
   display: flex;
   overflow-y: hidden;
`
const ColumnWrapper = styled.div`
   display: flex;
   flex-direction: column;

   `

const CommentText = styled.p`
   font-size: 0.5em;
   text-align: left;
   ${'' /* margin-left: 1.5em; */}
   padding-left: 2em;
   width: 70%;
`

const DateLine = styled.p`
   font-size: 0.3em;
   text-align: left;
   margin-left: auto;
   wrap: nowrap;
   padding-top:0.1em;
`
const UserLine = styled.div`
   display: flex;
   align-items: left;
   margin-left: 1.5em;
`

const UserText = styled.p`
   font-size: 0.5em;
`

const StyledLink = styled(Link)`
   color: var(--color-accent);
   &:hover {
      color: var(--color-secondary); // replace with the color you want on hover
   }
`

