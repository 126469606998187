import styled from 'styled-components';


const GridWrapper = styled.div`
  margin:0.2em 1em 0.2em 1em;
  height: auto;
  height: ${(props) => props.height};
  ${'' /* width: auto; */}
  grid-template-columns: 4fr 15fr 2fr;
  grid-template-rows: 1fr auto 1fr;
  display: grid;
  
  grid-template-columns: ${(props) => props.gridTemplateColumns};
  grid-template-rows: ${(props) => props.gridTemplateRows};
  ${'' /* margin: ${(props) => props.margin};
  height: ${(props) => props.height}; */}
`;

export default GridWrapper;