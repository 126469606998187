import React, { useState, useContext } from "react";
import styled from "styled-components";
import { Navigate, useNavigate } from "react-router-dom";
import StoryService from "../services/StoryService";

const FlexColWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 1em;
`;

const FlexRowWrapper = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;

align-items: center;

`;




const Bad = styled.p`

    // text-align: left;
`

const StyledSlider = styled.input.attrs({ type: 'range' })`
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 0.1em;
  background: white;
  outline: none;
  opacity: 0.7;
  transition: opacity .2s;

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;

    background: var(--color-primary);
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #4CAF50;
    cursor: pointer;
  }
`;

const OverallSlider = styled(StyledSlider)`
width: 50vw;
`

const Good = styled.p`
    // text-align: right;
`


const StyledTextArea = styled.textarea`
  width: 70%;
  overflow: auto;
  padding: 0.5em;
  rows: 3;
  vertical-align: center;
  horizontal-align: center;
  font-size: calc(0.5em - 0.1vw);
  outline: none;
  border-radius: 7px;
  box-sizing: border-box;
  border-color: var(--color-primary);
  color: var(--color-primary);
  background-color: var(--color-background);
  ::placeholder {
    color: var(--color-secondary);
    opacity: 0.8;
};
  &:hover {
    ::placeholder {
      opacity: 1;
    };
  };
&:focus {
  outline: none;
  ::placeholder {
    transition: opacity 0.5s 0.5s ease!important;
    opacity: 0.35;
    color: var(--color-secondary);
};
};
`

const SubmitButton = styled.button`
  background-color: var(--color-primary); /* Green */
  border: none;
  color: var(--color-background);
  font-weight: bold;
  padding: 0.3em;
  flex-grow: 1;

  width: 70%;
  height: auto;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1em;
  cursor: pointer;
  border-radius: 8px;
  &:hover {
    background-color: var(--color-secondary);
  };
`

const Question = styled.h1`
font-size: 1em;
`
const Pad = styled.div`
  padding: 1em;
  padding: ${(props) => props.padding};

  `

const FeedbackForm = () => {
  const [rating1, setRating1] = useState(5);
  const [feedback, setFeedback] = useState("");
  const navigate = useNavigate();

  // const {user} = useContext(AuthContext);

  function handleChange(event) {
    
    

    const { name, value } = event.target;
   setFeedback(value)
    };

    function handleSubmit() {
      
      
      StoryService.postFeedback({rating1, feedback})
      .then((data) => {
        alert("Thank you for your feedback!")
        navigate(-1, { replace: true });
      })
      .catch((err) => {
        alert("There was an error posting your feedback. Pleas try again later.")

        console.log(err);
      })
  

    }



  return (
    <FlexColWrapper>
                <Pad/>

            <Question>How was your overall experience</Question>
            <Pad padding="1em"/>
          <FlexRowWrapper>
            <Bad>bad</Bad>
          <OverallSlider type="range" min="1" max="10" value={rating1} onChange={(e) => setRating1(e.target.value)} />
            <Good>good</Good>
          </FlexRowWrapper>
          <Pad padding="1rem"/>




          <StyledTextArea 
            autoFocus 
            placeholder="How can we improve?"
            name="feedback" 
            onChange={handleChange} />
          <Pad padding="0.5rem"/>

          <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>


            
        
        
      </FlexColWrapper>
);
};

export default FeedbackForm;
