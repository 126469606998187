import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import StoryService from '../services/StoryService';
import styled from 'styled-components';
import StoryCard from '../components/cards/StoryCard';

function UserStoriesPage() {
    const {username} = useParams();
    const [userStories, setUserStories] = useState([]);

    useEffect(() => {
        StoryService.getUserStories(username)
        .then(res => {
            setUserStories(res.data);
        })
        .catch(err => {
            // Handle error
        })
    }, [username]);

    return (
        <PageWrapper>
            <Pad />
            <Heading>{username}'s Stories:</Heading>
            <Pad />
            <CardsContainer>
                {userStories.map((story) => (
                    <StoryPad>
                        <StoryCard {...story} />
                    </StoryPad>
                ))}
            </CardsContainer>
            <Pad />
        </PageWrapper>
    );
}

export default UserStoriesPage;

const PageWrapper = styled.div`
    padding: 1em;
`;

const Pad = styled.div`
    padding: 1rem;
`;

const StoryPad = styled.div`
    padding : 0.2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    @media (min-width: 600px) {
        width: 100%;
    }
    @media (min-width: 900px) {
        width: 50%;
    }
    @media (min-width: 1200px) {
        width: 33.33%;
    }
`;

const Heading = styled.h1`
    font-weight: bold;
    margin-bottom: 1rem;
    margin-left: 1rem;
`;

const CardsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    align-items: stretch;
`;
