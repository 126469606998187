import React, {useContext, useState} from "react";
import {Outlet, Navigate, useHistory, useLocation, useNavigate} from 'react-router-dom';

import { AuthContext } from "../context/AuthContext";



import {useParams} from 'react-router-dom';
import { TbArrowAutofitRight } from "react-icons/tb";



// import user, isAuthenticated
// test to see if isAuthenticated
// if so, return outlet, otherwise redirect to login

// use params to make custom redirects

 function ProtectedRoute() {
  // const navigate = useNavigate();

     const { isAuthenticated, user } =  useContext(AuthContext);
    // const {invert, setInvert} = useState(false);


    var redirectPath='/';
    var invert = false;
    // const {id} = useParams();
    // const {token} = useParams();
    

    const location = useLocation()
    console.log('location', location.pathname)
    console.log('isAuthenticated', isAuthenticated)


switch(location.pathname) {



     case '/home':
       redirectPath = `/`;
       invert = false;
       // invert = true;
       // setInvert(true);
       break;

     case '/auth/login':
       redirectPath = `/`;
       invert = true;
       // setInvert(true);
       break;

     case '/auth/logout':
       redirectPath = "/auth/login";
       // setInvert(false);
       break;

     case '/auth/register':
       redirectPath = "/auth/logout";
       invert = true;
       // setInvert(true);
       break;

     case '/auth/registersuccess':
       redirectPath = "/";
       invert = true;
       // setInvert(true);
       break;


     case '/welcome':
       redirectPath = '/home';
       invert = false;
       break;




     default:
       redirectPath = "/";

   }

   console.log("Redirect Path: ", redirectPath);
   console.log("Invert: ", invert);


  // need to switch this around
  // check auth first
  // then check if email is verified

    return (
        <>

        
        {invert ? (isAuthenticated ? <Navigate to={redirectPath}/>: <Outlet  /> )
        
        
        :    (isAuthenticated ? <Outlet  /> : <Navigate to={redirectPath}/>)
       
        }
        </>
    
    );
    }
    
    export default ProtectedRoute;