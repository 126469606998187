import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import OpenAiService from '../services/OpenAiService';
import {MyButton, SolidButton} from '../components/StyledWidgets';


const ChatbotContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #ccc;
    padding: 20px;
    width: 400px;
    margin: 20px auto;
`;



const StyledTextArea = styled.textarea.attrs({
    rows: 3,
  })`
    width: 100%;
    overflow: auto;
    flex-grow: 1;
    padding: 0.5em;
    rows: 10;
    resize: none;
    vertical-align: center;
    horizontal-align: center;
    font-size: 1em;
    outline: none;
    border-radius: 7px;
    box-sizing: border-box;
    border-color: var(--color-primary);
    color: var(--color-primary);
    background-color: var(--color-background);
    ::placeholder {
      color: var(--color-secondary);
      opacity: 0.8;
  };
    &:hover {
      ::placeholder {
        opacity: 1;
      };
    };
  &:focus {
    outline: none;
    ::placeholder {
      transition: opacity 0.5s 0.5s ease!important;
      opacity: 0.35;
      color: var(--color-secondary);
  };
  };
  `
  
  

const MessagesList = styled.div`
    // max-height: 300px;
    overflow-y: auto;
    margin-bottom: 20px;
`;

const Message = styled.p`
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
    font-size: 0.5em;
    color: ${props => props.role === 'user' ? 'var(--color-tertiary)' : 'var(--color-accent)'};
    background-color: 'var(--color-background)';
    // text-align: ${props => props.role === 'user' ? 'right' : 'left'};
    padding-right: ${props => props.role === 'user' ? '20px' : '10px'};
    padding-left: ${props => props.role === 'user' ? '10px' : '20px'};

`;

const InputSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Input = styled.input`
    flex: 1;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-right: 10px;
`;

const SendButton = styled.button`
    padding: 10px 15px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
`;



const FlexWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 1em;
`

const StrengthHeader = styled.h1`
    font-size: 1.5em;
    font-weight: bold;
`

const StrengthsSubHeader = styled.h6`
    // font-size: 1.2em;
    font-weight: bold;
`

const Pad = styled.div`
padding: ${props => props.padding || '1em'};
`

const NewButton = styled(SolidButton)`
    margin: 1em;
    width: auto;
    `


function StrengthsChatbot() {
    const [messages, setMessages] = useState([]);
    const [currentMessage, setCurrentMessage] = useState('');

    const handleInputChange = (e) => {
        setCurrentMessage(e.target.value);
    };

    const handleSubmit = async () => {
        setMessages([...messages, { role: 'user', content: currentMessage }]);
    
        try {
            // const chatGenerator = OpenAiService.chatStrengths(messages, currentMessage);
            // for await (const chatbotMessage of chatGenerator) {
            //     setMessages([...messages, { role: 'user', content: currentMessage }, { role: 'assistant', content: chatbotMessage }]);
            // }
            submitMessage(currentMessage);
    
            setCurrentMessage('');
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    async function submitMessage(message) {
    
        let accumulatedContent = "";
    
        for await (const newcontent of OpenAiService.chatStrengths(messages, message)) {
          accumulatedContent += newcontent;
          setMessages([...messages, { role: 'user', content: message }, { role: 'assistant', content: accumulatedContent }]);
        }
        // setIsRequesting(false);
    
      }

    return (
        <FlexWrapper>
            <StrengthHeader>Strengths Chat</StrengthHeader>
            <StrengthsSubHeader>Chat with our assistant to find your strengths!</StrengthsSubHeader>
            <p><em>italic</em><b>bold</b> not bold</p>
            <Pad/>
            <MessagesList>
                {messages.map((message, index) => (
                    <Message key={index} role={message.role}>
                        {message.role === 'user'}{message.content}
                    </Message>
                ))}
            </MessagesList>
            <InputSection>
                <StyledTextArea
                    type="text"
                    value={currentMessage}
                    onChange={handleInputChange}
                    placeholder="Type your message..."
                />
                <Pad padding='0.2em'/>
                <SolidButton onClick={handleSubmit}>Send</SolidButton>
            </InputSection>
            </FlexWrapper>

    );
}

export default StrengthsChatbot;
