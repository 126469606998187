import React, {createContext, useState, useEffect} from 'react';
import AuthService from '../services/AuthService';
import styled from 'styled-components';

// import StoryService from '../services/StoryService';
// import {Outlet} from 'react-router-dom';

export const AuthContext = createContext();


// below is the default export for this module. 
// it is referenced as AuthProvider in index.js
// as that is what the return statement is returning; a Provider. 
export default function AuthProvider ({children}) {

    const [user, setUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false); 

    async function checkAuth() {
        try {
            const res = await AuthService.isAuthenticated();
           await setUser(res.data.user);
           await setIsAuthenticated(res.data.isAuthenticated);
            setIsLoaded(true);
        } catch (error) {
            console.error("Failed to check authentication status: ", error);
        }
    } 




    useEffect(  () => {
        // remove this when you work out how TO DO lazy loading.
            checkAuth();

    },[]); 

 
    

    
    // replace the loading h1 to be a separate component at some point

    return (
        <div>
        
            {!isLoaded ? <Loading/> :
            <AuthContext.Provider value={{user, setUser, isAuthenticated, setIsAuthenticated, checkAuth}}>
            {children}

            </AuthContext.Provider> 

            }
        </div>
    )

}

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--color-background);
`;

const LoadingText = styled.h1`
  color: var(--color-primary);
`;

const Loading = () => (
  <LoadingContainer>
    <LoadingText>Loading...</LoadingText>
  </LoadingContainer>
);



