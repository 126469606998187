import React,{ useState, useContext} from "react";
import styled from "styled-components";
import {MyButton} from "./StyledWidgets";
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { AuthContext } from "../context/AuthContext";



function LikeButton(props) {
const {isAuthenticated, user} = useContext(AuthContext);


async function handleLiked(event) {

    
    const data = {
        liked: props.likedByUser.includes(user._id),
        userId: user._id,
        storyId: props.storyId
    }
    await props.likeClick(props.indexArray,data)
    // keeps the page from refreshing
    event.stopPropagation();

    };


    return (

        <MyButton disabled={props.disabled} onClick={handleLiked}>
        {props.likedByUser.includes(user._id)
        ? <AiFillHeart /> 
        : <AiOutlineHeart/>
        }
    </MyButton>

    )
}

export default LikeButton;


