import React from 'react';
import { VscComment } from 'react-icons/vsc';
import styled from 'styled-components';
import {MyButton} from "./StyledWidgets";
// import CommentButtonModal from './CommentButtonModal';


const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center; 
    `;

    const CommentIconContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    color: var(--color-primary);

`;

  

const CommentCount = styled.span`
    position: absolute;
    top: 44%;
    left: 50%;
    transform: translate(-50%, -50%); 
    font-size: 0.3em;
    font-weight: bold;

    
`;

function CommentCountIcon(props) {

    return (
        <MyButton disabled={props.disabled} onClick={props.onClick}>
        <CommentIconContainer >
            {/* <CommentButtonModal storyId={props.storyId} > */}
            <VscComment/>
            {props.count !== 0 && <CommentCount>{props.count}</CommentCount>}
            
            {/* </CommentButtonModal> */}
        </CommentIconContainer>
        </MyButton>
    );
}

export default CommentCountIcon;
