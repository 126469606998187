import React, {useEffect, useContext, StrictMode} from "react";
// import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { RouterProvider, createBrowserRouter, createRoutesFromElements, Route, useLocation, redirect } from "react-router-dom";





import ProtectedRoute from "./components/ProtectedRoute";
// import {action as newStoryAction} from "./components/CreateFork";

import LoginPage from "./pages/LoginPage";
import LogoutPage from "./pages/LogoutPage";
import RegisterPage from "./pages/RegisterPage";
import NotFoundPage from "./pages/NotFoundPage";
import UserHomePage from "./pages/UserHomePage";
import AuthoredListPage from "./pages/AuthoredListPage";
import LikedListPage from "./pages/LikedListPage";
import RootLayout from "./pages/RootLayout";
import VerifyEmailPage from "./pages/VerifyEmailPage";
import LandingPage from "./pages/LandingPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import NotificationsPage from "./pages/NotificationsPage";
import {AuthContext} from "./context/AuthContext";
import UserProfilePage from "./pages/UserProfilePage";
import StoryLoader from "./components/StoryLoader";
import ForkLoader from "./components/ForkLoader";
import StoryExplorer from "./pages/StoryExplorer";
import CreateStory from "./components/CreateStoryV2";
import RegisterSuccessPage from "./pages/RegisterSuccessPage";
import FeedbackForm from "./components/FeedbackForm";
import WelcomePage from "./pages/WelcomePage";
import EditProfilePage from "./pages/EditProfilePage";
import ExcerptPage from "./pages/ExcerptPage";
import UserCharactersPage from "./pages/UserCharactersPage";
import CharacterPage from "./pages/CharacterPage";
import CharacterBuilderForm from "./components/CharacterBuilderForm";
import ColourTester from "./components/ColourTester";
import StrengthsChatbot from "./components/StrengthsChatbot";
import UserStoriesPage from "./pages/UserStoriesPage";
import UserForksPage from "./pages/UserForksPage";
import MainLayout from "./pages/MainLayout";




function AuthFunc() {
  const { isAuthenticated, user } = useContext(AuthContext);
  return (
    isAuthenticated ? <UserHomePage /> : <LandingPage />
  )
}


const router = createBrowserRouter(createRoutesFromElements(
  <Route path="/" element={<RootLayout />}>

    <Route index element={<LandingPage />} />
    <Route element={<MainLayout />}>

    <Route path="/auth/:id/verify/:token" element={  <VerifyEmailPage />} />
    <Route path="/auth/forgotpassword" element={  <ForgotPasswordPage />} />
    <Route path="/auth/:id/resetpassword/:token" element={  <ResetPasswordPage />} />


    <Route path="/story/explore" element={ <StoryExplorer /> } /> 
    <Route path="/story/:storytitle" element={ <StoryLoader key={Date.now()} /> }/> 
    <Route path="/story/:storytitle/fork/:forkid" element={ <ForkLoader key={Date.now()} /> }/>
    
    {/* <Route path="/user/editprofile" element={ <EditProfilePage /> } /> */}


      <Route path="/user/:username" element={ <UserProfilePage /> } />
        {/* <Route path="excerpt" element={ <ExcerptPage /> }/> */}
      <Route path="/user/:username/characters" element={ <UserCharactersPage /> }/>
      <Route path="/user/:username/stories" element={<UserStoriesPage /> } />
      <Route path="/user/:username/forks" element={<UserForksPage /> } />

        {/* <Route path="/user/:username/characters/:character" element={ <UserCharacterPage /> }/> */}


    <Route element={<ProtectedRoute/>} >

    <Route path="/home" element={ <UserHomePage /> } />

      <Route path="/auth" >
        <Route path="login" element={<LoginPage />} />
        <Route path="logout" element={ <LogoutPage /> } /> 
        <Route path="register" element={  <RegisterPage />} />
        <Route path="registersuccess" element={  <RegisterSuccessPage />} />


      </Route>

      <Route path="/characterbuilder" element={ <CharacterBuilderForm /> }/>

      {/* <Route path="/writingmenace" element={ <WritingMenacePage /> } /> */}



      <Route path="/campaign/:id" redirect="/" />
      


      <Route path="/new-story" element={ <CreateStory /> } /> 


      <Route path="/notifications" element={ <NotificationsPage /> }/>
      <Route path="/welcome" element={ <WelcomePage /> } />



{/* <Route 
      path="/storytest" 
      element={ <StorySwiper_TEST/> } 

      /> */}
    </Route>
    <Route path="/characters/:characterid" element={ <CharacterPage /> }/>


    <Route path="/colour" element={ <ColourTester /> }/>


    <Route path="/feedback" element={ <FeedbackForm /> } />
    <Route path="/strengths" element={ <StrengthsChatbot /> } />




    <Route path="*" element={<NotFoundPage />} />

  </Route>

  </Route>
))

function App() {

console.log(`process.env.NODE_ENV: ${process.env.NODE_ENV}`)


  return (
  
    <RouterProvider router={router} >

    <router />
    </RouterProvider>

  )
}



export default App;





