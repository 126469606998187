import React from "react";
import {MyButton} from "./StyledWidgets";
import { MdEdit } from 'react-icons/md';



function EditButton(props) {


    return (


<MyButton onClick={props.onClick} disabled={props.disabled}>
                <MdEdit/>
            </MyButton>

    )
}

export default EditButton;


