
import Modal from 'react-modal';
import { IoSave } from "react-icons/io5";
import renderMention from "./RenderMention";
import {StyledButton} from '../components/StyledWidgets';
import MentionsInputStyle from '../styles/MentionsInputStyle';
import { Mention, MentionsInput } from 'react-mentions';
import { useNavigate, redirect } from "react-router-dom";
import CharacterService from "../services/CharacterService";
import StoryService from "../services/StoryService";
import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components';
import OpenAiService from "../services/OpenAiService";
import { StoryContext } from "../context/StoryContext";
import StyledSwitch from "./StyledSwitch";


// import a react send icon
import { FaPaperPlane } from "react-icons/fa";
import {MdOutlineArrowBackIosNew} from "react-icons/md";
// props
// story.
// key={story._id}
// id={story._id}
// username={story.userSubmitted}
// title={story.title}
// likes={story.likes}
// likedbyuser={story.likedByUser}
// content={story.content}
// datesubmitted={story.dateSubmitted}

function CreateFork(props) {

  // console.log(`CreateFork component loaded`)



  Modal.setAppElement('#root');
  

  const [writingBuddy, setWritingBuddy] = useState(true);
  const [isRequesting, setIsRequesting] = useState(false);
  const [characters, setCharacters] = useState([]);
  const [mentions, setMentions] = useState([]); 

  const [prompt, setPrompt] = useState("");
  const textAreaRef = useRef(null);
  const [content, setContent] = useState("");
const customStyles = { 
  overlay: {
    maxHeight: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 99999
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '90%',
    maxHeight: '90%',
    minHeight: '90%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'var(--color-background)',
    zIndex: 99999
  }
};



const [modalIsOpen, setModalIsOpen] = useState(false);
// const [promptModalIsOpen, setPromptModalIsOpen] = useState(false);

function openModal() {
  setModalIsOpen(true);
}

function closeModal() {
  setModalIsOpen(false);
}



  // load all the characters from the database
  useEffect(() => {
    CharacterService.getCharacters().then((res) => {
      // convert all the characters to an id and display name
      const conv_characters = res.data.map((character) => {
        return {
          id: character._id,
          display: character.name
        }
      })

      setCharacters(conv_characters);
      
      // const newTags = createTagFromId(props.characters, res.data);
      // setCharacterTags(newTags);

      });

  
  }, []);

  const charCount = content.length;
  const charLimit = 1250;
  const overLimit = charCount > charLimit;

  // follow the cursor as text is added to textarea
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
    }
  }, [content]);

  // format punctuation after request is finished
  useEffect(() => {
    if (!isRequesting) {
      if (content.length > 0) {
        formatContent(content)
      }
      textAreaRef.current.focus();
    }
  }, [isRequesting])

  
function handleChange(event, newValue, plainTextValue, newMentions) {
    if (isRequesting) {
      return;
    }

    setMentions(newMentions);

    const value = event.target.value;
    setContent(value);

    if (value.length < content.length) {
      return;
    }

    const isTerminal = isLastCharTerminalPunctuation(value);

    if (writingBuddy && isTerminal && !isRequesting) {
      generateNextSentence(value);
    }
  }

  function formatContent(content) {
    // Trim the content first
    content = content.trim();

    // Regular expression for checking terminal punctuation
    const terminalPunctuation = isLastCharTerminalPunctuation(content)

    if (!terminalPunctuation) {
      setContent(content + ". ");
    } else {
      setContent(content + " ");
    }

  }

  // check for terminal punctuation
  function isLastCharTerminalPunctuation(str) {
    // 
    const lastChar = str.slice(-1);
    return /[.!?]$/.test(lastChar);
  }

  // check for space
  function isLastCharSpace(str) {
    const lastChar = str.slice(-1);
    return /\s$/.test(lastChar);
  }


  async function handleSendPrompt() {
    console.log(prompt); 
    setIsRequesting(true);

    let accumulatedContent = content;

   // make a request to OpenAI.sendPrompt(prompt) and stream the response to the content state
    for await (const newcontent of OpenAiService.sendPrompt(props.segment+"\n\n"+content, prompt, props.storyId)) {
      accumulatedContent += newcontent;

      // Update the state with the accumulated content
      setContent(accumulatedContent);
    }


    setIsRequesting(false);
  };



  async function addSpace() {
    // add a space
setContent(content + " ");
  }

  // generate next sentence of the story
  async function generateNextSentence(str) {
    
    setIsRequesting(true);
    // await setContent(str + " ");
    let accumulatedContent = str + " ";

    for await (const newcontent of OpenAiService.generateNextSentence(props.segment+ " " + str + " ",props.storyId)) {
      accumulatedContent += newcontent;
      setContent(accumulatedContent);
    }
    setIsRequesting(false);

  }



  async function submitNewStory() {
    if (overLimit) {
      alert('Your story is too long. Try again.')
      return;
    }
    if (content.length < 1) {
      alert('Your story doesn\'t have any content. Try again.')

      // put in error message here
      return;
    }

/// need to fix this. Maybe convert this to a post request, then navigate to the new route?
    const parentId = props.id;
    props.addStory(props.indexArray, { parentId, newStory:{rootStory:false,content:content, title:""} } );

    props.forkClick();

  };

  function handlePromptChange(event, newValue, plainTextValue, newMentions) {
    const { name, value } = event.target;

    setPrompt(value);
    if (mentions !== newMentions) {
    setMentions(newMentions);
    }
  }
  
// async function generateText() {
//   for await (const chunk of OpenAiService.generateFork(props.segment)) {
// setContent(content + chunk);
//   }
// }





  return (

    <>

      <FlexWrapper>
      {/* <Modal
  isOpen={modalIsOpen}
  onRequestClose={closeModal}
  shouldCloseOnOverlayClick={true}
  shouldCloseOnEsc={true}
  shouldFocusAfterRender={true}
  style={customStyles}
>
  <FlexColumn>
          <PromptInput
            style={MentionsInputStyle}
            input="textarea"
            value={prompt}
            name="prompt"
            onChange={handlePromptChange}
            placeholder="Describe what happens in your story here... include characters with '@'"

          >
            <Mention
              // markup={renderMention}
              // displayTransform={(id, display) => `<a href='/characters/${id}'>${display}</a>`}
              trigger="@"
              data={characters}
            />
          </PromptInput>
          <Pad/>
      <StyledButton onClick={() =>{handleSendPrompt(); closeModal();}}>Create</StyledButton>
      </FlexColumn>
      </Modal> */}

            <RowWrapper>
            <MyButton onClick={props.closeForkModal}>
                <MdOutlineArrowBackIosNew onClick={props.closeInfoSectionModal} />
                </MyButton>
            </RowWrapper>
            <Pad padding="0.5em" />
            <ContentInput
            inputRef={textAreaRef}
            style={MentionsInputStyle}
            input="textarea"
            value={content}
            name="content"
            onChange={handleChange}
            placeholder="What happens next? Include characters with '@'"
            // ref={textAreaRef}


          >
            <Mention
              // markup={renderMention}
              // displayTransform={(id, display) => `<a href='/characters/${id}'>${display}</a>`}
              trigger="@"
              data={characters}
            />
          </ContentInput>

            <p>{(overLimit) ? "over the limit buddy, reel it in a little" : `${charCount} / ${charLimit}`}</p>
            {/* <Pad padding='0.5em' /> */}

 
            
     
            <ButtonWrapper>
            <SwitchWrapper>
            <StyledSwitch size='1em' checked={writingBuddy} onToggle={setWritingBuddy} />
            <Pad padding="0.5em" />

            <p>Writing Buddy {writingBuddy? 'On' : 'Off'}</p>

            </SwitchWrapper>

  {/* <Pad /> */}
          {/* <StyledButton onClick={openModal}>Story Magic</StyledButton> */}
          {/* <Pad /> */}
              {/* <GenButton
                onClick={generateText}
              >
                Generate With AI
              </GenButton> */}
              {/* <Pad padding="0.5em" /> */}


              <SubmitButton
                onClick={submitNewStory}
                // type="submit"
                disabled={overLimit}
              >
                <FaPaperPlane />
              </SubmitButton>
           
            </ButtonWrapper>
            <Pad padding="0.5em" />



      </FlexWrapper>

      {/* <CloseButton onClick={handleCancel}>Cancel</CloseButton> */}

    </>

  )
}

const FlexWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`

const PromptInput = styled(MentionsInput)`
overflow-y: scroll;
`
const ContentInput = styled(MentionsInput)`
// @media (max-width: 768px) {
// min-height: 25em;
// }
// @media (min-width: 768px) {
// font-size: 0.5em;
// }
overflow-y: scroll;
`

const RowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: auto;
`;

const SwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  `

const CloseButton = styled.button`
position: absolute;
left:    0;
bottom:   0;

`


const MyButton = styled.button`
display: flex;
align-items: center;
  background-color: var(--color-background);
  border: none;
  border-radius: 0.5em;
  padding: 0;
  margin: 0;
width: 5%;
font-size: 1em;
transition: ease-in-out 50ms;

&:active {
    transform: scale(1.2);
    }

&:hover {
    color: var(--color-secondary);
    };

`


const ButtonWrapper = styled.div`
display: flex;
flex-flow: row wrap;
align-items: center;
justify-content: space-between;
padding: 0.5em 0;
gap: 0.5em;
`



const GenButton = styled.button`
  background-color: var(--color-primary); /* Green */
  border: none;
  color: var(--color-background);
  font-weight: bold;
  padding: 0.3em;
  flex-grow: 1;

  // width: 100%;
  height: auto;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1em;
  cursor: pointer;
  border-radius: 8px;
  &:hover {
    background-color: var(--color-secondary);
  };
`

const SubmitButton = styled(GenButton)`
width: 20%;
padding: 0.3em 0.5em; 
flex-grow: 0;

`




const Pad = styled.div`
  padding: 1em;
  padding: ${(props) => props.padding};

  `
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  `

export default CreateFork;



// You can integrate the postFork function from your service here
// For example: StoryService.postFork(content).then(response => {...});
