import React,{ useState, useContext} from "react";
import styled from "styled-components";
import {MyButton} from "./StyledWidgets";
import { MdLink } from 'react-icons/md';
import { toSlug } from '../utils/regex';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';





function LinkButton(props) {
    // console.log(`props from LinkButton: ${JSON.stringify(props)}`)

    function handleLinkClick() {
        // create a link to the current story fork
           
             var title = props.parentRootTitle
           // create kebab case for the title
        //    title = title.replace(/\s+/g, '-').toLowerCase();
           const kebabTitle = toSlug(props.parentRootShortId,title);
           const link = `https://www.storyfork.app/story/${kebabTitle}/fork/${props.storyId}`
       
           // copy the link to the clipboard
            navigator.clipboard.writeText(link);


           // show a toast message
           toast.success('Link copied!', {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark'

          });
       
         }
    return (

<>
<ToastContainer />

<MyButton disabled={props.disabled} onClick={handleLinkClick}>
                <MdLink/>
            </MyButton>
</>


    )
}

export default LinkButton;


