import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import StoryCard from "../components/cards/StoryCard";
import 'swiper/css';

const SwiperWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
`;

const StoryPad = styled.div`
  height: 100%;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  align-items: stretch;
`;

const SwiperButton = styled.button`
  background-color: var(--color-background);
  border: none;
  cursor: pointer;
  color: var(--color-primary);
  font-size: 1.5rem;
  opacity: ${props => props.disabled ? 0.1 : 0.8};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  padding: 0.5em;
  &:hover {
    color: var(--color-secondary);
  }
  &:first-child {
    left: 0;
  }
  &:last-child {
    right: 0;
  }
`;

const StyledSelect = styled.select`
  padding: 0.25rem 0.5rem;
  font-size: 0.5rem;
  border: 1px solid var(--color-tertiary);
  border-radius: 4px;
  background-color: var(--color-background);
  color: var(--color-primary);
  cursor: pointer;

  &:hover {
    border-color: var(--color-accent);
  }

  &:focus {
    outline: none;
    border-color: var(--color-secondary);
    box-shadow: 0 0 0 2px rgba(var(--color-secondary), 0.2);
  }
`;

const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const Heading = styled.h2`
  font-size: 1.5rem;
  margin: 0;
`;

const SortDropdown = ({ onSortChange }) => {
  return (
    <StyledSelect onChange={(e) => onSortChange(e.target.value)}>
      <option value="newest">Newest First</option>
      <option value="oldest">Oldest First</option>
    </StyledSelect>
  );
};

function ExplorerSwiper({ stories, title, type }) {
  const [sortedStories, setSortedStories] = useState(stories);
  const [sortOrder, setSortOrder] = useState('newest');
  const [prevButtonDisabled, setPrevButtonDisabled] = useState(true);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
  const swiperRef = useRef(null);

  useEffect(() => {
    sortStories();
  }, [stories, sortOrder]);

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(0);
      handleSwiperNavigation(swiperRef.current);
    }
  }, [sortOrder]);

  const sortStories = () => {
    const sorted = [...stories].sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return sortOrder === 'newest' ? dateB - dateA : dateA - dateB;
    });
    setSortedStories(sorted);
  };

  const handleSortChange = (value) => {
    setSortOrder(value);
  };

  const handleSwiperNavigation = (swiper) => {
    setPrevButtonDisabled(swiper.isBeginning);
    setNextButtonDisabled(swiper.isEnd);
  };

  const getSlidesPerView = (screenWidth) => {
    const maxSlides = Math.min(sortedStories.length, 3.5);
    if (screenWidth >= 1280) return maxSlides;
    if (screenWidth >= 1024) return Math.min(maxSlides, 3);
    if (screenWidth >= 768) return Math.min(maxSlides, 2.5);
    if (screenWidth >= 640) return Math.min(maxSlides, 2);
    if (screenWidth >= 480) return Math.min(maxSlides, 1.5);
    return 1;
  };

  return (
    <>
      <HeaderSection>
        <Heading>{title}</Heading>
        <SortDropdown onSortChange={handleSortChange} />
      </HeaderSection>
      <SwiperWrapper>
        <SwiperButton 
          onClick={() => swiperRef.current?.slidePrev()} 
          disabled={prevButtonDisabled}
        >
          &lt;
        </SwiperButton>
        <Swiper
          modules={[Navigation]}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          onSlideChange={handleSwiperNavigation}
          style={{ width: '100%', paddingLeft: '40px', paddingRight: '40px' }}
          breakpoints={{
            320: { slidesPerView: getSlidesPerView(320), spaceBetween: 10 },
            480: { slidesPerView: getSlidesPerView(480), spaceBetween: 15 },
            640: { slidesPerView: getSlidesPerView(640), spaceBetween: 15 },
            768: { slidesPerView: getSlidesPerView(768), spaceBetween: 20 },
            1024: { slidesPerView: getSlidesPerView(1024), spaceBetween: 20 },
            1280: { slidesPerView: getSlidesPerView(1280), spaceBetween: 20 }
          }}
        >
          {sortedStories.map((story) => (
            <SwiperSlide key={`${type}: ${story._id}`}>
              <StoryPad>
                <CardWrapper>
                  <StoryCard {...story} />
                </CardWrapper>
              </StoryPad>
            </SwiperSlide>
          ))}
        </Swiper>
        <SwiperButton 
          onClick={() => swiperRef.current?.slideNext()} 
          disabled={nextButtonDisabled}
        >
          &gt;
        </SwiperButton>
      </SwiperWrapper>
    </>
  );
}

export default ExplorerSwiper;