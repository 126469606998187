import React,{useContext} from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {AuthContext} from '../context/AuthContext';
import SearchBar from '../components/SearchBar';


const TaglineFirstH1 = styled.h2`
  ${'' /* font-size: 3em; */}
  text-align: left;
`;

const IntroH2 = styled.h4`
  grid-column: 2 / span 2;
  grid-row: 1;
  text-align: left;
`;

const BrightLink = styled(Link)`
  color: var(--color-secondary);
  text-decoration: none;
  font-weight: bold;
  &:hover {
    color: var(--color-accent);
  }
`;

const WelcomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${'' /* align-items: center; */}
  ${'' /* padding: 2em; */}
`;

const OnboardingStep = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
  align-content: center;
  ${'' /* margin-bottom: 2em; */}
`;

const Steps = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  `;

const StepNumber = styled.span`
  ${'' /* font-size: 3em; */}
  font-weight: bold;
  color: var(--color-primary);
  text-align:left;
`;

const StepDescription = styled.p`
  ${'' /* font-size: 1.5em; */}
  text-align: left;
  max-width: 80%;
`;

const Pad = styled.div`
padding: 1.5em;
`

function WelcomePage() {

const {user, isAuthenticated} = useContext(AuthContext);

  return (
    <WelcomeContainer>
          <Pad/>


      <TaglineFirstH1>Welcome, {user.username}!</TaglineFirstH1>

{/* <Pad/> */}
<Pad>

      <IntroH2>Let's get you started:</IntroH2>
      <Pad/>
      <Steps>
      <OnboardingStep>
        <StepNumber>1</StepNumber>
        <Pad/>
        <StepDescription>
          <BrightLink to={`/user/${user.username}`}>Complete your profile: </BrightLink>Edit your profile page and add links to your socials and websites.
        </StepDescription>
      </OnboardingStep>

      <OnboardingStep>
        <StepNumber>2</StepNumber>
        <Pad/>
        <StepDescription>
          Connect with others: Find and follow like-minded individuals to join discussions and share ideas.
        </StepDescription>
      </OnboardingStep>

      <OnboardingStep>
        <StepNumber>3</StepNumber>
        <Pad/>
        <StepDescription>
        <BrightLink to={`/new-story`}>Create your first story: </BrightLink>Write a story and share it with the world.
        </StepDescription>
      </OnboardingStep>

      <OnboardingStep>
        <StepNumber>4</StepNumber>
        <Pad/>
        <StepDescription>
        <BrightLink to={`/new-story`}>Explore: </BrightLink> Check out the stories that others have written and fork the ones you like!
        </StepDescription>
      </OnboardingStep>
      <OnboardingStep>
        <StepNumber>5</StepNumber>
        <Pad/>
        <StepDescription>
        <BrightLink to={`/feedback`}>Feedback: </BrightLink> Help us improve by providing feedback and suggestions. StoryFork is a community-driven platform, so you can steer it's direction!
        </StepDescription>
      </OnboardingStep>
</Steps>

</Pad>
      {/* <CTALink to="/onboarding">Start Onboarding</CTALink> */}
    </WelcomeContainer>
  );
};

export default WelcomePage;
