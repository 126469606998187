import React, { useRef, useEffect } from "react";


// The purpose of this function is to allow click in and click out of components in react
// when a components div is clicked, a thing happens, but we also need the function to click out of it too

function useOuterClick(callback) {
    const callbackRef = useRef(); // initialize mutable ref, which stores callback
    const innerRef = useRef(); // returned to client, who marks "border" element
  
    // update cb on each render, so second useEffect has access to current value 
    useEffect(() => { callbackRef.current = callback; });
    
    useEffect(() => {
      document.addEventListener("click", handleClick);
      return () => document.removeEventListener("click", handleClick);
      function handleClick(e) {
        if (innerRef.current && callbackRef.current && 
          !innerRef.current.contains(e.target)
        ) callbackRef.current(e);
      }
    }, []); // no dependencies -> stable click listener
        
    return innerRef; // convenience for client (doesn't need to init ref itself) 
  }

export default useOuterClick;


// To use this in your component, do something like the following:

// import the useOuterClick function in the component eg:
// import useOuterClick from '../components/useOuterClick';

// Then, within the component function, define the inner Ref
// and use the ref prop in the Wrapping component

// const innerRef = useOuterClick(() => {handleClickOut()});

// const [isInfoShowing, setIsInfoShowing] = useState(false);

// const handleClickIn= () => {
//   setIsInfoShowing(!isInfoShowing);
// };

// const handleClickOut= () => {
//     setIsInfoShowing(false);
// };

{/* <Wrapper ref={innerRef} onClick={handleClickIn}>
<p>content blah blah</p>
</Wrapper> */}