
// create a component to display a message to prompt the user to check their email for a verification link

import React, { useState, useEffect, useContext } from 'react';
import AuthService from '../services/AuthService';
import { Link, useNavigate, Navigate, useParams } from 'react-router-dom';
import {AuthContext}  from '../context/AuthContext';



function VerifyEmailPage() {
  const {isAuthenticated, setIsAuthenticated, user, checkAuth} = useContext(AuthContext);
  const navigate = useNavigate();
  const [isVerified, setIsVerified] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const {id, token} = useParams();


  useEffect(() => {
    async function verifyEmail() {
      const response = await AuthService.verifyEmail(id, token);
      
      setIsVerified(response.data?.isVerified);
      await checkAuth();
      setIsExpired(response.data?.isExpired);

      if (response.data?.isVerified) {
        checkAuth();
      }
    }
    verifyEmail();

   

    setIsLoading(false);

  }, []);

  useEffect(() => {
    setTimeout(async () => {
      // set timeout to redirect to home page after 5 seconds
      if (isVerified) {

      if (isAuthenticated) {

        navigate('/welcome',{replace:true});

      } 



      }
    }, 3000);



  }, [isAuthenticated]);



  // this is the get request to the backend to verify the email
// `http://${process.env.ADDRESS}:${process.env.PORT}/api/users/${this._id}/verify/${verifyEmailToken}`

  return (
    <div>
      <h1>Verify Email Page</h1>
      {isLoading ? (
        <p>Loading...</p>
      ) : isVerified ? (
        <p>Your email has been verified. Logging in now!</p>
      ) : isExpired ? (
        <p>
          The link has expired. Please request a new link to verify your email.
        </p>
      ) : (
        <p>
          There was an error verifying your email. Please request a new link to
          verify your email.
        </p>
      )}
      <p>
        <Link to="/">Return to the home page</Link>
      </p>
    </div>
  );
}

export default VerifyEmailPage;