import axios from 'axios';

axios.defaults.withCredentials = true


if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    
    var api_story_string = `http://${process.env.REACT_APP_ADDRESS}:3000/api/stories`
    var api_user_string = `http://${process.env.REACT_APP_ADDRESS}:3000/api/users`

} else {
    
     api_story_string = `${process.env.REACT_APP_BACKEND_API_ADDR_PROD}/stories`
     api_user_string = `${process.env.REACT_APP_BACKEND_API_ADDR_PROD}/users`
}



// const api_story_string = `http://${process.env.REACT_APP_ADDRESS}:3000/api/stories`
// const api_user_string = `http://${process.env.REACT_APP_ADDRESS}:3000/api/users`






export default {


    //// Story Service 
    //// Since it's impracticle to get all the stories, we need to get just the stories 
    //// that branch from a common ancestor story. 
    //// This will be reusable later on when we need to render further levels of the story

    getStoryFeed: async () => {
        
        return await axios.get(`${api_story_string}/feed`)
        .then(res => { return res })
        .catch(error =>console.log(error) )
    },
    postFeedback: async (feedback) => {
        
        return await axios.post(`${api_story_string}/feedback`,feedback)
        .then(res => { return res })
        .catch(error => console.log(error))
    },


    postStory: async (story) => {
        

        return await axios.post(`${api_story_string}/newstory`,story)
        .then(res => { return res })
        .catch(error => console.log(error))
    },
    postFork: async (storyid, story) => {
        

        return await axios.post(`${api_story_string}/${storyid}`,story)
        .then(res => { return res })
        .catch(error =>console.log(error) )
    },
    getRootStories: async () => {
        
        return await axios.get(`${api_story_string}/rootstories`)
        .then(res => { return res })
        .catch(error =>console.log(error) )
    },

    getRecentStoriesAndForks: async () => {

        return await axios.get(`${api_story_string}/recentstoriesandforks`)
        .then(res => { return res })
        .catch(error =>console.log(error) )
    },



    getRootStoryCount: async (storyid,story) => {
        
        return await axios.get(`${api_story_string}/rootcount`)
        .then(res => { return res })
        .catch(error => console.log(error))
    },
    getChildren: async (storyid, depth) => {
        
        return await axios.get(`${api_story_string}/${storyid}/children.${depth}`)
        .then(res => { ; return res })
        .catch(error => console.log(error))
    },
    getRootStoryChildren: async (storytitle, depth) => {
        
        return await axios.get(`${api_story_string}/title/${storytitle}/children.${depth}`)
        .then(res => { ; return res })
        .catch(error => console.log(error))
    },

    getMoreSiblings: async (storyid, depth,childarray=[]) => {
        
        return await axios.get(`${api_story_string}/${storyid}/children.${depth}/${childarray}`)
        .then(res => { ; return res })
        .catch(error =>console.log(error) )
    },
    getParents: async (storyid) => {
        
        return await axios.get(`${api_story_string}/${storyid}/parents`)
        .then(res => { ; return res })
        .catch(error => console.log(error))
    },
    getForkParents: async (storytitle,forkid) => {
        
        return await axios.get(`${api_story_string}/${storytitle}/fork/${forkid}/parents`)
        .then(res => { ; return res })
        .catch(error => console.log(error))
    },
    getStory: async (storyid) => {
        
        return await axios.get(`${api_story_string}/${storyid}`)
        .then(res => { ; return res })
        .catch(error => console.log(error))
    },
    deleteStory: async (storyid) => {
        console.log('delete story')
        return await axios.delete(`${api_story_string}/${storyid}`)
        .then(res => { ; return res })
        .catch(error => console.log(error))
    },
    handOverStory: async (storyid) => {
        console.log('hand over story to hivemind')
        return await axios.post(`${api_story_string}/${storyid}/handover`)
        .then(res => { ; return res })
        .catch(error => console.log(error))
    },
    ///
    updateArrayField: async (storyid,field) => {
        
        return await axios.put(`${api_story_string}/${storyid}/arrayfield/${field}`)
        .then(res => { ; return res })
        .catch(error => console.log(error))
    },

 
    // /api/stories/:storyid/editstory
    editStory: async (storyid,storyedit) => {
        
        return await axios.patch(`${api_story_string}/${storyid}/editstory`,storyedit)
        .then(res => { ; return res })
        .catch(error => console.log(error))
    },

    getSegmentComments: async (storyid) => {
        
        return await axios.get(`${api_story_string}/${storyid}/comments`)
        .then(res => { ; return res })
        .catch(error => console.log(error))
    },

    postSegmentComment: async (storyid,comment) => {  
        
        return await axios.post(`${api_story_string}/${storyid}/comments`,comment)
        .then(res => { return res })
        .catch(error => console.log(error))
    },  



    ///// User Service

    getUserStories: async (username) => {
        
        return await axios.get(`${api_user_string}/${username}/stories`)
        .then(res => { ; return res })
        .catch(error => console.log(error))
    },
    getUserForks: async (username) => {
        
        return await axios.get(`${api_user_string}/${username}/forks`)
        .then(res => { ; return res })
        .catch(error => console.log(error))
    },

    getUserDetails: async (username) => {
        
        return await axios.get(`${api_user_string}/${username}`)
        .then(res => { ; return res })
        .catch(error => console.log(error))
    },


    getLikedStories: async (userid) => {
        
        return await axios.get(`${api_user_string}/${userid}/liked`)
        .then(res => { ; return res })
        .catch(error => console.log(error))
    },

    getUserNotifications: async (userid) => {
        
        return await axios.get(`${api_user_string}/${userid}/notifications`)
        .then(res => { ; return res })
        .catch(error => console.log(error))
    },

    getNewNotificationCheck: async (userid) => {
        
        return await axios.get(`${api_user_string}/${userid}/newnotifications`)
        .then(res => { return res })
        .catch(error => console.log(error))
    },
    

    getFollowCheck: async (username) => {
        
        return await axios.get(`${api_user_string}/${username}/followcheck`)
        .then(res => { return res })
        .catch(error => console.log(error))
    },


    postFollowUser: async (username) => {
        
        return await axios.post(`${api_user_string}/${username}/follow`)
        .then(res => { return res })
        .catch(error => console.log(error))
    },

    postUnFollowUser: async (username) => {
        
        return await axios.post(`${api_user_string}/${username}/unfollow`)
        .then(res => { return res })
        .catch(error => console.log(error))
    },

    postUserDetails: async (userdetails) => {
        
        return await axios.post(`${api_user_string}/${userdetails._id}/editprofile`,userdetails)
        .then(res => { return res })
        .catch(error => console.log(error))
    },

    getDBStats: async () => {
            
            return await axios.get(`${api_user_string}/dbstats`)
            .then(res => { return res })
            .catch(error => console.log(error))
        },

    getUserCharacters: async (username) => {
            
        return await axios.get(`${api_user_string}/${username}/characters`)
        .then(res => { return res })
        .catch(error => console.log(error))
    },

    







}


