import { React, useReducer, useState, useEffect, useContext } from 'react';
import { useLoaderData, useParams} from 'react-router-dom';
import styled from 'styled-components';
import StoryService from "../services/StoryService"
import CreateStoryFork from '../components/CreateFork';

import { StoryContext } from "../context/StoryContext";

// import StorySwiper from "../components/StorySwiper";

import StorySwiperV2 from "../components/StorySwiperV2";
import StoryTitle from "../components/StoryTitle";
import HelpIcon from '../components/HelpIcon';

import _ from "lodash";


// Import Swiper styles
import 'swiper/css';
import "swiper/css/navigation";





function StoryBuilder() {
    


    const {
        fetchedData, 
        setFetchedData} = useContext(StoryContext);
    const [isLoading, setIsLoading] = useState(false);


      function locateInStory(indexArray) {

        // for some reason, .slice() allows us to modify the fetched data
        var newData = fetchedData.slice();
       
        // locatefunction finds the childarray in fetchedData
        // passed back from storyblock
        function locateFunction(accumulator,curvalue,index) {

            if (index === 0) {
                return accumulator[curvalue]
            } else {
                return accumulator.children[curvalue]
            }   
        }
    
            // .reduce takes an array and iteratively applies a function, 
            // returning a single value/object
            // in this case we're reducing the 'indexArray'
            // and returning the specific object we want to modify
    
        // dataobj is the parent object of the target story
        const dataobj = indexArray.reduce(locateFunction,newData)
    
        return {newData, dataobj};
    }



    async function addChildStory(indexArray,data) {
        setIsLoading(true);
    
        const response = await StoryService.postFork(data.parentId,data.newStory);

    
        if (_.isEmpty(indexArray)) {
            var newData = fetchedData.slice();
            newData.push(response.data.newStory);
        } else {
            var {newData, dataobj} =  locateInStory(indexArray);
            dataobj.children.push(response.data.newStory)
            dataobj.childrenCount = dataobj.childrenCount + 1;

        }
    
    
        await setFetchedData(newData);
        setStoryActiveIndex(indexArray,dataobj.children.length - 1);
        setIsLoading(false);

    }

        // this function is called when the slider changes to set the activeIndex
    // of the current story
    // the aim of the function is the find the last position of the array when it was last called.
    // Ie. if the parent array changes position, then returns to that position, the child array should
    // return to the same position it was in before.
    async function setStoryActiveIndex(indexArray,activeIndex) {
        setIsLoading(true);

        if (_.isEmpty(indexArray)) {
            var newData = fetchedData.slice();
            var dataArray = newData;
    
        } else {
            var {newData, dataobj} = locateInStory(indexArray);
            dataArray = dataobj.children;
    
        }
        // remove activeIndex field from all objects in the array
        // dataArray = dataArray.map(({activeIndex, ...rest}) => rest);
        dataArray.forEach(object => {
            delete object['activeIndex'];
            });


        // add activeIndex field to the array at the activeIndex
        dataArray[activeIndex].activeIndex = true;


        await setFetchedData(newData);
        setIsLoading(false);
    
    }



function changeLiked(indexArray,data) {

    var {newData, dataobj} =  locateInStory(indexArray);


   if (data.liked) {
   
        // filter will not mutate the array, 
        // so you need to assign to new var 'replacement' then insert back into data

        var replacement = dataobj.likedByUser.filter(item => item !== data.userId)
        dataobj.likedByUser = replacement
        dataobj.likes -= 1
     } else {

        dataobj.likedByUser.push(data.userId) 
        dataobj.likes += 1
     }

     // reset the data to be rendered out on the storydashpage
    setFetchedData(newData);

    // send the data for the change to likes to the backend.
    StoryService.updateArrayField(data.storyId,'likedByUser');

} 


function editStory(indexArray,data) {

    
        var {newData, dataobj} = locateInStory(indexArray);
    
    
       if ((data.segment === dataobj.segment) && (data.title === dataobj.title)) { 
             return
         }  
    
        dataobj.segment = data.segment
        dataobj.title = data.title


         // reset the data to be rendered out on the storydashpage
        setFetchedData(newData);
    
        // send the data for the change to the segment to the backend.
        StoryService.editStory(data.storyId,data);
    
    } 

    // need to make this a patch not delete request
    
function deleteStory(indexArray,data) {

    
        var {newData, dataobj} = locateInStory(indexArray);
    
    
       if (data.segment === dataobj.segment) { 
             return
         }  
    
        dataobj.segment = data.segment


         // reset the data to be rendered out on the storydashpage
        setFetchedData(newData);
    
        // send the data for the change to the segment to the backend.
        StoryService.editStory(data.storyId,data);
    
    } 




return (
        <>

        {(fetchedData == null || isLoading) ? <p>loading...</p> :


        <>
        {/* <HelpWrapper>
        <HelpIcon message={helpMessage} />
        </HelpWrapper> */}
        <StoryTitle title={fetchedData[0].title} />
      <StorySwiperV2  
        key={fetchedData.length} 
        storyArray={fetchedData} 
        isClickedFork={false}
        indexArray={[]}  
        changeLiked={changeLiked}
        addStory={addChildStory}
        storyTitle={fetchedData[0].title}
        setStoryActiveIndex={setStoryActiveIndex}
        editStory={editStory}


          /> 
   </>
           }
     <BottomPad/>

    </>
)
}


const FlexWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const HelpWrapper = styled.div`
    display: flex;
    align-items: right;
    padding: 1em;
    width: 100%;
`

const SwiperWrapper = styled.div`
    visibility: ${(props) => props.visibility};
    display: flex;
    justify-content: center;
    font-size: 60px;
    color: white;
    text-align: center;

`

const BottomPad = styled.div`
height: 7rem;
`
export default StoryBuilder;


