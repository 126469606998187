import React, { useState, useContext, useEffect } from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import styled from 'styled-components';
import StoryService from '../../services/StoryService';
import { AuthContext } from '../../context/AuthContext';
import { Modal, Box } from '@mui/material';
import EditProfileForm from '../../components/EditProfileForm.jsx'
import {Username, FollowUser, RowWrapper} from '../../components/FollowUser.jsx'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  border: 2px solid;
  border-color: var(--color-primary);
  border-radius: 8px;
  padding: 1em;
  gap: 1em;
  width: 100%;
`;

// const Username = styled.h1`
//   ${'' /* margin-bottom: 1em; */}
// `;

const FollowButton = styled.button`
  background-color: var(--color-primary);
  color: black;
  border: none;
  font-weight: bold;
  border-radius: 0.2em;
  padding: 0.2rem 0.5em;
  display: flex;
  
  transition: ease-in-out 200ms;

  &:hover {
    transform: scale(1.2);
    background-color: var(--color-secondary);

  }
  &:active {
    transform: scale(1.2);
    background-color: var(--color-secondary);

  }
`;

const Head = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
`

const KeyText = styled.p`
color: var(--color-secondary);
display: inline;

`
const ValueText = styled.p`
display: inline;
`

const TextDiv = styled.div`
display: inline-block;

`

const ColWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: right;
`

const EditProfileButton = styled(FollowButton)`
`
 

function UserCard(props) {
  const navigate = useNavigate();
  const { isAuthenticated, user } = useContext(AuthContext);

  const [openEditProfile, setOpenEditProfile] = useState(false);
  const [following, setFollowing] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  // const [userExists, setUserExists] = useState(false);

  const location = useLocation();

  useEffect(() => {
    // alert(JSON.stringify(location.state?.userDetails))
    if (location.state?.userDetails) {
      setUserDetails(location.state.userDetails);
    }
  }, [location.state?.userDetails]);


  useEffect(() => {
    StoryService.getUserDetails(props.username)
    .then(res => {
        setUserDetails(res.data.user);
    })
    .catch(err => {
        console.log(err);
        navigate('/')
    })

}, [props.username]);

useEffect(() => {
if (!userDetails) {
  navigate('/');}

// alert(JSON.stringify(userDetails))
}, [userDetails]);


  useEffect(() => {
    if (!isAuthenticated) return;

    StoryService.getFollowCheck(props.username)
      .then((res) => {
        setFollowing(res.data.following);
      })
      .catch((err) => {
        ;
      });
  }, [props.username]);

  function handleFollow() {
    StoryService.postFollowUser(props.username)
      .then((res) => {
        ;
        setFollowing(res.data.following);
      })
      .catch((err) => {
        ;
      });
  }

  function handleUnFollow() {
    StoryService.postUnFollowUser(props.username)
      .then((res) => {
        ;
        setFollowing(res.data.following);
      })
      .catch((err) => {
        ;
      });
  }

  const handleOpenEditProfile = () => {
    setOpenEditProfile(true);
  };

  const handleCloseEditProfile = () => {
    setOpenEditProfile(false);
  };

  function handleEditProfile() {
    handleOpenEditProfile();
  }

  return (
    <Wrapper>
          <Modal open={openEditProfile} onClose={handleCloseEditProfile} >
          <Box style={{ maxHeight: '90vh', overflow: 'auto' }}>

        <EditProfileForm user={userDetails} onClose={handleCloseEditProfile} />
        </Box>

      </Modal>
 
{/* <Head> */}
      {/* <FollowUser username={props.username} /> */}
        <RowWrapper>
          <Username username={props.username} />
          {
            user?.username === props.username ?
              <EditProfileButton onClick={() => handleEditProfile()}>Edit Profile</EditProfileButton> :
              <FollowUser username={props.username} />
          }

        </RowWrapper>

      {/* </Head> */}
      {/* <p>Email: {userDetails.email}</p> */}
      {userDetails.pronouns && <TextDiv><KeyText>Pronouns:</KeyText><ValueText> {userDetails.pronouns}</ValueText></TextDiv>}
      {userDetails.bio && <TextDiv><KeyText>Bio:</KeyText><ValueText> {userDetails.bio}</ValueText></TextDiv>}
      {userDetails.writingGoals && <TextDiv><KeyText>Writing Goals:</KeyText><ValueText> {userDetails.writingGoals}</ValueText></TextDiv>}
      {userDetails.worksLinks && <KeyText>My Published Work: <ColWrapper>{userDetails.worksLinks.map((link) => (
      <a href={`//${link}`}>{link}</a>))}</ColWrapper></KeyText>}
      {userDetails.socialsLinks && <KeyText>Socials: <ColWrapper>{userDetails.socialsLinks.map((link) => (
      <a href={`//${link}`}>{link}</a>))}</ColWrapper></KeyText>}
      {userDetails.otherLinks && <KeyText>Other Links: <ColWrapper>{userDetails.otherLinks.map((link) => (
      <a href={`//${link}`}>{link}</a>))}</ColWrapper></KeyText>}




    </Wrapper>
  );
}

export default UserCard;
