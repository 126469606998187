import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import AuthProvider from "./context/AuthContext"
import StoryProvider from "./context/StoryContext"
import {AllStoriesAndForksProvider} from "./context/AllStoriesAndForksContext"
import ErrorBoundary from './components/ErrorBoundary';


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<StrictMode>
    <AuthProvider>
        <AllStoriesAndForksProvider>
            <StoryProvider>
                <ErrorBoundary>
                    <App />
                </ErrorBoundary>
            </StoryProvider>
        </AllStoriesAndForksProvider>
    </AuthProvider>
</StrictMode>
);

