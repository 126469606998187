// This is not used any more, in favor of the StoryCard, which handles the case of a forked story as well.

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column; // stack children vertically
  border: 1px solid var(--color-primary);
  border-radius: 0.2em;
  padding: 2em;
  height: 20em;

  &:hover {
    border-color: var(--color-secondary);
    border-width: 2px;
  }
`;

const Content = styled.p`
  flex-grow: 1; // allow content to take up available space
  overflow: hidden; // hide overflow
  position: relative;

  text-align: justify;

`;

const ContentWrapper = styled.div`
  flex-grow: 1; // allow this container to grow and take up available space
`;

const Title = styled.h1`
  display: flex;
  flex-direction: column;
  font-weight: bold;
  // max-height: 1em;
`;

const StyledH4 = styled.h4`
  padding-left: 1em;
`;

const SecondaryText = styled.p`
  padding-left: 1em;
  margin-top: auto;
`;

const Number = styled.span`
  color: var(--color-secondary);
  cursor: pointer;
`;

const Pad = styled.div`
  padding: 0.5em;
`;

function formatDate(date) {
  return new Date(date).toLocaleDateString("en-GB", {
    year: "numeric",
    month: "short",
    day: "numeric"
  });
}

function ForkCard({ content, dateSubmitted, likes, forks, shortId, id, parentRoot, userSubmitted }) {
  const forkid = shortId || id;
  const formattedDate = formatDate(dateSubmitted);

  return (
    <CardWrapper>
      {/* <ContentWrapper> */}
      <StyledH4>A Fork of </StyledH4>
      <Title>
        <Link to={`/story/${parentRoot.title.replace(/\s/g, '-')}`}>{parentRoot.title}</Link>
      </Title>
      <StyledH4> by <Link to={`/user/${userSubmitted.username}`}>{userSubmitted.username}</Link></StyledH4>
      
      <Pad />
      <Link to={`/story/${parentRoot.title.replace(/\s/g, '-')}/fork/${forkid}`}>
        <Content>{content.substring(0, 200) + "..."}</Content>
      </Link>
      <Pad />
      {/* </ContentWrapper> */}
      <SecondaryText>
        Submitted on {formattedDate} • <Number>{likes}</Number> {likes === 1 ? "like" : "likes"} • <Number>{forks}</Number> {forks === 1 ? "fork" : "forks"}
      </SecondaryText>
      <Pad />
    </CardWrapper>
  );
}

export default ForkCard;
