import React,{ useState, useContext} from "react";
import styled from "styled-components";
import {Outlet, useNavigate} from "react-router-dom";
import Modal from 'react-modal';
import CommentSection from "./CommentSection";
import { AuthContext } from "../context/AuthContext";
// import {MdComment} from "react-icons/md";
import { VscComment } from 'react-icons/vsc';



Modal.setAppElement('#root');





function ForkButtonModal(props) {
  const navigate = useNavigate();
    const {isAuthenticated, user} = useContext(AuthContext)

    const [isComment, setIsComment] = useState(false);

    const customStyles = { 
        overlay: {
        // overflowY: 'scroll',
        maxHeight: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 99999 // set the z-index of the overlay to a high value
      },
        content : {
          display               : 'flex',
          flexDirection         : 'column',
          justifyContent        : 'center',
          top                   : '50%',
          left                  : '50%',
          right                 : 'auto',
          bottom                : 'auto',
          marginRight           : '-50%',
          width                 : '90%',
          height                : '90%',
          transform             : 'translate(-50%, -50%)',
          backgroundColor: 'var(--color-background)',
        zIndex: 99999   
      }
      };


    function commentClick() {
        (isAuthenticated ?
            setIsComment(true)
        : navigate("/auth/login"))
    }

    function closeModal() {
        setIsComment(false);
    }


    return (
<>


        <Modal
        isOpen={isComment}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}              
        shouldCloseOnEsc={true}
        id={props.storyId}
        shouldFocusAfterRender={true}
        style={customStyles} // You can define your own styles here
      >
        <CommentSection {...props}/>
        <CloseButton onClick={closeModal}>close</CloseButton>

        
      </Modal>
            <MyButton onClick={commentClick}>
            <CommentIconContainer >
        <CommentIcon />
            {props.count !== 0 && <CommentCount>{props.count}</CommentCount>}
            </CommentIconContainer>

            </MyButton>
    </>

    )
}

export default ForkButtonModal;

const CommentIcon = styled(VscComment)`
font-size: 2em;
`;


const MyButton = styled.button`

  background-color: var(--color-background);
  border: none;
  padding: 0;
  margin: 0;

  cursor: pointer;
  &:hover {
    color: var(--color-accent);
  }
`

const CloseButton = styled.button`
align-self: flex-start;
justify-content: flex-end;

`

const CommentIconContainer = styled.div`
    position: relative;
    display: inline-block;
`;

const CommentCount = styled.span`
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%); 
    font-size: 0.5em;
    font-weight: bold;
`;
