import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { CustomLink } from '../StyledWidgets';
import { toSlug } from '../../utils/regex';

const CardWrapper = styled.div`
  border: 1px solid #ddd;
  border-radius: 0.2em;
  padding: 2em;
  border-color: var(--color-primary);
  &:hover {
    border-color: var(--color-secondary);
    border-thickness: 2px;
  }
`;

const Title = styled.h1`
  font-weight: bold;
`;

const BrightSpan = styled.span`
    color: var(--color-accent);
`;

const Subtitle = styled.h3`
  margin-bottom: 1em;
`;

const Text = styled.p`
  margin-bottom: 0.2em;
`;

const LinkHover = styled(Link)`
  &:hover {
    color: var(--color-tertiary);
  }
`

function CharacterCard(props) {
  return (
    <CardWrapper>
      <Title>{props.name}</Title>
      {/* <Text>{props.occupation}</Text> */}
      <Text><BrightSpan>Age: </BrightSpan>{props.age}</Text>
      <Text><BrightSpan>Gender: </BrightSpan>{props.gender}</Text>
      <Text><BrightSpan>Backstory: </BrightSpan>{props.backstory}</Text>
      <Text><BrightSpan>Personality: </BrightSpan>{props.personality.join(', ')}</Text>
      <Text><BrightSpan>Skills: </BrightSpan>{props.skills.join(', ')}</Text>
      <Text><BrightSpan>Physical Description: </BrightSpan>{props.physicalDescription}</Text>
      <Text><BrightSpan>Goals: </BrightSpan>{props.goals}</Text>
      <Text><BrightSpan>Dialogue Examples: </BrightSpan>{props.dialogueExamples.join(', ')}</Text>
      <Text><BrightSpan>Author: </BrightSpan><LinkHover to={`/user/${props.userSubmitted.username}`}>{props.userSubmitted.username}</LinkHover></Text>


      {props?.featuredIn.map((story) => {
        // kebab-case the story title
        // const title = story.parentRootTitle.toLowerCase().replace(/ /g, '-');
        const kebabTitle = toSlug(story.parentRootShortId, story.parentRootTitle);
        const forkId = story.shortId || story._id;
        
        var link = ''
        if (forkId) {
          link = `/story/${kebabTitle}/fork/${forkId}`
        } else {
          link = `/story/${kebabTitle}`
        }

        return (
          <Text><BrightSpan>Featured in: </BrightSpan><LinkHover to={link}>{story.parentRootTitle}: {story.segment.substring(0, 40)}...</LinkHover></Text>
        )
      }
      )}

    </CardWrapper>
  );
}

export default CharacterCard;
