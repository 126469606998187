import React from "react";
import { useNavigate } from "react-router-dom";
import StoryService from "../services/StoryService";
import { toSlug } from '../utils/regex';
import StoryEditor from './StoryEditorV3';

function CreateStory() {
  const navigate = useNavigate();

  const handleSubmit = async ({ title, content }) => {
    try {
      const res = await StoryService.postStory({ storyroot: true, title, content });
      const newID = res.data.newStory.shortId || res.data.newStory._id;
      const kebabTitle = toSlug(newID, title);
      navigate(`/story/${kebabTitle}`, { replace: true });
    } catch (err) {
      console.error(err);
      alert('An error occurred while submitting your story.');
    }
  };

  return <StoryEditor onSubmit={handleSubmit} isForking={false} isEditing={false} storyRoot={true} />;
}

export default CreateStory;