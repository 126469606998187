import {createGlobalStyle} from "styled-components";
import ElegantTypewriterBold from '../css/fonts/elegant_typewriter/ELEGANT TYPEWRITER Bold.ttf';


      // // // Reds and orange
      // --color-primary: #ff5f5f; /* used for text */
      // --color-secondary: #ff9f5f;
      // --color-tertiary: #ffd35f;
      // --color-accent: #a2a0a0;
      // --color-background: #2c2f2e; 


const GlobalStyles = createGlobalStyle`

// @font-face {
//   font-family: 'elegant typewriter';
//   src: url(${ElegantTypewriterBold}) format('truetype');
//   font-weight: bold;
//   font-style: normal;
// }


    :root {

  

 
      --toastify-font-family: 'Noto Sans Mono', monospace; 
      



--color-primary: #43b059; /* used for text */
--color-secondary: #3dd45c ;
--color-tertiary: #65e67f;
--color-accent: #d4d2d2 ;
--color-background: #171a19; /* used for background colors*/



      
      --font-heading: 'Noto Sans Mono', monospace; 
      --font-body: 'Noto Sans Mono', monospace;

        --breakpoint-mobile: 480px;
        --breakpoint-tablet: 768px;
        --breakpoint-desktop: 1024px;

    }

    // Set the page to fill the screen
    html, body {
        max-width: 100vw;

        background-color: var(--color-background);
    }
    html {


      font-size: calc(15px + (32 - 27) * (100vw - 360px) / (1024 - 360));


// create variables for each tag below
// then use the variables in the media queries below

  --font-size-h1: 2.0em;
  --font-size-h2: 1.75em;
  --font-size-h3: 1.5em;
  --font-size-h4: 1.25em;
  --font-size-h5: 1em;
  --font-size-h6: 0.75em;
  --font-size-default: 0.75em;

  ${'' /* --font-size-story-content: 16px; */}

  h1 {
  font-size: var(--font-size-h1);
}
h2 {
  font-size: var(--font-size-h2);
}
h3 {
  font-size: var(--font-size-h3);
}
h4 {
  font-size: var(--font-size-h4);
}
h5 {
  font-size: var(--font-size-h5);
}
h6 {
  font-size: var(--font-size-h6);
}
p {
  font-size: var(--font-size-default);
}
label {
  font-size: var(--font-size-default);
}
button {
  font-size: var(--font-size-default);
}
li {
  font-size: var(--font-size-default);
}
span {
  font-size: var(--font-size-default);
}





}








  body {
    overflow: auto;
  }


    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        box-sizing: border-box;
        ${'' /* font-size: 100%; */}
        vertical-align: baseline;
    }

    // Set the color/fonts for our headings,
    // labels and buttons.
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    
    label,
    button,
  
    li {
    color: var(--color-primary);
    font-family: var(--font-heading);
    }

    // Set our font for the body elements
    p,
    a,
    li,
    blockquote,
    span,
    input {
    font-family: var(--font-body);
    color: var(--color-primary);
    }

    .swiper-button-prev {
    color: var(--color-primary); 
    background-color: var(--color-background);

}

    .swiper-button-next {
        color: var(--color-primary); 
        background-color: var(--color-background);
    }

 

    ${'' /* .ql-toolbar .ql-stroke {
    fill: none;
    stroke: var(--color-primary);
  } */}

  ${'' /* .ql-toolbar .ql-fill {
    fill: var(--color-primary);
    stroke: none;
  } */}

  ${'' /* .ql-toolbar .ql-picker {
    color: var(--color-primary);
  } */}

${'' /* set a custom font size for story content */}



link, a{
    text-decoration: none;
    color: var(--color-primary);
}

.modal-open {
  overflow: hidden;
}

textarea {
  font-family: var(--font-body);
  width: 100%;
  overflow: auto;
  flex-grow: 1;
  padding: 0.5em;
  rows: 10;
  resize: none;
  vertical-align: center;
  horizontal-align: center;
  font-size: 1em;
  outline: none;
  border-radius: 7px;
  box-sizing: border-box;
  border-color: var(--color-primary);
  color: var(--color-primary);
  background-color: var(--color-background);
  ::placeholder {
    color: var(--color-secondary);
    opacity: 0.8;
};
  &:hover {
    ::placeholder {
      opacity: 1;
    };
  };
&:focus {
  outline: none;
  ::placeholder {
    transition: opacity 0.5s 0.5s ease!important;
    opacity: 0.35;
    color: var(--color-secondary);
};
};
}

 

    
    `;


export default GlobalStyles;

// @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Mono:wght@300&display=swap');
// // @import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@500&display=swap');
