import React from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';

function AuthCheckModal({ isOpen, onRequestClose }) {
  const customStyles = { 
    overlay: {
      maxHeight: '100vh',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 99999
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      width: '70%',
      height: '70%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'var(--color-background)',
      zIndex: 99999   
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <FlexWrapper>
        <h2>Looks like you're having fun!</h2>
        <p>Why not join the party? It's free!</p>
        <CTALink to="/auth/register">Get Writing!</CTALink>
      </FlexWrapper>
    </Modal>
  );
}

const CTALink = styled(Link)`
  width: auto;
  display: inline-block;
  background-color: var(--color-secondary); 
  color: var(--color-background);
  font-weight: bold;
  border-radius: 1rem;
  padding: 1rem 1.3rem;
  text-decoration: none;
  cursor: pointer;
  transition: ease-in-out 200ms;
  &:hover {
    transform: scale(1.2);
  }
  &:active {
    transform: scale(1.2);
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export default AuthCheckModal;
