import React, { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate, Link, useParams, Await } from 'react-router-dom';
import styled from 'styled-components';
import AuthorOptions from './AuthorOptions';
import { FollowUser } from './FollowUser';
import StoryContent from './StoryContent';
import EditStory from './EditStory';
import StoryEditor from './StoryEditorV3';
import EditButton from './EditButton';
import DeleteButton from './DeleteButton';
import useOuterClick from './useOuterClick';
import CreateFork from './CreateForkV3';
import LinkButtonV2 from './LinkButtonV2';
import CommentCountIcon from './CommentCountIcon';
import LikeButton from './LikeButton';
import InfoBlock from './InfoBlock';
import CommentSection from './CommentSection';
import { MyButton } from './StyledWidgets';
import { AuthContext } from "../context/AuthContext";
import AuthCheckModal from './AuthCheckModal';



// // Fork Icon
// import AltRouteIcon from '@mui/icons-material/AltRoute';
// import Edit from '@mui/icons-material/Edit';


function StoryBlock(props) {

  console.log('renderingcomponent', props.id)
  // console.log(props)


  const { isAuthenticated, user } = useContext(AuthContext);


  const { forkid } = useParams()


  const storyBlockRef = useOuterClick(() => { handleClickOut() });

  const [isClicked, setIsClicked] = useState(false);
  const [isClickedFork, setIsClickedFork] = useState(false);
  const [isClickedComments, setIsClickedComments] = useState(false);
  const [isClickedEdit, setIsClickedEdit] = useState(false);
  const [firstRenderComplete, setFirstRenderComplete] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const disableButtons = isClickedFork || isClickedComments || isClickedEdit;

  useEffect(() => {
  if (props.swiperRef && props.swiperRef.current && isClicked) {
    props.swiperRef.current.allowTouchMove = false;
    props.swiperRef.current.allowTouchMove = false;
  }
}, [isClicked, props.swiperRef]);

  

  // need to create a function that sets all the states to false, and then sets the one that is clicked to true
  // this will allow for the closing of the other components when one is clicked
  // this will also allow for the closing of the component when the user clicks outside of the component

  async function resetStates() {
    await setIsClicked(false);
    await setIsClickedFork(false);
    await setIsClickedComments(false);
    await setIsClickedEdit(false);
    await props.setIsClickedFork(false);
  }

  const handleClickIn = async () => {

    if (isClicked) {
      // await resetStates();
    } else {
      await setIsClicked(!isClicked);
      await props.forkClick();
      await props.swiperRef.current.update();
    }



  };

  const handleClickOut = async () => {
    if (isClicked) {
      await resetStates();
    } else {
      return;
    }


  };

  async function handleForkClick() {
    if (!isAuthenticated) {
      setModalIsOpen(true);
      return;
    }
    await setIsClickedFork(true);
    await props.swiperRef.current.update();


  }



  useEffect(() => {
    // If there's a hash in the URL, try to scroll to the element

    scrollToFork();
  }, [forkid]);

async function scrollToFork() {
  if ((forkid === props.id) && !firstRenderComplete) {
    const element = document.getElementById(forkid);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    await setFirstRenderComplete(true);

  }
}



 

  async function handleCommentsButtonClick() {
    await setIsClickedComments(true);
    await props.swiperRef.current.update();

  }

  async function handleEditClick() {
    await setIsClickedEdit(true);
    await props.swiperRef.current.update();
  }




  return (
    <>

      <ComponentWrapper ref={storyBlockRef} addPad={isClicked} >
        <StoryBlockRowInfo>
        <FlexRow>
        <SmallLink makeBright={isClicked}  to={`/user/${props.userSubmitted.username}`}>{props.userSubmitted.username}</SmallLink>
        {/* {isClicked && <SmallLink to={`/user/${props.userSubmitted.username}`}>{props.userSubmitted.username}</SmallLink>} */}
        {
            isClicked
            && (user?.username !== props.userSubmitted.username) &&

            <FollowUser username={props.userSubmitted.username} {...props} fontSize="1.5em" />
          }
        </FlexRow>
          {
            isClicked
            && (user?.username === props.userSubmitted.username) &&
              <RowEnd>
                <EditButton disabled={disableButtons} onClick={handleEditClick} />
                <DeleteButton disabled={disableButtons} {...props} />
              </RowEnd>
          }

        


          

        </StoryBlockRowInfo>


        <StyledDiv  addPad={isClicked} id={props.id} onClick={handleClickIn} >
          {
            isClickedEdit ?
      
              
  
    

<StoryEditor
  isEditing={true}
  initialContent={props.segment}
  initialTitle={props.title}
  storyId={props._id}
  editStory={props.editStory}
  indexArray={props.indexArray}
  userSubmitted={props.userSubmitted}
  storyRoot={props.storyRoot}
  onCancel={resetStates}
  toggleEdit={setIsClickedEdit}
/>

              :
              <StoryContent shouldTruncate={false}  storyRoot={props.storyRoot} title={props.title} segment={props.segment} />



          }
        </StyledDiv>


        {
          isClicked
          &&
          <StoryBlockBottomRow>
            {/* <InfoBlock {...props} /> */}
            <FlexSpaceBetween id="space-between">
              <ForkButton disabled={disableButtons}  onClick={handleForkClick}>
               Fork It!
              </ForkButton>
              <ButtonWrapper >
                <LinkButtonV2 disabled={disableButtons} {...props} />
                <CommentCountIcon disabled={disableButtons} onClick={handleCommentsButtonClick} count={props.commentsCount} />
                <LikeButton disabled={disableButtons || !isAuthenticated} {...props} />
              </ButtonWrapper>
            </FlexSpaceBetween>
          </StoryBlockBottomRow>

        }

        {/* { (isClickedFork || isClickedComments || isClickedEdit) &&
          <FlexSpaceBetween>
          <CancelButton onClick={() => resetStates()}>Cancel</CancelButton>
          </FlexSpaceBetween>
        } */}

        {
          isClickedFork &&
          <>
            <CreateFork cancel={resetStates} {...props} />
          </>


        }

        {
          isClickedComments &&
         
          <CommentSection cancel={resetStates}  {...props} />
          
        }

      </ComponentWrapper>

      <AuthCheckModal 
        isOpen={modalIsOpen} 
        onRequestClose={() => setModalIsOpen(false)} 
      />
    </>

  )
}



const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--color-background);
  width: 100%;  
  min-height: 100%;
  padding: ${props => props.addPad ? '1em 0' : '0'};
`

const FlexRow = styled.div`
  display: flex;
  gap: 0.5em;
  ${'' /* // if isClickedEdit is true, set opacity to 0.1 */}
  opacity: ${props => props.dimButtons ? '0.1' : '1'};
 
  `

const ButtonWrapper = styled(FlexRow)`
 ${'' /* // mobile view: set font size to 1.5em */}
  @media (max-width: 600px) {
    font-size: 1.5em;
  }
`


const RowEnd = styled(ButtonWrapper)`
  justify-content: flex-end;
`

const StyledDiv = styled.div`
 width: 100%;
 padding: ${props => props.addPad ? '0.5em 0 1em 0' : '0'};

  `

const SmallLink = styled(Link)`
  font-size: 0.4em;
  color: var(--color-accent);
  text-align: left;
  opacity: ${props => props.makeBright ? '1' : '0.5'};
  `

const StoryBlockRowInfo = styled.div`
  max-height: 100%; 
  display: flex;
  gap: 0.5em;
  align-items: center;
  background-color: var(--color-background);
  justify-content: space-between;
  `

//   const DisabledButtonWrapper = styled.div`
//   & > button {
//     opacity: ${props => (props.disabled ? 0.1 : 1)};
//     pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
//   }
// `;

const ForkButton = styled.button`
  display: flex;
  align-items: center;
  background-color: var(--color-secondary);
  color: var(--color-background);
  
  font-size: 0.5em;
  border: none;
  
  margin: 0;
  font-weight: bold;
  border-radius: 0.2em;
  padding: 0.2em 1em;
  

  opacity: ${(props) => (props.disabled ? '0' : '1')};
cursor: ${(props) => (props.disabled ? 'normal' : 'pointer')};
transition: ease-in-out 50ms;



  &:hover {
    background-color: ${(props) => (props.disabled ? 'inherit' : 'var(--color-accent)')};
  }
  
  `

  const CancelButton = styled(ForkButton)`

  `

const StoryBlockBottomRow = styled.div`
  display: flex;
  flex-direction: column;
  ${'' /* justify-content: space-between; */}
  padding-top: 0.5em;
  `

const FlexSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  `


export default StoryBlock;


{/* <EditStory  
cancel={resetStates} 
toggleEdit={setIsClickedEdit} 
{...props} 
/>  */}