import React,{useEffect} from 'react'; 

import {useNavigate} from 'react-router-dom';
import AuthService from '../services/AuthService';


import UserLogout from '../components/UserLogout';

function LogoutPage() {

const navigate = useNavigate();


useEffect(() => {
   AuthService.logout() 
        navigate(0)
    }, [])

return (
    <>
        <UserLogout />
    </>
);
}

export default LogoutPage;

