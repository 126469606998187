import React, {createContext, useState, useEffect} from 'react';
// import {Outlet} from 'react-router-dom';

export const StoryContext = createContext();



export default ({children}) => {

    // const [startNewStory, setStartNewStory] = useState(false);
    const [fetchedData, setFetchedData] = useState(null);
    const [parentStory,setParentStory] = useState(null);

    return (
        <div>

            <StoryContext.Provider value={{
            //     startNewStory, 
            // setStartNewStory, 
            fetchedData, 
            setFetchedData,
            parentStory,
            setParentStory
            }}>
            {children}
            </StoryContext.Provider> 
        </div>
    )

}

