import React from "react";

import styled from "styled-components";
import { Link } from "react-router-dom";



function InfoBlock(props) {

    const sinceSubmitted = (dateSubmitted) => {
        const now = new Date();
        const then = new Date(dateSubmitted);

        // get formatted date
        const date = then.toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
        });

        

        const diff = now - then;
        const years = Math.floor(diff / (1000 * 60 * 60 * 24 * 30 * 12));
        const months = Math.floor(diff / (1000 * 60 * 60 * 24 * 30));
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor(diff / (1000 * 60 * 60));
        const minutes = Math.floor(diff / (1000 * 60));
        const seconds = Math.floor(diff / (1000));
   if (months > 0) {
            return `${date}`;
        }
        else if (days > 0) {
            return `${days} days ago`;
        } else if (hours > 0) {
            return `${hours} hours ago`;
        } else if (minutes > 0) {
            return `${minutes} minutes ago`;
        } else {
            return `${seconds} seconds ago`;
        }
    };


    return (
        <>
        <Pad/>
        <InfoWrapper>

            <InfoText> {sinceSubmitted(props.dateSubmitted)}</InfoText>
            <InfoText> likes: {props.likes} </InfoText>
        </InfoWrapper>
        </>
    );
    
}

export default InfoBlock;


const InfoWrapper = styled.div`
height: auto;
display: flex;

  text-align: left;
  flex-direction: row;

  justify-content: space-between;
`

const Pad = styled.div`
    height: 1rem;
`


const InfoText = styled.p`
  ${'' /* font-size: 1rem; */}
  text-align: left;
  margin: 0;
  padding: 0;
`