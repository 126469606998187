
// create a component to display a message to prompt the user to check their email for a verification link

import React from 'react';
import styled from 'styled-components';


function RegisterSuccessPage() {

  return (
    <Wrapper>
    <Pad />
      <RegistrationHeading>Registration Successful!</RegistrationHeading>
      <Pad />

      <Message>You are now registered!</Message>
      <Pad />

      <Message>Please check your email for a verification link.</Message>

    </Wrapper>
  );
}

export default RegisterSuccessPage;

const Wrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding-top: 2em;
padding-bottom: 4em;
padding-left: 2em;
padding-right: 2em;
`


const RegistrationHeading = styled.h1`
font-size: 2.5em;
text-align: center;
margin: 0;
padding: 0;
color: var(--color-secondary);
`
const Pad = styled.div`
padding: 1rem;
`

const Message = styled.p`
font-size: 1em;
text-align: center;
margin: 0;
padding: 0;
color: var(--color-secondary);
`

