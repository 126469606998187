import styled from 'styled-components';
import {Link} from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
import { TbGenderGenderqueer } from 'react-icons/tb';

const MyButton = styled.button`
display: flex;
align-items: center;
  background-color: var(--color-background);
  border: none;
  border-radius: 0.5em;
  padding: 0;
  margin: 0;
font-size: ${(props) => (props.fontSize ?? '1em')};
opacity: ${(props) => (props.disabled ? '0.1' : '1')};
cursor: ${(props) => (props.disabled ? 'normal' : 'pointer')};
transition: ease-in-out 50ms;

&:active {
    transform: ${(props) => (props.disabled ? 'none' : 'scale(0.9)')};
  }

  &:hover {
    color: ${(props) => (props.disabled ? 'inherit' : 'var(--color-secondary)')};
  }


`

const Pad = styled.div`
padding: 0.5em;
`


const GenButton = styled.button`
  background-color: var(--color-primary); /* Green */
  border: none;
  color: var(--color-background);
  font-weight: bold;
  padding: 0.3em;
  flex-grow: 1;

  height: 3em;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 0.5em;
  cursor: pointer;
  border-radius: 8px;
  width: 15em;
padding: 0.3em 0.5em; 
flex-grow: 0;
  

  opacity: ${(props) => (props.disabled ? '0' : '1')};
cursor: ${(props) => (props.disabled ? 'normal' : 'pointer')};
transition: ease-in-out 50ms;



  &:hover {
    background-color: ${(props) => (props.disabled ? 'inherit' : 'var(--color-accent)')};
  }
 
`

const SubmitButton = styled(GenButton)`


`

const CancelButton = styled(GenButton)`

  
  `

const CustomLink = styled(Link)`
display: flex;
justify-content: center;
align-items: center;
flex:  ${(props) => (props.grow ? '1' : 'none')};

background-color: var(--color-secondary); 
color: var(--color-background);
font-weight: bold;
border-radius: 0.5rem;
max-width: 100%;
font-size: 0.5em;
height:  ${(props) => props.height};
padding: 0.5rem 0.7rem;
text-decoration: none;

white-space: nowrap;
cursor: pointer;
transition: ease-in-out 100ms;

&:hover {
  background-color: var(--color-accent);

}

&:active {

}

@media (min-width: 900px) {
width: 100%;
border-radius: 0.2rem;

  }

${props => props.variant === 'primary' && `
  background-color: var(--color-accent); 
  color: var(--color-background);
`}

${props => props.variant === 'secondary' && `
  background-color: var(--color-background); 
  color: var(--color-secondary);
  border: 1px solid var(--color-accent);
  &:hover {
    background-color: var(--color-tertiary);
    color: var(--color-background);
    border: 1px solid var(--color-tertiary);
  }
`}
`;

const StyledButton = styled.button`
  color: var(--color-background);
  background-color: var(--color-primary);
  font-size: 1em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border: 1px solid var(--color-primary);
  border-radius: 0.1em;
  ${'' /* border-width: 0.2rem; */}
  box-sizing: border-box;
  height: 2em;
  overflow: hidden;
  white-space: nowrap;
  resize: none;
  padding: 0.5em 2em;
  &:hover {
    background-color: var(--color-secondary);
  };
  cursor: pointer;
`

const SolidButton = styled.button`
  background-color: var(--color-secondary); /* Green */
  border: none;
  color: var(--color-background);
  font-weight: bold;
  padding: 0.3em;
  flex-grow: 1;

  width: 100%;
  height: auto;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1em;
  cursor: pointer;
  border-radius: 8px;
  transition: ease-in-out 100ms;

  &:hover {
    background-color: var(--color-tertiary);
  };
`


const StyledTextArea = styled(TextareaAutosize)`
  width: 100%;

  font-size: ${(props) => props.fontSize ?? "0.4em"};
  border-color: var(--color-primary);
  resize: none;
  color: var(--color-primary);
  background-color: var(--color-background);
  &:hover {
      color: var(--color-secondary);
    };
  ::placeholder {
  color: #425F57;
};
&:focus {
  outline: none;

};
visibility: ${(props) => props.visibility};
`



export {Pad, MyButton, SubmitButton, CancelButton, CustomLink, StyledButton, SolidButton, StyledTextArea};