import React, { useState, useRef, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';
import StyledSwitch from "./StyledSwitch";
import { SubmitButton, CancelButton } from './StyledWidgets';
import OpenAiService from "../services/OpenAiService";

const StoryEditor = ({
  initialContent = '',
  initialTitle = '',
  segment = '',
  storyId = '',
  isForking = false,
  isEditing = false,
  storyRoot,
  onSubmit,
  onCancel,
  editStory,
  indexArray,
  userSubmitted,
  toggleEdit = () => {}
}) => {
  const [content, setContent] = useState(initialContent);
  const [title, setTitle] = useState(initialTitle);
  const [isRequesting, setIsRequesting] = useState(false);
  const [writingBuddy, setWritingBuddy] = useState(false);
  const quillRef = useRef();

  useEffect(() => {
    setContent(initialContent);
    setTitle(initialTitle);
  }, [initialContent, initialTitle]);



  const generateNextSentence = async (value) => {
    setIsRequesting(true);
  
    const editor = quillRef.current.getEditor();
    const text = editor.getText();
  
    // Find the index before any trailing newline characters
    let insertIndex = text.length - 1;
    while (insertIndex > 0 && text[insertIndex - 1] === '\n') {
      insertIndex--;
    }
  

  
      // Keep a local variable to track the cumulative content
      let accumulatedContent = '';
      editor.insertText(insertIndex, " ");
      insertIndex++;

//           // Remove formatting at the insert position
//     editor.removeFormat(insertIndex, 1, 'user');

//     // Clear formatting at the current cursor position
// editor.format('bold', false);
// editor.format('italic', false);
// editor.format('underline', false);
// // Add other formats as needed

    
  
for await (const newContent of OpenAiService.generateNextSentence(segment + " " + text, storyId)) {
  // Insert the new content with formatting explicitly set to false
  editor.insertText(insertIndex, newContent, { bold: false, italic: false, underline: false }, 'user');

  // Update the insertIndex for the next insertion
  insertIndex += newContent.length;

  accumulatedContent += newContent;

  // Set the cursor to the end after each insertion
  editor.setSelection(insertIndex, 0);
}

  
    setIsRequesting(false);
    editor.focus();
  };
  
  


  const handleChange = (value, delta, source) => {
    setContent(value);
  
    if (isRequesting || !writingBuddy || source !== 'user') return;
  
    // Get the last operation from the delta
    const lastOp = delta.ops && delta.ops[delta.ops.length - 1];
  
    if (lastOp && typeof lastOp.insert === 'string') {
      const insertedText = lastOp.insert;
      const lastChar = insertedText.slice(-1);
  
      if (/[.!?]/.test(lastChar)) {

        generateNextSentence(value);
      }
    }
  };
  
  

  const handleTitleChange = (event) => {
    const newTitle = event.target.value.slice(0, 75);
    setTitle(newTitle);
  };

  const charLimit = 2000;

  const isOverCharLimit = () => {
    if (quillRef.current) {
      const plainText = quillRef.current.getEditor().getText();
      return plainText.length > charLimit;
    }
    return false;
  };

  const getTextLength = () => {
    if (quillRef.current) {
      return quillRef.current.getEditor().getText().length;
    }
    return 0;
  };

  const handleSubmit = async () => {
    if (isOverCharLimit() || getTextLength() < 1 || (!isForking && !isEditing && title.length < 1)) {
      alert('Please check your story length and ensure you have a title.');
      return;
    }

    if (!isForking && !isEditing && !/^[a-zA-Z0-9 ]*$/.test(title)) {
      alert('Your title can only contain letters, numbers, and spaces. Try again.');
      return;
    }

    if (isEditing) {
      const data = {
        segment: content,
        title: title,
        userId: userSubmitted._id,
        storyId: storyId
      };
      await editStory(indexArray, data);
      toggleEdit();
    } else {
      onSubmit({ title, content });
    }
  };

  const modules = {
    toolbar: [['bold', 'italic']],
  };

  return (
    <FlexWrapper>
      {storyRoot && (
        <>
          <TitleText
            placeholder="Title"
            value={title}
            onChange={handleTitleChange}
          />
          <Pad padding="0.5em" />
          {(title.length >= 75) && <p>Sorry to handicap your boundless creativity, but the maximum title length is 75 characters.</p>}
        </>
      )}
      <EditorWrapper>
      <ReactQuill 
  ref={quillRef}
  value={content} 
  onChange={(value, delta, source) => handleChange(value, delta, source)}
  modules={modules}
  placeholder={isForking ? "What happens next?" : "Write your story here."}
/>

      </EditorWrapper>
      <p>{getTextLength()} / {charLimit} {isOverCharLimit() && "   Over the limit buddy, reel it in a little."}  </p>
      <ButtonWrapper>
        
          <SwitchWrapper>
            <StyledSwitch size='0.5em' checked={writingBuddy} onToggle={setWritingBuddy} />
            <Pad padding="0.5em" />
            <p>Writing Buddy {writingBuddy ? 'On' : 'Off'}</p>
          </SwitchWrapper>
        
        <RowWrapper>
          <SubmitButton onClick={handleSubmit} disabled={isOverCharLimit()}>
            {isEditing ? 'Save' : 'Submit'}
          </SubmitButton>
          <CancelButton onClick={onCancel}>Cancel</CancelButton>
        </RowWrapper>
      </ButtonWrapper>
    </FlexWrapper>
  );
};

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: auto;
  gap: 0.5em;
`;

const SwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  padding: 0.5em;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5em 0;
  gap: 0.5em;
`;

const Pad = styled.div`
  padding: ${(props) => props.padding || '1em'};
`;

const EditorWrapper = styled.div`
  border: 1px solid var(--color-primary);
  border-radius: 2px;
  margin: 0.01em;
  z-index: 9999;

  .ql-editor {
    height: 20em;
    font-size: 1rem;
  }

  .ql-editor.ql-blank::before {
    color: var(--color-secondary);
    opacity: 0.3;
  }

  .ql-stroke {
    fill: none;
    stroke: var(--color-primary);
  }

  .ql-snow {
    border: none;
    text-align: left;
  }

  .ql-snow.ql-toolbar button:hover .ql-stroke,
  .ql-snow .ql-toolbar button:hover .ql-stroke,
  .ql-snow.ql-toolbar button:focus .ql-stroke,
  .ql-snow .ql-toolbar button:focus .ql-stroke,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
  .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
    stroke: var(--color-tertiary);
  }
`;

const TitleText = styled.textarea.attrs({
  rows: 1,
})`
  width: 100%;
  overflow: auto;
  padding: 0.5em;
  resize: none;
  font-size: 1em;
  outline: none;
  border-radius: 7px;
  box-sizing: border-box;
  border-color: var(--color-primary);
  color: var(--color-primary);
  background-color: var(--color-background);
  text-align: center;
  ::placeholder {
    color: var(--color-secondary);
    opacity: 0.8;
  }
  &:hover {
    ::placeholder {
      opacity: 1;
    }
  }
  &:focus {
    outline: none;
    ::placeholder {
      transition: opacity 0.5s 0.5s ease!important;
      opacity: 0.35;
      color: var(--color-secondary);
    }
  }
`;

export default StoryEditor;