import React, { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { AuthContext } from '../context/AuthContext';
import { useAllStoriesAndForksContext } from '../context/AllStoriesAndForksContext';
import UserCard from '../components/cards/UserCard';
import ExplorerSwiper from '../components/ExplorerSwiper';


const UserStats = ({ stories }) => {
  const stats = useMemo(() => {
    const submittedCount = stories.filter(story => story.storyRoot).length;
    const forksCount = stories.filter(story => !story.storyRoot).length;
    const totalWordCount = stories.reduce((sum, story) => {
      const wordCount = story.segment.split(/\s+/).length;
      return sum + wordCount;
    }, 0);
    const averageWordCount = submittedCount > 0 ? Math.round(totalWordCount / submittedCount) : 0;
    const totalForks = stories.reduce((sum, story) => sum + (story.children.length || 0), 0);

    return {
      submittedCount,
      forksCount,
      totalWordCount,
      averageWordCount,
      totalForks,
    };
  }, [stories]);

  return (
    <StatsWrapper>
      <StatItem>
        <StatLabel>Submitted Stories:</StatLabel>
        <StatValue>{stats.submittedCount}</StatValue>
      </StatItem>
      <StatItem>
        <StatLabel>Submitted Forks:</StatLabel>
        <StatValue>{stats.forksCount}</StatValue>
      </StatItem>
      <StatItem>
        <StatLabel>Number of Times Submissions Have Been Forked:</StatLabel>
        <StatValue>{stats.totalForks}</StatValue>
      </StatItem>
      <StatItem>
        <StatLabel>Total Word Count:</StatLabel>
        <StatValue>{stats.totalWordCount}</StatValue>
      </StatItem>
      <StatItem>
        <StatLabel>Average Words per Submission:</StatLabel>
        <StatValue>{stats.averageWordCount}</StatValue>
      </StatItem>
    
    </StatsWrapper>
  );
};



function UserProfilePage() {
  const { username } = useParams();
  const { allStories, isLoading } = useAllStoriesAndForksContext();

  const userStories = useMemo(() => {
    return allStories.filter(story => story.userSubmitted.username === username);
  }, [allStories, username]);

  const rootStories = useMemo(() => {
    return userStories.filter(story => story.storyRoot === true);
  }, [userStories]);

  const forkedStories = useMemo(() => {
    return userStories.filter(story => !story.storyRoot);
  }, [userStories]);

  return (
    <PageWrapper>
      <Pad />
      <FlexWrapper>
        <UserCardWrapper>
        <UserCard username={username} />
        </UserCardWrapper>
        <UserStatsWrapper>
        <StatsHeader >{username}'s Writing Stats:</StatsHeader>
        <UserStats stories={userStories} />
        </UserStatsWrapper>
      </FlexWrapper>
      
      {isLoading ? (
        <p>Loading stories...</p>
      ) : (
        <>
          {rootStories.length > 0 && (
            <>
              <Pad />
              <ExplorerSwiper 
                stories={rootStories} 
                title={`${username}'s Original Stories`} 
              />
            </>
          )}
          
          {forkedStories.length > 0 && (
            <>
              <Pad />
              <ExplorerSwiper 
                stories={forkedStories} 
                title={`${username}'s Forked Stories`} 
              />
            </>
          )}
          
          {userStories.length === 0 && (
            <p>{username} hasn't created any stories yet.</p>
          )}
        </>
      )}
    </PageWrapper>
  );
}

export default UserProfilePage;

const PageWrapper = styled.div``;

const StatsHeader = styled.h4`
  padding-bottom: 1em;
  color: var(--color-tertiary);
`;

const UserCardWrapper = styled.div`
 // set breakpoint for mobile
  @media (min-width: 600px) {
    width: 60%;
  }
`;

const UserStatsWrapper = styled.div`
   @media (min-width: 600px) {
    width: 30%;
  }
`;

const Pad = styled.div`
  padding: 1em;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex: 1;
  gap: 1em;
  flex-direction: row;
  flex-wrap: wrap;
  @media (max-width: 600px) {
    flex-direction: column;
  }
  justify-content: space-between;
`;

const StatsWrapper = styled.div`
  background-color: var(--color-background);
  border-radius: 8px;
  ${'' /* padding: 1em; */}
`;

const StatItem = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1em;
  align-items: center;
  margin: 0.5em 0;
  font-size: 0.9em;
`;

const StatLabel = styled.span`
  font-weight: bold;
  word-wrap: break-word;
  max-width: 80%;
`;

const StatValue = styled.span`
  text-align: right;
`;