import {React, useState, useEffect} from 'react'; 
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import GridWrapper from "../components/GridWrapper";



function StorySample(props) {

    return (
        <>
            <GridWrapper>
            {/* <UserInfoWrapper>
                   
                        <StoryText>{props.story.parentStory.title}</StoryText>
                    
                </UserInfoWrapper> */}
                <UserInfoWrapper>
                    <StoryLink to={`/user/${props.story.userSubmitted.username}`}>
                        <StoryText>{props.story.userSubmitted.username}</StoryText>
                    </StoryLink>
                </UserInfoWrapper>

                <StorySampleWrapper>
                    <StoryText>{props.story.title}</StoryText>
                   
                    <StoryLink to={`/story/${props.story._id}`}>
                    {/* <StoryText>{props.story.segment.substring(0, 100)+'...'}</StoryText> */}
                    <StoryText>{props.story.segment}</StoryText>

                    </StoryLink>

                </StorySampleWrapper>

            </GridWrapper>

            
    
        </>
    );


}

export default StorySample;

const UserInfoWrapper = styled.div`
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
`

const StorySampleWrapper = styled.div`
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
`

const StoryText= styled.p`
    font-size: 1.5rem;
`

const ViewStoryWrapper = styled.div`
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
`

const StoryLink = styled(Link)`
${'' /* display: flex; */}
justify-content: center;
align-items: bottom;
text-decoration: none;

`

const LinkText = styled.p`
    font-size: 1.5rem;
    text-align: center;
`


