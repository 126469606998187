import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// import the like and fork icons
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import AltRouteIcon from '@mui/icons-material/AltRoute';

// import user icon 
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

// import comment icon
import CommentIcon from '@mui/icons-material/Comment';

// import nordicwalkind icon
import CharacterIcon from '@mui/icons-material/NordicWalking';





const Notification = (props) => {

  const [read, setRead] = useState(props.read);
const NotificationSwitch = () => {
  switch(props.type) {
    case 'segmentLike':
      return (
          <>
          <NotificationIcon color={read ? 'var(--color-primary)' :'var(--color-secondary)'}><ThumbUpAltIcon fontSize='inherit'/></NotificationIcon>
          <NotificationMessage><UserLink to={`/user/${props.senderName}`}>{props.senderName}</UserLink><Link to={`${props.link}`}> liked your story</Link></NotificationMessage>
          </>
      );
    case 'segmentFork':
      return (
        <>
          <NotificationIcon color={read ? 'var(--color-primary)' :'var(--color-secondary)'}><FlipContainer><AltRouteIcon fontSize='inherit' /></FlipContainer></NotificationIcon>
          <NotificationMessage><UserLink to={`/user/${props.senderName}`}>{props.senderName}</UserLink><Link to={`${props.link}`}> forked your story</Link></NotificationMessage>
        </>
      );
    case 'userFollow':
      return (
        <>
          <NotificationIcon color={read ? 'var(--color-primary)' :'var(--color-secondary)'}><AccountCircleIcon fontSize='inherit' /></NotificationIcon>
          <NotificationMessage><UserLink to={`/user/${props.senderName}`}>{props.senderName}</UserLink><Link to={`${props.link}`}> started following you</Link></NotificationMessage>
        </>
      );
    case 'segmentComment':
      return (
        <>
          <NotificationIcon color={read ? 'var(--color-primary)' :'var(--color-secondary)'}><CommentIcon fontSize='inherit' /></NotificationIcon>
          <NotificationMessage><UserLink to={`/user/${props.senderName}`}>{props.senderName}</UserLink><Link to={`${props.link}`}> commented on your story</Link></NotificationMessage>
        </>
      );
      case 'characterFeatured':
        return (
          <>
            <NotificationIcon color={read ? 'var(--color-primary)' :'var(--color-secondary)'}><CharacterIcon fontSize='inherit' /></NotificationIcon>
            <NotificationMessage><UserLink to={`/story/${props.senderName}`}>{props.senderName}</UserLink><Link to={`${props.link}`}> featured your character {props.message} in their story</Link></NotificationMessage>
          </>
        );
    default:
      return (
        <>
          <NotificationIcon color={read ? 'var(--color-primary)' :'var(--color-secondary)'}><ThumbUpAltIcon fontSize='inherit' /></NotificationIcon>
          <NotificationMessage><UserLink to={`/user/${props.senderName}`}>{props.senderName}</UserLink> liked <Link to={`${props.link}`}>your story</Link></NotificationMessage>
        </>
      );
  }
}

  return (
    <NotificationContainer ><NotificationSwitch/></NotificationContainer>
  // start by creating a simple switch statement to determine which icon to use
  // for the cases where there is a segmentLike, segmentFork, follow, or comment

  )
};

export default Notification;

// create a container for AltRouteIcon that flips it 180 degrees
const FlipContainer = styled.div`
  transform: rotate(180deg);
`;

// const LikeContainer = styled.div`
//     color: var(--color-primary);
// `;

const NotificationContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 0.5em;
  ${'' /* margin-left: 2em; */}
  font-size: 0.7rem;
  ${'' /* padding: 12px; */}
  ${'' /* border-radius: 6px; */}
  ${'' /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); */}
  background-color: var(--color-background);
  ${'' /* background-color: ${(props) => props.backgroundColor}; */}


`;

const NotificationIcon = styled.div`
  padding-right: 1em;
  font-size: 1rem;
  color: ${(props) => props.color};

`;

const NotificationMessage = styled.p`
  flex-grow: 1;
  color: var(--color-primary);
`;

const DullLink = styled(Link)`
  color: var(--color-primary);
`;

const UserLink = styled(Link)`
  color: var(--color-accent);
`;

