import axios from 'axios';


// this allows you to post session data from the client to the backend
axios.defaults.withCredentials = true;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    
    var api_auth_string = `http://${process.env.REACT_APP_ADDRESS}:3000/api/auth`

} else {
    
     api_auth_string = `${process.env.REACT_APP_BACKEND_API_ADDR_PROD}/auth`
     


    // production code
}





export default {

    // register service
    // returns the registered user, along with isAuthenticated set to false
    register: async (user) => {
        return await axios.post(`${api_auth_string}/register`, {
            email: user.email, username: user.username, password: user.password, password2: user.password2
        }
        )
            .then(res => {
                
                return res;
            })
            .catch(error => { return error.response
            })
    },

    // login service
    login: async (user) => {
        return await axios.post(`${api_auth_string}/login`, 
        { email: user.email, password: user.password },
        {
            withCredentials: true
        }
        )
            .then(res => { ; return res })
            .catch(error => console.log(error) )
    },

    tokenLogin: async (user) => {
        return await axios.post(`${api_auth_string}/tokenlogin`, 
        { id: user.id, token: user.token },
        {
            withCredentials: true
        }
        )
            .then(res => { ; return res })
            .catch(error => console.log(error) )
    },


    // logout service
    logout: async () => {
        return await axios.get(`${api_auth_string}/logout`)
            .then(res => {
                ;
                ;
                return res;
            })
            .catch(error => {
                 console.log(error);
            })
    },

    isAuthenticated: async () => {
        return await axios.get(`${api_auth_string}/authenticated`,
        {
            withCredentials: true
        })
            .then(res => {
                ;
                return res
            })
            .catch(error => {
                console.log(error);
            })
    },

    verifyEmail: async (userid,token) => {
        return await axios.get(`${api_auth_string}/users/${userid}/verify/${token}`)
            .then(res => {
                ;
                return res
            })
            .catch(error => {
                console.log(error);
            })
    },

    forgotPassword: async (email) => {
        return await axios.post(`${api_auth_string}/users/forgotpassword`,email)
            .then(res => {
                ;
                return res
            })
            .catch(error => {
                console.log(error);
            })
    },

    verifyPasswordResetToken: async (userid, token) => {
        return await axios.get(`${api_auth_string}/users/${userid}/resetpassword/${token}`)
            .then(res => {
                ;
                return res
            })
            .catch(error => {
                console.log(error);
            })
    },


    postReset: async (userid, token, password) => {
        return await axios.post(`${api_auth_string}/users/${userid}/resetpassword/${token}`,password)
            .then(res => {
                ;
                return res
            })
            .catch(error => {
                console.log(error);
            })
    }
    
}

