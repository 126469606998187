import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FaSave } from 'react-icons/fa';
import styled from 'styled-components';
import { Pad, SubmitButton, CancelButton } from './StyledWidgets';

function EditStory(props) {
  const [editedContent, setEditedContent] = useState({
    storyroot: props.storyRoot ?? false,
    title: props.title ?? "",
    segment: props.segment
  });

  function handleChange(content, delta, source, editor) {
    setEditedContent((prevData) => ({
      ...prevData,
      segment: content
    }));
  }

  function handleTitleChange(event) {
    const { value } = event.target;
    setEditedContent((prevData) => ({
      ...prevData,
      title: value
    }));
  }

  async function handleStoryEdit() {
    const data = {
      segment: editedContent.segment,
      title: editedContent.title,
      userId: props.userSubmitted._id,
      storyId: props.storyId
    }
    await props.editStory(props.indexArray, data);
  }

  const modules = {
    toolbar: [
      ['bold', 'italic'],

    ],
  };

  return (
    <>
      <StoryContentWrapper>
        {props.storyRoot && (
          <StyledInput
            name="title"
            value={editedContent.title}
            onChange={handleTitleChange}
            type="text"
            placeholder="Title"
          />
        )}
          <ReactQuill
            theme="snow"
            value={editedContent.segment}
            onChange={handleChange}
            modules={modules}
          />
      </StoryContentWrapper>
      <Pad />
      <ButtonsWrapper>
        <SubmitButton onClick={() => { handleStoryEdit(); props.toggleEdit(false); }}>
          <FaSave />
        </SubmitButton>
        <CancelButton onClick={props.cancel}>Cancel</CancelButton>
      </ButtonsWrapper>
    </>
  );
}

export default EditStory;

const StoryContentWrapper = styled.div`
  grid-column: 1 / span 4;  
  grid-row: 1 / span 1;
  background-color: var(--color-background);
  padding-top: 0.5em;
  position: relative;


  ${'' /* set height of editor space  */}
  .ql-editor {
    height: 20em;
  }

${'' /* // for styling the placeholder text */}
  .ql-editor.ql-blank::before {
    color: var(--color-secondary);
    opacity: 0.3;
}

  .ql-stroke {
    fill: none;
    stroke: var(--color-primary);
  }

  .ql-icon {
    vertical-align: middle; 
  }

  .ql-snow {
    border: none;
    text-align: left;
  }



  .ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke-miter, .ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
    stroke: var(--color-tertiary);
}

`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 0.5em;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid var(--color-primary);
  border-radius: 4px;
  font-size: 1em;
  color: var(--color-text);
  background-color: var(--color-background);

  &:focus {
    outline: none;
    border-color: var(--color-accent);
  }
`;