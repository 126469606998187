import {React} from 'react'; 

import UserLogin from '../components/UserLogin';



function LoginPage() {
return (
    <div>
        <UserLogin />
    </div>
);
}

export default LoginPage;