import React from "react";



function ExcerptPage() {
    return (
<h1>test</h1>
    );
};

export default ExcerptPage;