import axios from 'axios';

axios.defaults.withCredentials = true


if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    
    var api_openai_string = `http://${process.env.REACT_APP_ADDRESS}:3000/api/openai`

} else {
    
     api_openai_string = `${process.env.REACT_APP_BACKEND_API_ADDR_PROD}/openai`
}



// const api_story_string = `http://${process.env.REACT_APP_ADDRESS}:3000/api/stories`
// const api_user_string = `http://${process.env.REACT_APP_ADDRESS}:3000/api/users`


const apiCall = async (endpoint, content, storyid) => {
  const response = await fetch(`${api_openai_string}/${endpoint}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ content: content, storyid: storyid }),
  });

  if (!response.ok) {
    throw new Error(await response.text());
  }

  return response.body.getReader();
};

const apiPromptCall = async (endpoint, content, prompt, storyid) => {
  const response = await fetch(`${api_openai_string}/${endpoint}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ content: content, prompt: prompt, storyid: storyid }),
  });

  if (!response.ok) {
    throw new Error(await response.text());
  }

  return response.body.getReader();
};


const apiChatCall = async (endpoint, messages, currentMessage) => {
  const response = await fetch(`${api_openai_string}/${endpoint}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ messages: messages, currentMessage: currentMessage }),
  });

  if (!response.ok) {
    throw new Error(await response.text());
  }

  return response.body.getReader();

};


const processStreamedContent = async function* (reader, errorHandler = null) {
  const decoder = new TextDecoder();

  while (true) {
    const { value, done } = await reader.read();
    if (done) {
      break;
    }

    const decodedChunk = decoder.decode(value);
    const lines = decodedChunk.split('\n');
    const parsedLines = lines
      .map(line => line.replace('data: ', '').trim())
      .filter(line => line !== "" && line !== '[DONE]')
      .map(line => {
        try {
          return JSON.parse(line);
        } catch (error) {
          if (errorHandler) {
            errorHandler(line);
          }
          return null;
        }
      });

    for (const line of parsedLines) {
      if (!line) {
        continue;
      }
      const { choices } = line;
      try {
        const { delta } = choices[0];
        const { content } = delta;
        if (content) {
          yield content;
        }
      } catch (error) {
        console.error(`Failed to extract content from line: ${JSON.stringify(line)}`);
      }
    }
  }
};








export default {


generateFork: async function* (content) {
  const reader = await apiCall('generatefork', content);
  yield* processStreamedContent(reader);
},

generateNextSentence: async function* (content, storyid) {
  const reader = await apiCall('generatenextsentence', content, storyid);
  const errorHandler = line => console.error(`Failed to parse line as JSON: ${line}`);
  yield* processStreamedContent(reader, errorHandler);
},

sendPrompt: async function* (content,prompt, storyid) {


  const reader = await apiPromptCall('sendprompt', content, prompt, storyid);

  yield* processStreamedContent(reader);

},

chatStrengths: async function* (messages, currentMessage) {

  const reader = await apiChatCall('chatstrengths', messages, currentMessage);

  yield* processStreamedContent(reader);

},


}
